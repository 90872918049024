import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import { Select, TextField } from "@mui/material";
import { CommonDropdown } from "../Input/commonDropdown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Invalid from "../error/invalidData";
import accountAPI from "../../api/accountBaseAPI";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import { checkValidDate } from "../../utils/util";
import { act } from "react-dom/test-utils";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RegenAccountModal = ({
    open = false,
    action,
    hasBlur = false,
}) => {
    const [as_of_date, setAsOfDate] = React.useState(new Date())
    const [errorOpen, setErrorOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [errorState, setErrorState] = React.useState([])

    React.useEffect(() => {
        setErrorOpen(false)
    }, [open])

    const Submit = async () => {
        try {
            setLoading(true)
            const ret = await accountAPI.accountRegen({
                report_date: format(new Date(as_of_date), "yyyy-MM-dd"),
            });


            if (ret.status === 200) {
                action(true);
            }

        }
        catch (e) {
            setErrorState([{ message: e?.response.data?.status?.description }])
            setErrorOpen(true)
        }
        finally {
            setLoading(false)
        }
    }

    const Validate = () => {
        const asOfdate = moment(as_of_date).format("YYYY-MM-DD");
        const invalid = (asOfdate.toString() === "Invalid date") || asOfdate.toString().startsWith("0")
        if (invalid) {
            setErrorOpen(true);
            setErrorState([{ message: "As of date is required" }]);
        }
        else {
            Submit();
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            // onClose={action}
            className={"z-index-1"}
            minWidth={500}
            sx={
                hasBlur
                    ? {
                        backdropFilter: "blur(5px)",
                    }
                    : {}
            }
        >
            <DialogTitle sx={{ borderBottom: 1, borderColor: "#ccc", fontSize: "16px", fontWeight: "bold" }} align="center">
                {"REGEN"}
                <Invalid pt="pt-20px" errorState={errorState} open={errorOpen} setOpen={setErrorOpen} isShow={true} />
            </DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-slide-description " sx={{ paddingX: "40px" }}>
                    <div className="my-20px">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date desktop"
                                inputFormat="dd/MM/yyyy"
                                value={as_of_date || null}
                                onChange={e => setAsOfDate(e)}
                                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 16, color: "#7A58BF", } }}
                                    fullWidth={true} variant="standard" label="AS OF DATE" error={false} />}
                            />
                        </LocalizationProvider>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <Button variant="text" onClick={action}>
                    Cancel
                </Button>

                <LoadingButton
                    // startIcon={<img alt="" src={SaveIcon} />}
                    variant="contained"
                    //  sx={{ fontSize: "14px" }}
                    onClick={Validate}
                    loading={loading}
                    disabled={loading}
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default RegenAccountModal;
