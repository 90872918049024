import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)(({ theme }) => ({
  //   color: theme.palette.getContrastText("transparent"),
  backgroundColor: "transparent",
  color: "rgba(0, 0, 0, 0.6)",
  width: "100%",
  height: "36px",
  fontSize: "10px",
  "&:hover": {
    backgroundColor: "rgba(122, 88, 191, 0.15)",
  },
  "&:active": {
    backgroundColor: "rgba(122, 88, 191, 0.15)",
  },
}));

function SecondaryButton({ text = "Button", ...props }) {
  return <CustomButton {...props}>{text}</CustomButton>;
}

export default SecondaryButton;
