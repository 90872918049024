import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTE_AUTH_CALLBACK, ROUTE_LOGIN } from "../constrant/route.constrant";
import AuthCallbackMain from "../pages/authCallback/authCallback.main";
import LoginMain from "../pages/login/login.main";

function AuthRoute() {
  return (
    <Routes>
      <Route path={ROUTE_AUTH_CALLBACK} element={<AuthCallbackMain />} />
      <Route path={ROUTE_LOGIN} element={<LoginMain />} />
    </Routes>
  );
}

export default AuthRoute;
