import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";
import NotFoundIcon from "../../assets/icon/not_found.svg";
import { MAX_VALUE } from "../../constrant/text.constrant";
import { DEFAULT_HEADER_STYLE } from "../../constrant/style.constrant";
import useWindowDimensions from "../../utils/util";

export const NostroTable = ({
    rows,
    showFooter = false,
    selectable = false,
    onClickRow,
    page = 0,
    setPage,
    orderByDefault = "",
    hasUtilIndex = false,
    formatControl,
    showFields = [],
    searched,
    overwriteStyle = null,
    disabledSort = false,
    orderDefault = "asc",
    showNoData = false,
    rowsPerPage = 999,
    disabledSortList = [],
    disabledCursor = false,
}) => {
    const [order, setOrder] = React.useState(orderDefault);
    // set default order by here
    const [orderBy, setOrderBy] = React.useState(orderByDefault);
    const [sortBy, setSortBy] = React.useState(true);
    const { height } = useWindowDimensions();

    function EnhancedTableHead(props) {
        return (
            <TableHead>
                <TableRow className="border-b-0" hover={false}>
                    <TableCell sx={DEFAULT_HEADER_STYLE}></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div style={{minWidth:"80px"}}>G/L ACC</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div style={{minWidth:"288px"}}>G/L ACCT DESCRIPTION</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-20">ORIGINAL<br/>CURR</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div style={{minWidth:"128px"}}>AMOUNT<br/>[OS ORIGINAL]</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-56">COUNTRY OF HEAD QUARTER /<br/>GUARANTOR</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-24">INWARD RISK<br/>TRANSFER</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-28">OUTWARD RISK<br/>TRANSFER</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-24">INT BEARING<br/>FLAG</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-10">FIX / VAR</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-32">LATEST - DATE</div></TableCell>
                    <TableCell sx={DEFAULT_HEADER_STYLE}><div className="w-32">UPDATED BY</div></TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setSortBy(!sortBy);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const DataArrayTable = disabledSort
        ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : stableSort(rows, getComparator(order, orderBy), orderBy, sortBy)?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

    const convertShowFieldsObject = (row) => {
        let temp = [];
        if (showFields.length === 0) {
            /// Non Show Field case
            Object.keys(row).map((key) => temp.push(row[key]));
        } else {
            for (let i = 0; i < showFields.length; i++) {
                const key = showFields[i];
                if (row[key]) {
                    temp.push(row[key]);
                } else {
                    temp.push("");
                }
            }
        }
        return temp;
    };

    if (showNoData && DataArrayTable.length <= 0) {
        return (
            <TableContainer>
                <NoRecordFound />
            </TableContainer>
        );
    }

    return (
        <div className="">
            <TableContainer sx={{ height: height - 180  }}>
                {searched?.length > 2 && rows?.length <= 0 ? (
                    <NoRecordFound />
                ) : (
                    <Table
                        stickyHeader
                        sx={TABLE_STYLE_WITH_HOVER_STANDARD_WITDH}
                        aria-label="simple table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            rowCount={rows?.length}
                            onRequestSort={handleRequestSort}
                            disabledSortList={disabledSortList}
                        />
                        <TableBody>
                            {DataArrayTable?.map((row, index) => {
                                return (
                                    <TableRow
                                        id="table-row"
                                        key={index}
                                        hover={selectable}
                                        onClick={() => onClickRow && onClickRow(row)}
                                        sx={{
                                            ...TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
                                        }}
                                    >
                                        {convertShowFieldsObject(row).map((item, index) => {
                                            if (overwriteStyle !== null) {
                                                return (
                                                    <TableCell
                                                        id="table-cell"
                                                        align="left"
                                                        key={index}
                                                        sx={{
                                                            ...overwriteStyle[index],
                                                            cursor: disabledCursor && "auto",
                                                        }}
                                                    >
                                                        {item}
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell
                                                    id="table-cell"
                                                    align="left"
                                                    key={index}
                                                    // sx={
                                                    //     index < 3 ?
                                                    //         {
                                                    //             position: "sticky",
                                                    //             left: index * 70 + (index > 1 ? 45 : 0),
                                                    //             background: "white",
                                                    //             //boxShadow: "5px 2px 5px grey",
                                                    //             //borderRight: "2px solid black"
                                                    //         } : {  }
                                                     
                                                    // }
                                                >
                                                    {formatControl === undefined ||
                                                        formatControl[index] == null
                                                        ? item
                                                        : formatControl[index](item, index)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                   
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            {rows?.length > 0 && showFooter && (
                <div className="border-t">
                    <TablePagination
                        component="div"
                        count={rows?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[]}
                        showFirstButton={true}
                        showLastButton={true}
                    />
                </div>
            )}
        </div>
    );
};

/// Sort
export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}



function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, name, sortBy) {
    if (name?.length > 0) {
        return array?.sort((a, b) => {
            if (sortBy) {
                return a[name]?.toString().localeCompare(b[name]);
            } else {
                return b[name]?.toString().localeCompare(a[name]);
            }
        });
    } else {
        return array;
    }
}

const TABLE_STYLE_WITH_HOVER_STANDARD_WITDH = {
    minWidth: 650,
    // tableLayout: "fixed",
    "& .MuiTableRow-root:hover": {
   //     cursor: "pointer",
    },
};

const TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER = {
    "& .MuiTableRow-root:hover": {
        backgroundColor: "#7A58BF70 !important",
  //      cursor: "pointer",
    },
    "&:last-child td, &:last-child th": { border: 0 },
};

const NoRecordFound = () => {
    return (
        <div id="NoRecordFound" className="m-auto pt-xl">
            <img
                className="w-28px h-28px m-auto mb-5px"
                alt="error"
                src={NotFoundIcon}
            />
            <div className="text-value  w-full text-center text-textsecoundary">
                No Record Found.
            </div>
        </div>
    );
};