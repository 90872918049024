import api from "./api.main";
import { apiConfig } from "../config/config.main";

const capitalBaseList = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.capitalBaseList}${query}`, {
    params,
  });
};

const capitalBaseCreate = async (params = {}, query) => {
  return api.instance.post(`${apiConfig.capitalBaseCreate}`, params);
};

const capitalBaseUpdate = async (as_of_date,params) => {
  return api.instance.put(`${apiConfig.capitalBaseUpdate}/${as_of_date}`, 
    params,
  );
};

const capitalBaseDelete = async (as_of_date,params) => {
  return api.instance.delete(`${apiConfig.capitalBaseDelete}/${as_of_date}`, {
    params,
  });
};

const capitalBaseRegen = async (params = {}) => {
  return api.instance.post(`${apiConfig.capitalBaseRegen}`, params);
};


const exportedObject = {
    capitalBaseList,
    capitalBaseCreate,
    capitalBaseUpdate,
    capitalBaseDelete,
    capitalBaseRegen
};

export default exportedObject;
