import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from "@mui/material";
import React from "react";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import ErrorIcon from "../../assets/icon/error.svg";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({ isAlert = false, title = "TITLE", message = "MESSAGE", onConfirm, open, setOpen, loading = false }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={true}
            // onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className={"z-index-9999"}
        >
            {isAlert ?
                <DialogTitle><div className="flex justify-center">
                    {title}
                    <img className="ml-4" src={ErrorIcon} />
                    </div></DialogTitle> :
                <DialogTitle>{title}</DialogTitle>
            }
            <div className="border-b w-full"></div>
            <DialogContent sx={{ width: "520px" }}>
                <DialogContentText id="alert-dialog-slide-description ">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={onConfirm}
                    loading={loading}
                    disabled={loading}
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;