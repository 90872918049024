import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../../components/loading/loadingPage";
import Logo from "../../assets/images/scb-logo-desktop.png";
import { login, SetUser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authService from "../../api/authService";

function Login() {
  const dispatch = useDispatch()
  const nagivate = useNavigate();

  // useEffect(() => {
  //   sessionStorage.clear();
  // }, []);
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const onClickLogin = async () => {
    try {
      setLoading(true)
      const { data } = await authService.login(userName, password)
      const today = new Date().getTime()
      sessionStorage.setItem("lastActive", today);
      await dispatch(SetUser({ ...data.data, }))?.unwrap()
      nagivate("/");
    }
    catch (error) {
      const errorResponse = error?.response?.data?.status?.description || "Network Error" ;
      alert(errorResponse)
    }finally {
      setLoading(false)
    }
  }

  const validate =  () => {
    if (userName.trim().length === 0) {
      alert("username is required");
    } else if (password.trim().length === 0) {
      alert("password is required");
    }
    else {
      onClickLogin();
    }
  };

  return (
    <div className="h-screen bg-white flex max-w-md mx-auto flex-col items-center justify-center w-full ">
      <img src={Logo} alt="logo" className="" />
      <div className="h-10px" />
      <TextField label="Username" variant="standard" fullWidth
        value={userName} onChange={e => setUserName(e.target.value)} />
      <div className="h-10px" />
      <TextField label="Password" pass variant="standard" fullWidth
        type="password" value={password} onChange={e => setPassword(e.target.value)} />
      <div className="h-10px" />
      <LoadingButton />
      <LoadingButton
        onClick={validate}
        variant="contained"
        loading={loading}
        disabled={loading}
        fullWidth
      >
        Sign in
      </LoadingButton>
    </div>
  );
}

export default Login;
