import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import { Select, TextField } from "@mui/material";
import { CommonDropdown } from "../Input/commonDropdown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Invalid from "../error/invalidData";
import capitalBaseAPI from "../../api/capitalBaseAPI";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import { addCommasToString, checkValidDate, convertInputNumCommaToNumber, errMaxAmount, inputFormatNumCommaTwoDigit, validateAsOfDate, validateMaxAmount } from "../../utils/util";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CapitalBaseModal = ({
    open = false,
    action,
    hasBlur = false,
    data = {},
    isCreate,
}) => {
    const [form, setForm] = React.useState({})
    const [errorOpen, setErrorOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [errorState, setErrorState] = React.useState([])

    React.useEffect(() => {
        if (!isCreate)
            setForm(data)
        else 
            setForm({})
        setErrorOpen(false)
    }, [open])

    const Submit = async () => {
        try {
            setLoading(true)
            /// submit data to API
            //sub_acc,account_name,original_curr
            var ret
            const amount = convertInputNumCommaToNumber(form?.amount);
            if (isCreate)
                ret = await capitalBaseAPI.capitalBaseCreate({ as_of_date: format(new Date(form.as_of_date),"yyyy-MM-dd"), amount: amount});
            else
                ret = await capitalBaseAPI.capitalBaseUpdate(format(new Date(form.as_of_date),"yyyy-MM-dd"), {amount:amount})

               

                if(ret.status === 200) {
                    action(true);
                }
                // else {
                //     setErrorState([ret.status])
                //     setErrorOpen(true)
                // }
        }
        catch (e) {
            setErrorState([{message:e?.response.data?.status?.description}])
            setErrorOpen(true)
        }
        finally {
            setLoading(false)
        }
    }

    const Validate = () => {
        let error = []
        if (!form.as_of_date || form.as_of_date == "" || !checkValidDate(new Date(form.as_of_date))) {
                error.push({ message: "As of date is required" })
        }
        
        if (form.as_of_date !== null && checkValidDate(new Date(form.as_of_date))) {
            let asOfDate = new Date(form.as_of_date);
            const msg = validateAsOfDate(asOfDate.getDate(), asOfDate.getMonth() + 1);
            if (msg !== "") {
                error.push({ message: msg });
            }
        }
      
        if (form.amount == null || form.amount === "") {
            error.push({ message: "Amount is required" });
        } 
        else {
            validateMaxAmount(form.amount, "Amount", error);
        }

        console.log("error", error)
        setErrorOpen(error.length > 0)
        if (error.length === 0) {
            Submit();
        }
        setErrorState(error)
    
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            // onClose={action}
            className={"z-index-1"}
            minWidth={500}
            sx={
                hasBlur
                    ? {
                        backdropFilter: "blur(5px)",
                    }
                    : {}
            }
        >
            <DialogTitle sx={{ borderBottom: 1, borderColor: "#ccc", fontSize: "16px", fontWeight: "bold" }} align="center">
                {(!isCreate ? "EDIT" : "ADD") + " CAPITAL BASE"}
                <Invalid pt="pt-20px" errorState={errorState} open={errorOpen} setOpen={setErrorOpen} isShow={true} />
            </DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-slide-description " sx={{ paddingX: "40px" }}>
                    <div className="grid grid-cols-2 gap-4 my-20px">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date desktop"
                                disabled={isCreate ? false : true}
                                inputFormat="dd/MM/yyyy"
                                value={form?.as_of_date || null}
                                onChange={e=>setForm({...form,as_of_date:e})}
                                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 16, color: "#7A58BF", } }}
                                    fullWidth={true} variant="standard" label="AS OF DATE" error={false} />}
                            />
                        </LocalizationProvider>
                        <TextField label="AMOUNT (HKD)" variant="standard" type="text" placeholder="0.00" fullWidth value={addCommasToString(form?.amount)} onChange={e=> inputFormatNumCommaTwoDigit("amount", e.target.value, setForm)} />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <Button variant="text" onClick={action}>
                    Cancel
                </Button>

                <LoadingButton
                    // startIcon={<img alt="" src={SaveIcon} />}
                    variant="contained"
                    //  sx={{ fontSize: "14px" }}
                    onClick={Validate}
                    loading={loading}
                    disabled={loading}
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CapitalBaseModal;
