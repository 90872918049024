import api from "./api.main";
import { apiConfig } from "../config/config.main";

const loanInquiry = async (params = {}, query) => {

  return api.instance.get(`${apiConfig.loanInquiry}${query}`, {
    params,
  });
};

const loanInquiryLatest = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.loanInquiryLatest}${query}`, {
    params,
  });
}


const loanUpdate = async (gl_acct, master_acc, sub_acc, params, type = "Latest Data") => {
  if (type === "Latest Data")
    return api.instance.put(`${apiConfig.loanUpdateLatest}/${gl_acct}/${master_acc}-${sub_acc}`,params,);
  return api.instance.put(`${apiConfig.loanUpdate}/${gl_acct}/${master_acc}-${sub_acc}`,
    params,
  );
};

const loanRegen = async (params) => {
  return api.instance.post(`${apiConfig.loanRegen}`,
    params,
  );
};

const loanDownload = async (date) => {
  // /v1/parameter/bond/download?report_date=yyyy-mm-dd

  // return api.instance.get(`${apiConfig.`);
  return api.instance.get(`/v1/parameter/loan/download?report_date=${date}`)

}

const loanLatestDownload = async (showAll) => {
  return api.instance.get(`/v1/parameter/loanlatest/download?showall=${showAll}`);
}



const exportedObject = {
  loanInquiry,
  loanUpdate,
  loanRegen,
  loanInquiryLatest,
  loanDownload,
  loanLatestDownload
};

export default exportedObject;
