import { useState } from "react";
import AlertModal from "../components/modal/alertModal";
import DownloadIcon from "../assets/icon/download.svg";
import ErrorIcon from "../assets/icon/error.svg";
import reportApi from "../api/reportInquiry";
import useQuery from "../hooks/useQuery";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { apiBaseURL } from "../config/config.main";
import ConfirmModal from "../components/modal/confirmModal";
import { optionGroupUnstyledClasses } from "@mui/base";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import capitalBaseApi from "../api/capitalBaseAPI";
import moment from "moment";

export const currentTime = () => {
  return new Date().toLocaleString("th-TH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const onClickScroll = (input) => {
  const yOffset = -130;
  const element = document.getElementById(input);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: "smooth" });
};

export const saveTokenToLocalStorage = (data) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("expired_at", getExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    getExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    getExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
};

export const getExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const getUserFromStorage = () => {
  let data = { user: null };

  let getUserStorage = sessionStorage.getItem("user");
  if (getUserStorage) {
    data.user = JSON.parse(getUserStorage);
  }
  return data.user;
};

export const getTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
    info: parseJwt(access_token),
  };
};

export const saveUserLogin = (isLoggedIn) => {
  if (isLoggedIn) {
    sessionStorage.setItem("isLoggedIn", true);
  } else {
    sessionStorage.removeItem("isLoggedIn");
  }
};

export const checkUserLogin = () => {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  return isLoggedIn != undefined;
};

export const parseJwt = (token) => {
  if (!token) return null;
  var base64Url = token?.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

export const ColorStatus = (status) => {
  if (status === "IN_PROGRESS")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "COMPLETE")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const formateDate = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) {
    return "";
  }
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateTime = (dateString) => {
  if (dateString === "" || dateString === null || dateString === undefined) {
    return "";
  }
  const dateTime = moment(dateString).format("DD/MM/YYYY - hh:mm")
  return dateTime.toString()
};

export const ScbText = (text) => {
  return <div className="text-textscb">{text}</div>;
};

export const formateDateWithTimeHourMintues = (dateString) => {
  if (dateString === "" || dateString === null || !dateString) return "";
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  return (
    dateSplit[2] +
    "/" +
    dateSplit[1] +
    "/" +
    dateSplit[0] +
    " - " +
    timeSplit[0] +
    ":" +
    timeSplit[1]
  );
};

export const CapitalbaseColumnUpdate = (value) => {
  const [loading, setLoading] = useState(false);

  const onClickRegen = async () => {
    try {
      setLoading(true);

      console.log("value", value);
      const response = await capitalBaseApi.capitalBaseRegen({
        report_date: value?.as_of_date,
      });
      console.log("regen", response);
    } catch (error) {
      console.log("regen error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-between">
      <div>{value?.update_user}</div>
      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{ fontSize: "14px" }}
        startIcon={<RefreshIcon />}
        onClick={onClickRegen}
      >
        REGEN
      </LoadingButton>
    </div>
  );
};

export const ReportNameColumn = (name) => {
  const colorByStatus = (status) => {
    if (status === "IN_PROGRESS") return "bg-yellow-500";
    if (status === "COMPLETE") return "bg-green-500";
    return "bg-red-500";
  };

  var shouldTab = name.report_name === name.prevName;

  return (
    <div className={"w-96 flex flex-row " + (shouldTab && "ml-16")}>
      <div
        className={
          colorByStatus(name.colorStatus) + " rounded-full w-3 h-3 mr-3 mt-1"
        }
      ></div>
      {name.report_name}
    </div>
  );
};

export const downloadFile = (fileBlob, filename) => {
  const myJsonString = JSON.stringify(fileBlob);
  const blob = new Blob([myJsonString], {
    //    type: "text/plain;charset=utf-8",
    type: "application/vnd.ms-excel;charset=utf-8",
    //type: "application/xml;charset=utf-8"
    // type: "application/vnd.ms-excel;charset=utf-8"
    //    type: "application/vnd.ms-excel;charset=utf-8"

    ///  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //  type :"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
  });
  FileSaver.saveAs(blob, filename);
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const dateSafety = (date) => {
  if (date === "" || date === null || !date) return "";

  // if(date === "Invalid date") return format(new Date(), "yyyy-MM-dd")
  // if(date instanceof Date) return   format(new Date(date), "yyyy-MM-dd");

  // console.log("query by ",date)

  if (isValidDate(date)) return format(new Date(date), "yyyy-MM-dd");
  return format(new Date(), "yyyy-MM-dd");
};

export const Download = (record) => {
  const [showModal, setShowModal] = useState(false);
  const download = async (checkStatus = true) => {
    if (checkStatus && showModal) return;
    if (checkStatus && record.report_status !== "COMPLETE") {
      setShowModal(true);
      return;
    }

    console.log("download report", record);
    const access_token = sessionStorage.getItem("access_token");
    fetch(apiBaseURL + "/v1/reports/download/" + record.report_id, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }).then((response) => {
      const filename = response.headers
        .get("Content-Disposition")
        .split("filename=")[1];
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });

    ///downloadFile(response, filename)
    // }
    // catch (e) {
    //   console.log("error", e)
    // }
    // const { data, loading } =  useQuery( reportApi.downloadReport,{"id":record.report_file_path}, {}, []);
  };

  const confirmDownlaod = () => {
    download(false);
    setShowModal(false);
    // download();
  };

  return (
    <div
      className="flex text-white cursor-pointer bg-textscb p-3 rounded-lg"
      onClick={download}
    >
      <ConfirmModal
        isAlert={true}
        title={"Generate Report"}
        message="Report is processing. Do you want to download it ?"
        onConfirm={confirmDownlaod}
        open={showModal}
        setOpen={setShowModal}
      />
      <img className="text-white mr-3" src={DownloadIcon}></img>
      DOWNLOAD
    </div>
  );
};

export const DownloadCellCommonWithOutText = (record) => {
  const [openError, setOpenError] = useState(false);
  const [errorApi, setReturnErrorApi] = useState("");

  const onClickDownLoad = async () => {
    // try {
    //   if (record.status === "FAILED") {
    //     setOpenError(true);
    //     return;
    //   }
    //   const response = await reportService.downloadReport(record.request_id);
    //   let filename = response.headers["content-disposition"].replace(
    //     "attachment; filename=",
    //     ""
    //   );
    //   return downloadFile(response.data, filename);
    // } catch (error) {
    //   if (error.response.status === 400) {
    //     setReturnErrorApi(convertErrorApi("Data not found"));
    //     setOpenError(true);
    //   }
    // }
  };

  return <div>hey</div>;

  if (record === undefined || record === null || record === "") {
    return <></>;
  }

  if (record.status === "FAILED") {
    return (
      <div>
        <AlertModal
          open={openError}
          title={"Priced Transaction"}
          description={record.fail_reason}
          action={() => setOpenError(false)}
        />
        <img onClick={onClickDownLoad} className="" src={ErrorIcon} />
      </div>
    );
  }

  if (record.status !== "SUCCESS") {
    return null;
  }

  // return (
  //   <div>
  //     <AlertModal
  //       open={openError}
  //       title={"Priced Transaction"}
  //       description={errorApi}
  //       action={() => setOpenError(false)}
  //     />
  //     <div
  //       onClick={onClickDownLoad}
  //       target={"_blank"}
  //       className="flex text-textscb cursor-pointer"
  //     >
  //       <img className="" src={DownloadIcon}></img>
  //     </div>
  //   </div>
  // );
};
