import api from "./apiConfig";
import modal from "./modalConfig";

export const apiConfig = api;

export const modalConfig = modal;

export const version = window?.config?.version;
export const apiBaseURL = window?.config?.apiBaseURL;

export const sessionExpire = window?.config?.sessionExpire;

export const timeReloadReport = window?.config?.timeReloadReport;
