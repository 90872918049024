import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ROUTE_BOUND, ROUTE_LOAN } from "../constrant/route.constrant";
import Loan from "../pages/loan";
import ProtectedRoute from "./protectedRoute";

function LoanRoute() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Routes>
      <Route path={ROUTE_LOAN} element={<ProtectedRoute can_see={user?.user_role === "MANAGEMENT"||user?.user_role === "REPORT"}><Loan /></ProtectedRoute>} />
    </Routes>
  );
}

export default LoanRoute;