export const Content = ({
  label = "label",
  value = "value",
  valueStyle = "",
  mode = "",
  overwriteText = "text-label",
  w = "w-full",
  disable = false
}) => {
  return (
    <div className={mode} id="Content">
      <div
        id="ContentLabel"
        className={`${overwriteText} text-textlabel ${w} `}
      >
        {label}
      </div>
      <div
        id="ContentValue"
        className={"text-value font-bold break-words " + valueStyle + (disable?" text-disable ":" text-textprimary" )}
      >
        {value}
      </div>
    </div>
  );
};
