import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";

// context
import { RootContext } from "../../context/root.context";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import LogoutIcon from "@mui/icons-material/Logout";

import ScbLogo from "../../assets/images/SCB-logo.svg";
import ScbUserIcon from "../../assets/icon/user.svg";
import Scbtaxshceme from "../../assets/icon/taxscheme.svg";
import ScbAccount from "../../assets/icon/account.svg";

import UserIcon from "../../assets/icon/userSolo.svg";
import ScbReport from "../../assets/icon/report.svg";

import ListbarCollapse from "../listbar/listbarCollapse";
import Listbar from "../listbar/listbar";
import SecondaryButton from "../button/secondaryButton";
import { DRAWER } from "../../constrant/style.constrant";
import { TEXT_PARAMETER } from "../../constrant/text.constrant";
import {
  PATH_NAME_REPORT,
} from "../../constrant/pathname.constrant";
import { useNavigate } from "react-router-dom";
import { getTokenFromLocalStorage, currentTime } from "../../utils/util.main";
import { ROUTE_ACCOUNT, ROUTE_BOUND, ROUTE_CAPITALBASE, ROUTE_CUSTOMER, ROUTE_LOAN, ROUTE_NOSTRO, ROUTE_REPORT } from "../../constrant/route.constrant";
import { Payment } from "@mui/icons-material";
import { logout } from "../../redux/authSlice";
import authService from "../../api/authService";

const openedMixin = (theme) => ({
  width: DRAWER,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `42px`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER,
  flexShrink: 0,
  whiteSpace: "nowrap",
  // boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function HKDrawer({ version }) {
 // const dispatch = useDispatch()
  const { openDrawer, setOpenDrawer } = useContext(RootContext);
  const { user } = useSelector((state) => state.auth);
  const [now, setNow] = useState(currentTime());
  const { setRouteName } = useContext(RootContext);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    let updateTimer = setInterval(() => {
      setNow(currentTime());
    }, 10000);
    return () => clearInterval(updateTimer);
  }, []);

  const onClickLogout = async () => {
    try {
      const token = getTokenFromLocalStorage();
      //   await authService.logout({ refresh_token: token.refresh_token });

      await authService.logout({ refresh_token: token.refresh_token });
      sessionStorage.clear();
      navigate("/login");
    } catch (error) {
      console.log("logout error", error);
    }
  };

  return (
    <Drawer variant="permanent" open={openDrawer}>
      {openDrawer ? (
        <div></div>
      ) : (
        <div className="p-md w-42px">
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge={false}
            sx={{
              width: "100%",
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </div>
      )}
      {openDrawer && (
        <div className="m-10px">
          <DrawerHeader>
            <div className="w-full flex flex-col items-center justify-center space-y-2 text-textgray text-10px">
              <div className="flex justify-between w-full items-center">
                <div
                  onClick={() => {setRouteName([]); navigate("/")}}
                  className="flex justify-center items-center cursor-pointer"
                >
                  <img alt="" className="" src={ScbLogo} />
                </div>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </div>
              <div className="bg-gray-100 w-full rounded-md  flex">
                <div>
                  <img alt="user_icon" className="py-4 px-2" src={UserIcon} />
                </div>
                <div className="grid grid-cols-1 py-2 my-auto">
                  <div className="uppercase text-10px truncate" title={user?.userName}>
                    {user?.userName}

                  </div>
                  <div className="uppercase text-8px">
                    {user?.userId}
                  </div>
                </div>
              </div>

              <SecondaryButton
                onClick={onClickLogout}
                startIcon={<LogoutIcon />}
                text="Logout"
              />
            </div>
          </DrawerHeader>
          <React.Fragment>
            <div className="text-textgray">
              <Listbar textHeader={PATH_NAME_REPORT}
                items={
                  [
                    {
                      text: PATH_NAME_REPORT,
                      imgIcon: ScbReport,
                      path: ROUTE_REPORT,
                    },
                  ]
                } />
              <Divider />
              {(user?.user_role === "MANAGEMENT"  ||  user?.user_role === "REPORT") && <React.Fragment>

                <ListbarCollapse
                  items={[
                    {
                      text: "CAPITAL BASE",
                      imgIcon: ScbAccount,
                      path: ROUTE_CAPITALBASE,
                    },
                    {
                      text: "A/C GROUP MGT",
                      imgIcon: ScbUserIcon,
                      path: ROUTE_ACCOUNT,
                    },
                    {
                      text: "BOND DETAIL",
                      imgIcon: Scbtaxshceme,
                      path: ROUTE_BOUND,
                    },
                    {
                      text: "LOAN DETAIL",
                      imgIcon: Scbtaxshceme,
                      path: ROUTE_LOAN,
                    },
                    {
                      text: "NOSTRO DETAIL",
                      imgIcon: Scbtaxshceme,
                      path: ROUTE_NOSTRO,
                    },
                  ]}
                  textHeader={TEXT_PARAMETER}
                />
                <Divider />
              </React.Fragment>}

            </div>
          </React.Fragment>
        </div>
      )}
      {openDrawer && (
        <div className="fixed w-200px bottom-0 mt-auto text-10px flex flex-row justify-between bg-gray-100 p-xs">
          <div className="text-textsecoundary">{now}</div>
          <div className="text-textsecoundary">{version}</div>
        </div>
      )}
    </Drawer>
  );
}

export default HKDrawer;
