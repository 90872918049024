import React from "react";

//mui
import List from "@mui/material/List";

import { CustomListBarNavLink } from "../listbar/listbarCollapse";

function Listbar({ textHeader = "", items = [], ...props }) {
  return (
    <List {...props}>
      {items.map((item, index) => {
        if (item.hidden) return null;
        //   const { imgIcon, text = "" } = item;
        return (
          <CustomListBarNavLink key={index} item={item} icon={item.icon} />
        );
      })}
    </List>
  );
}

export default Listbar;
