import api from "./api.main";
import { apiConfig } from "../config/config.main";

const accountOption = async () => {
  return api.instance.get(`${apiConfig.accountRM}`);
};

const accountRegen = async (params = {}) => {
  return api.instance.post(`${apiConfig.accountGroupRegen}`,params);
};


const accountInquiry = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.accountGroupInquiry}${query}`, {
    params,
  });
};

const accountCreate = async (params) => {
  return api.instance.post(`${apiConfig.accountGroupCreate}`, params);
};

const accountUpdate = async (parent_id,params) => {
  params.old_parent_name = parent_id;
  return api.instance.put(`${apiConfig.accountGroupUpdate}`, params);
};

const accountDelete = async (parent_id,params) => {
  return api.instance.put(`${apiConfig.accountGroupDelete}`, {id: parent_id});
};

const accountExportExcel = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.accountGroupExportExcel}`, {
    params,
  });


};


const exportedObject = {
    accountInquiry,
    accountCreate,
    accountUpdate,
    accountDelete,
    accountOption,
    accountExportExcel,
    accountRegen
};

export default exportedObject;
