import React, { useEffect } from "react";
import { Alert, AlertTitle, Collapse } from "@mui/material";

function HKError({ error, mt = "16" ,title = "Server Error"}) {
  const [open, setOpen] = React.useState(true);

  useEffect( () => {
    setOpen(true)
  }, [error]); 

  return (
    <div className={"w-full max-w-[calc(100vw-200px)] mt-" + mt + " -mb-16"}>
      <Collapse in={open}>
        <Alert onClose={() => setOpen(false)} severity="error">
          <AlertTitle>{title}</AlertTitle>
          {error?.message}
        </Alert>
      </Collapse>
    </div>
  );
}

export default HKError;
