const sestionExpire = {
  sessionExpiredModal: {
    title: "Session Expired",
    description: "Session expired",
    actionModal: async () => {
      window.open("/login", "_self");
    },
  },
  networkErrorModal: {
    title: "Network Error",
    description: "Can't connect to server. please try again later.",
    actionModal: () => {
      // store.dispatch(setModal({ open: false }));
    },
  },
  sendApproverConfirm: {
    description: "Do you want to submit change request to approver",
  },
  sendApproverSuccess: {
    description: "Request Has Been Sent to Approver",
  },
  sendApproverError: {
    description: "Invalid data",
  },
};

export default sestionExpire;
