import { Route, Routes } from "react-router-dom";
import { ROUTE_BOUND } from "../constrant/route.constrant";
import Bound from "../pages/bound";
import ProtectedRoute from "./protectedRoute";
import React from "react"
import { useSelector } from "react-redux";

function BoundRoute() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Routes>
      <Route path={ROUTE_BOUND} element={<ProtectedRoute can_see={user?.user_role === "MANAGEMENT"||user?.user_role === "REPORT"}><Bound /></ProtectedRoute>} />
    </Routes>
  );
}

export default BoundRoute;