import { Route, Routes } from "react-router-dom";
import { ROUTE_BOUND, ROUTE_LOAN, ROUTE_NOSTRO } from "../constrant/route.constrant";
import Nostro from "../pages/nostro";
import ProtectedRoute from "./protectedRoute";
import React from "react"
import { useSelector } from "react-redux";

function NostroRoute() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Routes>


      <Route path={ROUTE_NOSTRO} element={<ProtectedRoute can_see={user?.user_role === "MANAGEMENT"||user?.user_role === "REPORT"}><Nostro />   </ProtectedRoute>} />


    </Routes>
  );
}

export default NostroRoute;