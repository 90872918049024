import EditIcon from "../../assets/icon/edit.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { RootContext } from "../../context/root.context";
import Skeleton from "react-loading-skeleton";
import useQuery from "../../hooks/useQuery";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import loanAPI from "../../api/loanBaseAPI";
import { LOAN_DETAIL, MAP_YES_NO } from "../../constrant/text.constrant";
import { ROUTE_LOAN } from "../../constrant/route.constrant";
import Download from "../../assets/icon/download_scb.svg";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { LoanTable } from "../../components/table/loanTable";
import { formateDate, formateDateWithTimeHourMintues, isValidDate, ScbText } from "../../utils/util.main";
import LoanModal from "../../components/modal/loanModal";
import HKError from "../../components/error/alertError";
import { addCommasToString, formatNumberCommaTwoDigit } from "../../utils/util";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { OptionRadio } from "../../components/Input/yesnoRadio";
import AlertModal from "../../components/modal/alertModal";
import { useSelector } from "react-redux";
import ReactExport from "react-export-excel-xlsx-fix";
import moment from "moment";
import { apiBaseURL } from "../../config/config.main";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Loan = () => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const [dateValue, setDateValue] = useState(date);
  const [reload, setReload] = useState(0);
  const [viewType, setViewType] = useState("Latest Data");
  const [showAll, setShowAll] = useState("N")
  const { setRouteName, routeName, showModal, setShowModal } =
    useContext(RootContext);

  const { data, loading, error } = useQuery(
    viewType === "Latest Data"
      ? loanAPI.loanInquiryLatest
      : loanAPI.loanInquiry,
    {
      report_date:
        dateValue instanceof Date && !isNaN(dateValue)
          ? format(new Date(dateValue), "yyyy-MM-dd")
          : "",
      showall: showAll

    },
    {},
    [dateValue, reload, showAll],
    {
      // checkFirstLoad:    viewType === "Latest Data" ||
      //  (dateValue instanceof Date && !isNaN(dateValue) ? true : false),
      checkFirstLoad: true
    }
  );


  // console.log("date val " + (dateValue instanceof Date && !isNaN(dateValue)),dateValue )
  const [selectedRow, setSelectedRow] = useState(null);
  const [regenLoading, setRegenLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [errorState, setErrorState] = useState([])
  const [errorOpen, setErrorOpen] = useState(false);

  const changeViewType = (e) => {
    setViewType(e.target.value);
    setReload(reload + 1);
    // if (e.target.value === "Latest Data") {
    //     setDateValue(date)
    // }
    // else {
    //     setDateValue(null)
    // }
  };

  const RowAction = (item) => {
    if (user?.user_role === "REPORT") return <></>;
    return (
      <button>
        <img
          className="h-20px w-20px cursor-pointer"
          src={EditIcon}
          onClick={() => {
            setSelectedRow({
              ...item,
              // general_specific:
              cn_related: MAP_YES_NO[item?.cn_related],

              int_bearing_flag: MAP_YES_NO[item?.int_bearing_flag],
            });
            setShowModal(true);
          }}
        ></img>
      </button>
    );
  };

  useEffect(() => {
    setRouteName([
      { title: "PARAMETER", path: "/" },
      { title: LOAN_DETAIL, path: ROUTE_LOAN },
    ]);
    setShowModal(false);
  }, []);

  const regen = async () => {
    if (
      dateValue === null ||
      dateValue === undefined ||
      !isValidDate(dateValue)
    )
      return setShowAlert(true);
    try {
      setRegenLoading(true);
      const ret = await loanAPI.loanRegen({
        report_date: format(new Date(dateValue), "yyyy-MM-dd"),
      });
    } catch (e) {
      setErrorState({ message: e?.response.data?.status?.description })
    } finally {
      setRegenLoading(false);
    }
  };

  const regenValidate = () => {
    const asOfdate = moment(dateValue).format("YYYY-MM-DD");
    const invalid = (asOfdate.toString() === "Invalid date") || asOfdate.toString().startsWith("0")
    if (invalid) {
      setErrorOpen(true);
      setErrorState({ message: "As of date is required" });
    }
    else {
      setErrorOpen(false);
      regen();
    }
  }

  const closeModal = (shouldReload = false) => {
    setShowModal(false);
    if (shouldReload) setReload(reload + 1);
  };

  const createDataWithStyle = (data = []) => {
    if (!data) return;

    var result = [];

    for (let i = 0; i < data.length; i++) {
      var filled_col_0 = { patternType: "solid", fgColor: { rgb: "FDFFA7" } };
      const borders = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      var filled = { fgColor: { rgb: "FFFFFF" } };


      result.push([
        {
          value: data[i]?.gl_acct || "",
          style: { font: { sz: 14 }, alignment: "left", fill: filled_col_0 },
        },
        {
          value: data[i]?.master_acc || "",
          style: { font: { sz: 14 }, alignment: "left" },
        },
        {
          value: data[i]?.sub_acc || "",
          style: { font: { sz: 14 }, alignment: "left" },
        },
        {
          value: data[i]?.account_name || "",
          style: { font: { sz: 14 }, alignment: "left" },
        },
        {
          value: data[i]?.original_curr || "",
          style: { font: { sz: 14 }, alignment: "left" },
        },
        {
          value: formatNumberCommaTwoDigit(data[i]?.os_amount_original),
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.geographical_cn || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.country_usage || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.cn_related || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.general_specific || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.credit_rating || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.hkma_rating || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },

        {
          value: formatNumberCommaTwoDigit(data[i]?.provision_amt_hkd),
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: formateDate(data[i]?.drawdown_avail_dt) || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.int_bearing_flag || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.fix_var || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: getEconomicSectorFullName(data[i]?.economic_sector) || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
      ]);

    }

    return result;
  };

  const multiDataSet = [
    {
      columns: [
        {
          value: "G/L ACC",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "MASS ACC/CONTRACT NUM",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "SUB ACC/TRANS NUM",
          widthPx: 450,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "ACCOUNT NAME",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        }, //char width
        {
          value: "ORIGINAL CURR",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },

        {
          value: "AMOUNT [OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },

        },
        {
          value: "GEOGRAPHICAL IN CN",
          widthPx: 200,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "COUNTRY USAGE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "CN RELATED",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GENERAL/SPECIFIC",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "CREDIT RATING",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "HKMA RATING",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PROVISION AMT (HKD)",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },

        },
        {
          value: "DRAWDOWN AVAILABLE DT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "INT BEARING FLAG",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "FIX/VAR",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "ECONOMIC SECTOR",
          widthPx: 200,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
      ],
      data: createDataWithStyle(data),
    },
  ];

  const onClickExportExcel = async() => {
    let uri = `/v1/parameter/loanlatest/download?showall=${showAll}`;
    if (viewType === "Data as of Date") {
      const reportDate = format(new Date(dateValue), "yyyy-MM-dd");
      uri = `/v1/parameter/loan/download?report_date=${reportDate}`;
    }

    try {
      const access_token = sessionStorage.getItem("access_token");
      const url = apiBaseURL + uri;
      fetch(url, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
    } catch (e) {
      console.log("error", e);
      alert("Unable to export excel");
    }
  };

  return (
    <div className="w-full">
    {errorOpen ? <HKError error={errorState} title="ValidationError" /> : 
      (error || errorState?.message) && <HKError error={error || errorState} />}
      <div className="mt-20px p-large w-5/6">
        <AlertModal
          description="Plase Select Date"
          open={showAlert}
          action={() => setShowAlert(false)}
        />
        <LoanModal
          type={viewType}
          reportDate={
            dateValue instanceof Date && !isNaN(dateValue)
              ? format(new Date(dateValue), "yyyy-MM-dd")
              : ""
          }
          open={showModal}
          action={closeModal}
          data={selectedRow}
        />
        <div className="flex flex-row justify-center  p-large uppercase pb-0">
          <OptionRadio
            label=""
            value={viewType}
            values={["Latest Data", "Data as of Date"]}
            handleChange={changeViewType}
          />
          {viewType === "Data as of Date" ? (
            <div className="flex flex-row mt-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  //    views={['day', 'month', 'year']}
                  value={dateValue}
                  onChange={setDateValue}
                  sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 16, color: "#7A58BF" },
                      }}
                      variant="standard"
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              <div className="w-4"></div>
              <ExcelFile
                filename={`EXPORT_LOAN_${dateValue instanceof Date && !isNaN(dateValue)
                  ? format(new Date(dateValue), "yyyy-MM-dd")
                  : ""
                  }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
                element={<button id="export-excel-loan" hidden></button>}
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name="Loan Detail"
                ></ExcelSheet>
              </ExcelFile>
              <button onClick={onClickExportExcel} className="mx-5">
                <div className="flex flex-row text-textscb font-bold text-14px">
                  <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                  EXPORT EXCEL
                </div>
              </button>
              {user?.user_role !== "REPORT" && (
                <LoadingButton
                  variant="contained"
                  loadingPosition="start"
                  onClick={regenValidate}
                  loading={regenLoading}
                  disabled={regenLoading}
                  sx={{ fontSize: "12px", height: "32px" }}
                  startIcon={<RefreshIcon />}
                >
                  REGEN
                </LoadingButton>
              )}
            </div>
          ) : <div className="flex flex-row">
            <FormControlLabel control={<Checkbox onChange={(e) => setShowAll(showAll === "Y" ? "N" : "Y")} checked={showAll === "Y"} />} label="Show All" />
            <ExcelFile
              filename={`EXPORT_LOAN_${dateValue instanceof Date && !isNaN(dateValue)
                ? format(new Date(dateValue), "yyyy-MM-dd")
                : ""
                }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
              element={<button id="export-excel-loan" hidden></button>}
            >
              <ExcelSheet
                dataSet={multiDataSet}
                name="Loan Detail"
              ></ExcelSheet>
            </ExcelFile>
            <button onClick={onClickExportExcel} className="mx-5 ">
              <div className="flex flex-row text-textscb font-bold text-14px">
                <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                EXPORT EXCEL
              </div>
            </button></div>
          }
        </div>
        {loading ? (
          <div className="p-fourteen">
            <Skeleton
              containerTestId="react-loading-skeleton"
              height={30}
              width={`100%`}
              count={15}
            />
          </div>
        ) : (viewType !== "Latest Data" && dateValue === null) ? (
          <div />
        ) : (
          <LoanTable
            rows={MapData(data)}
            formatControl={[
              RowAction,
              ScbText,
              null,
              null,
              null,
              null,
              formatNumberCommaTwoDigit,
              null,
              null,
              null,
              null,
              null,
              null,
              formatNumberCommaTwoDigit,
              formateDate,
              null,
              null,
              EconomicData,
              formateDateWithTimeHourMintues,
              null,
            ]}
          />
        )}
      </div>
    </div>
  );
};

const MapData = (data) => {
  let temp = [];

  for (let i = 0; i < data?.length; i++) {
    // console.log("try , ",data[i].provision_amt_hkd )

    temp.push({
      action: data[i],
      gl_acct: data[i].gl_acct,
      master_acc: data[i].master_acc,
      sub_acc: data[i].sub_acc,
      account_name: data[i].account_name,
      original_curr: data[i].original_curr,
      os_amount_original: data[i].os_amount_original,
      geographical_cn: data[i].geographical_cn,
      country_usage: data[i].country_usage,
      cn_related: data[i].cn_related,
      general_specific: data[i].general_specific,
      credit_rating: data[i].credit_rating,
      hkma_rating: data[i].hkma_rating,
      provision_amt_hkd: (data[i].provision_amt_hkd),
      drawdown_avail_dt: data[i].drawdown_avail_dt,
      int_bearing_flag: data[i].int_bearing_flag,
      fix_var: data[i].fix_var,
      economic_sector: data[i].economic_sector,
      update_date_time: data[i].update_datetime,
      update_user: data[i].update_user,
    });
  }
  return temp;
};

const EconomicData = (value) => {
  return <div>{getEconomicSectorFullName(value)}</div>;
};

const getEconomicSectorFullName = (value) => {
  switch (value) {
    case "A1a":
      return "A1a. cotton";
    case "A1b":
      return "A1b. others";
    case "A2":
      return "A2. Footware & wearing apparel";
    case "A3":
      return "A3. Metal products & engineering";
    case "A4":
      return "A4. Rubber,plastic & chemicals";
    case "A5a":
      return "A5a. telecommunication equipment";
    case "A5b":
      return "A5b. others";
    case "A6":
      return "A6. Food";
    case "A7":
      return "A7.Beverages & Tobacco";
    case "A8":
      return "A8. Printing & publishing";
    case "A9":
      return "A9. Miscellaneous";
    case "B1a":
      return "B1a. industrial";
    case "B1b":
      return "B1b. residential";
    case "B1c":
      return "B1c. commercial";
    case "B1d":
      return "B1d. other";
    case "B2a":
      return "B2a. industrial";
    case "B2b":
      return "B2b. residential";
    case "B2c":
      return "B2c. commercial";
    case "B2d":
      return "B2d. Others";
    case "B3":
      return "B3. Civil engineering works";
    case "C":
      return "C. Electricity and gas";
    case "D":
      return "D. Recreational activities";
    case "E1":
      return "E1. Telecommunications";
    case "E2":
      return "E2. Others";
    case "F":
      return "F. Wholesale and retail trade";
    case "G1":
      return "G1. Shipping";
    case "G2":
      return "G2. Air transport";
    case "G3":
      return "G3. Taxis";
    case "G4":
      return "G4. Public light buses";
    case "G5":
      return "G5. Others";
    case "H1":
      return "H1. Hotels,boarding houses & catering";
    case "H2a":
      return "H2a.investment companies";
    case "H2b":
      return "H2b.insurance companies";
    case "H2c":
      return "H2c. futures brokers";
    case "H2d":
      return "H2d. finance companies & other";
    case "H3a":
      return "H3a. margin lending";
    case "H3b":
      return "H3b. others";
    case "H4a":
      return "H4a. margin lending";
    case "H4b":
      return "H4b. others";
    case "H5":
      return "H5. Professional & private individuals";
    case "H5a":
      return "H5a. for the purchase of flats covered by the guarantee issued by the Housing Authority under Hom Ownership Scheme, Private Sector Participation Scheme & Tenants Purchase Scheme";
    case "H5b":
      return "H5b. for the purchase of other residential properties";
    case "H5c":
      return "H5c. for credit card advances";
    case "H5d":
      return "H5d. for other business purposes";
    case "H5e":
      return "H5e. for other private purposes";
    case "H6":
      return "H6. All Other";
    case "J":
      return "J. TRADE FINANCING";
    case "K":
      return "K. OTHER LOANS & ADVANCES";
    default:
      return value;
  }
};
export default Loan;
