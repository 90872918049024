const apis = {
  logoutAPI: "/v1/auth/logout",
  loginAPI: "/v1/auth/login",
  getTokenAPI: "/v1/openid-connect/token",
  refreshTokenAPI: "/v1/auth/refresh",
  getUserProfile: "/v1/userprofile",
  getAllUserProfile: "/v1/users",
  getUserIdap: "/v1/user-ldap",
  getReportInquiry: "/v1/reports",
  downloadReport : "/v1/reports/download",
  capitalBaseList: "/v1/parameter/capitalbase",
  capitalBaseCreate: "/v1/parameter/capitalbase",
  capitalBaseUpdate: "/v1/parameter/capitalbase",
  capitalBaseDelete: "/v1/parameter/capitalbase",
  capitalBaseRegen: "/v1/parameter/capitalbase/regen",

  boundInquiry: "/v1/parameter/bond",
  boundLatest: "/v1/parameter/bondlatest",
  boundCreate: "/v1/parameter/bond",
  boundUpdate: "/v1/parameter/bond",
  boundDelete: "/v1/parameter/bond",
  boundRegen: "/v1/parameter/bond/regen",
  bondDel: "/v1/parameter/bond/delete",
  bondDelLatest: "/v1/parameter/bondlatest/delete",

  accountGroupInquiry: "/v1/parameter/accountgroup",
  accountGroupCreate: "/v1/parameter/accountgroup",
  accountGroupUpdate: "/v1/parameter/accountgroup",
  accountGroupDelete: "/v1/parameter/accountgroup/delete",
  accountGroupExportExcel: "/v1/parameter/accountgroup/download",
  accountGroupRegen : "/v1/parameter/accountgroup/regen",

  accountRM: "/v1/rm_no",

  loanInquiry: "/v1/parameter/loan",
  loanInquiryLatest: "/v1/parameter/loanlatest",
  loanUpdate: "/v1/parameter/loan",
  loanUpdateLatest: "/v1/parameter/loanlatest",
  loanRegen:"/v1/parameter/loan/regen",

  nostroInquiry: "/v1/parameter/nostro",
  nostroRegen: "/v1/parameter/nostro/regen",
  nostroLatest: "/v1/parameter/nostrolatest",
};

export default apis;
