import {
  MIN_VALUE,
  MAX_VALUE,
  FEE_TABLE_TYPE,
  STEP,
  NULL_DATE,
  TEXT_FIELD_DATE_INVALID_AD,
  ERROR_MESSAGE_REQUIRED,
} from "../constrant/text.constrant";
import { format } from "date-fns";
import _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import numeral from "numeral";
import moment from "moment";
import { useState, useEffect } from 'react';


export const checkInvalidPast = (currentDate = new Date()) => {
  const today = formatDateApi();
  const currentFormatDate = formatDateApi(new Date(currentDate));

  if (today > currentFormatDate) {
    return true;
  }

  return false;
};

export const validateAsOfDate = (day, month) => {
  console.log(day, month);
  let error = `As of date is required lastDay of quarter ${checkQuarter(month)}`;
  if (month === 3 || month === 12) {
    return day === 31 ? "" : error;
  } else if (month === 6 || month === 9) {
    return day === 30 ? "" : error;
  }
  return error;
};

const checkQuarter = (month) => {
  if (month <= 3) {
    return 1;
  } else if (month <= 6) {
    return 2;
  } else if (month <= 9) {
    return 3;
  } else {
    return 4;
  }
};
export const formatDateToAPI = (date) => {
  var checkDate = date;
  if (date.length === 10) {
    checkDate = new Date(checkDate)
  }
  if (!checkValidDate(checkDate)) return 0
  return format(checkDate, "yyyy-MM-dd")
}

export const errMaxAmount = (field) => {
  return `${field} maximum value 9,999,999,999,999.99`;
};

export const validateMaxAmount = (value, fieldName, err, isNagative = false) => {
  if (value.length < 1) {
    err.push({ message: `${fieldName} is required` });
  }
  if (isNagative) {
    const errMsg = `${fieldName} value -9,999,999,999,999.99 to 9,999,999,999,999.99`;
    if (typeof value === "string") {
      const amt = parseFloat(value.replace(/,/g, ""));
      !(amt >= -9999999999999.99 && amt <= 9999999999999.99) && err.push({ message: errMsg });
    } else if (typeof value === "number") {
      !(value >= -9999999999999.99 && value <= 9999999999999.99) && err.push({ message: errMsg });
    }
  }
  else {
    if (typeof value === "string") {
      parseFloat(value.replace(/,/g, "")) > 9999999999999.99 && err.push({ message: errMaxAmount(fieldName) });
    } 
    else if (typeof value === "number") {
      value > 9999999999999.99 && err.push({ message: errMaxAmount(fieldName) });
    }
  }
};

export const inputFormatNumCommaTwoDigit = (fieldName, val, setValue, isNagative = false) => {
  const value = val.replace(/[^-\d.]/g, "");
  let regexNum = /^\d*\.?\d{0,2}$/;
  const regexComma = /\B(?=(\d{3})+(?!\d))/g;

  if (isNagative) {
    regexNum = /^-?\d*\.?\d{0,2}$/;
  }

  if (regexNum.test(value)) {
    const [numValue, digit] = value.split(".");
    if (digit !== undefined) {
      const addComma = numValue.replace(regexComma, ",");
      const formattedValue = `${addComma}.${digit}`;
      if (digit.length <= 2) {
        setValue((prevData) => ({
          ...prevData,
          [fieldName]: formattedValue,
          }));
      }
    } else {
      const formattedIntegerPart = value.replace(regexComma, ",");
      setValue((prevData) => ({
        ...prevData,
        [fieldName]: formattedIntegerPart,
        }));
    }
  }
}

export const convertInputNumCommaToNumber = (value) => {
  const newValue = value?.toString().replace(/,/g, '');
  return parseFloat(newValue);
}

export function addCommasToString(value) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumber = (value) => {
  if (value === MIN_VALUE) return "MIN";
  else if (value === MAX_VALUE) return "MAX";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const mappingValue = (value, defaultValue) => {
  if (value) {
    return value;
  }
  return defaultValue;
};

export const formatNumberComma = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};



export const formatNumberCommaTwoDigit = (value) => {
  return numeral(value).format("0,0.00");
};

export const adjustStringLength = (str, length = 999) => {
  if (str.length <= length) return str
  return str.substring(0, 17) + "..."
}

export const getLastThreeQuaterBegin = () => {
  var quarterAdjustment = (moment().month() % 3) + 1;
  var lastQuarterEndDate = moment().subtract({ months: quarterAdjustment }).endOf('month');
  var lastQuarterStartDate = lastQuarterEndDate.clone().subtract({ months: 8 }).startOf('month');

  return new Date(lastQuarterStartDate)

}

export const getLastThreeQuaterEnd = () => {
  var quarterAdjustment = (moment().month() % 3) + 1;
  var lastQuarterEndDate = moment().subtract({ months: quarterAdjustment }).endOf('month');

  return new Date(lastQuarterEndDate)

}

export const getClearValueObject = (data) => {
  const keys = Object.keys(data);
  let newObject = { value: [] };

  keys.forEach((key) => {
    if (data[key]) {
      if (key === "qualifiers") {
        const keyQualifers = Object.keys(data.qualifiers);
        keyQualifers.forEach((qualifier) => {
          newObject = {
            ...newObject,
            qualifiers: {
              ...newObject?.qualifiers,
              [qualifier]: [],
            },
          };
        });
      } else if (key === "value") {
        data.value.forEach((val) => {
          const keyValue = Object.keys(val);
          keyValue.forEach((valKey) => {
            newObject = {
              ...newObject,
              value: [{ ...val[valKey], [valKey]: "" }],
            };
          });
        });
      } else {
        newObject = {
          ...newObject,
          [key]: "",
        };
      }
    }
  });

  return newObject;
};

export const getWordSpaceUppercase = (word) => {
  const splitWord = word.split(" ");
  let newWord = "";
  if (splitWord.length > 1) {
    splitWord.forEach((itemSplitWord) => {
      newWord += `${_.capitalize(itemSplitWord)} `;
    });
    return newWord;
  }

  return _.capitalize(word);
};

export const formatEmpty = (value, emptyValue = "-") => {
  if (value === 0) {
    return 0;
  }
  if (!value) {
    return emptyValue;
  }
  return value;
};

export const formatNumberMinReverse = (value) => {
  if (value === "MIN") return 0;
  return value;
};

export const formatNumberMaxReverse = (value) => {
  if (value === "MAX") return 0;
  return value;
};

export const formatNumberMin = (value, disabledComma = false) => {
  if (value === MIN_VALUE) {
    return "MIN";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const formatNumberMax = (value, disabledComma = false) => {
  if (value >= MAX_VALUE) {
    return "MAX";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const convertRequestType = (type) => {
  let convert_type = "";

  if (type === "New Customer / Account Pricelist") {
    convert_type = "NEWSERVICE";
  } else if (type === "New Customer / Account Offer") {
    convert_type = "NEWOFFER";
  } else if (type === "Update Customer / Account Pricelist") {
    convert_type = "UPDATESERVICE";
  } else if (type === "Update Customer / Account Offer") {
    convert_type = "UPDATEOFFER";
  }

  return convert_type;
};

export const isStepTier = (value) => {
  return value === STEP || value === "asd";
};

export const isValidDate = (date) => {
  if (date === NULL_DATE) return true;
  const expireDate = Date.parse(date);
  const now = new Date().setHours(0, 0, 0, 0, 0);
  if (expireDate < now) return false;
  return true;
};

export const formatField = (value) => {
  if (value === undefined) return "-";
  if (!isNaN(value)) return formatNumber(value);
  if (value?.includes("T00:00:00")) return formateDate(value);
  return value;
};

export const fomat = (value, type = 0) => { };

export const calculateRowSpan = (value) => {
  return value + 1;
};

export const formatPercent = (value) => {
  return value + "%";
};

export const formatPercentFourDigits = (value) => {
  return Number.parseFloat(value).toFixed(4) + "%"
};

export const formatFirstCapital = (value)=>{
   if(value === "NONE" || value === "") return "NONE"
   if(value?.length < 2) return ""
   let lowerCase = value.slice(1).toLowerCase();
   let first = value.charAt(0).toUpperCase();
   return first + lowerCase;

}

export const formatNone = (value) => {
  return value;
};

export const checkValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getDateOnly = (date = "") => {
  const myDate = date.split("T");
  if (myDate.length > 0) {
    return myDate[0].slice(0, 10);
  }
  return date;
};

export const convertErrorApi = (message = "") => {
  const splitMessage = message.split(":");
  if (splitMessage.length > 1) {
    return splitMessage[1];
  }

  return splitMessage[0];
};

export const getValidateRateType = (value) => {
  if (
    (value.rate && value.rate_type === "-") ||
    (value.rate && !value.rate_type)
  ) {
    return { error: true };
  }
  return { error: false };
};

export const getGroupIndex = (group, index) => {
  let currentIndex = index;
  for (let newIndex = currentIndex - 1; newIndex >= 0; newIndex--) {
    if (newIndex === 0) {
      return -1;
    }

    if (group?.fee_charge[newIndex]?.isRemove === false) {
      currentIndex = newIndex;
      break;
    }
  }

  return currentIndex;
};

export const getCheckValidateThreeMonth = (from, to) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  if (dateTo.getMonth() - dateFrom.getMonth() > 3) {
    return true;
  }

  return false;
};

export const getFeeChargeError = (service) => {
  let checkListChargeError = [];
  for (let index = 0; index < service.charge_group.length; index++) {
    const group = service.charge_group[index];
    for (let jedex = 0; jedex < group.fee_charge.length; jedex++) {
      const charge = group.fee_charge[jedex];

      if (charge.isRemove === true) {
        continue;
      }

      charge.from_date = getDateOnly(charge.from_date);
      charge.to_date = getDateOnly(charge.to_date);

      const validateLengthValidFromDate = FunctionCheckDateLength(
        charge.from_date,
        "from_date"
      );

      const validateLengthValidToDate = FunctionCheckDateLength(
        charge.to_date,
        "to_date"
      );

      const validateDateValidFromDate = FunctionCheckDateValidCustom(
        charge.from_date,
        "from_date"
      );

      const validateDateValidToDate = FunctionCheckDateValidCustom(
        charge.to_date,
        "to_date"
      );

      let getErrorFromDate = checkFromToDate(
        charge?.from_date,
        charge?.to_date,
        group?.fee_charge?.length > 0 && jedex > 0
          ? group?.fee_charge[getGroupIndex(group, jedex)]?.to_date
          : null,
        "from_date"
      );

      let getErrorToDate = checkFromToDate(
        charge?.from_date,
        charge?.to_date,
        group?.fee_charge?.length > 0 && jedex > 0
          ? group?.fee_charge[getGroupIndex(group, jedex)]?.to_date
          : null,
        "to_date"
      );

      if (
        validateLengthValidFromDate.error ||
        validateLengthValidToDate.error ||
        validateDateValidFromDate.error ||
        validateDateValidToDate.error ||
        getErrorFromDate ||
        getErrorToDate
      ) {
        checkListChargeError.push(true);
      }

      const hasQualifer = charge?.fee_value?.conditions?.length > 0;

      if (!hasQualifer) {
        const tier = charge.fee_value.tier;
        for (let vedex = 0; vedex < charge.fee_value.value.length; vedex++) {
          const value = charge.fee_value.value[vedex];
          checkListChargeError = getFeeValueError(
            charge,
            value,
            tier,
            vedex,
            checkListChargeError,
            charge.fee_value.value
          );
        }
      } else {
        for (
          let codex = 0;
          codex < charge?.fee_value?.conditions?.length;
          codex++
        ) {
          const condition = charge?.fee_value?.conditions[codex];
          const tier = condition.tier;
          for (let vedex = 0; vedex < condition.value.length; vedex++) {
            const value = condition.value[vedex];
            checkListChargeError = getFeeValueError(
              charge,
              value,
              tier,
              vedex,
              checkListChargeError,
              condition.value,
              true,
              condition
            );
          }
        }
      }
    }
  }

  return checkListChargeError;
};

export const getValidateOfferError = (value) => {
  const listError = [];

  value.from_date = getDateOnly(value.from_date);
  value.to_date = getDateOnly(value.to_date);

  const validateLengthValidFromDate = FunctionCheckDateLength(
    value.from_date,
    "from_date"
  );

  const validateLengthValidToDate = FunctionCheckDateLength(
    value.to_date,
    "to_date"
  );

  const validateDateValidFromDate = FunctionCheckDateValidCustom(
    value.from_date,
    "from_date"
  );

  const validateDateValidToDate = FunctionCheckDateValidCustom(
    value.to_date,
    "to_date"
  );

  let getErrorFromDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "from_date"
  );

  let getErrorToDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "to_date"
  );

  if (
    validateLengthValidFromDate.error ||
    validateLengthValidToDate.error ||
    validateDateValidFromDate.error ||
    validateDateValidToDate.error ||
    getErrorFromDate ||
    getErrorToDate
  ) {
    listError.push(true);
  }

  return listError;
};

export const getFeeValueError = (
  charge,
  value,
  tier,
  vedex,
  checkListChargeError = [],
  valueArr = [],
  hasQualifer = false,
  condition
) => {
  let newCheckListError = [...checkListChargeError];

  if (tier !== "NO") {
    var getErrorFrom = getValidateError(
      "from",
      value.from,
      tier,
      "to",
      value.to
    );

    var getErrorTo = getValidateError("to", value.to, tier);

    var getPrvErrorFrom =
      valueArr?.length > 1 &&
      vedex > 0 &&
      checkFormToValue(
        value.from,
        valueArr[vedex - 1].to ? valueArr[vedex - 1].to : 0
      );

    var getMinTier = getValidateError(
      "min_tier",
      value.min_tier,
      tier,
      "max_tier",
      value.max_tier
    );

    var getMaxTier = getValidateError("max_tier", value.max_tier, tier);
  }

  const getFixAmountError = getValidateError(
    "fix_amount",
    value.fix_amount,
    tier
  );

  const getRateError = getValidateError(
    "rate",
    value.rate,
    tier,
    "rate_type",
    value.rate_type
  );

  const getRateTypeError = getValidateRateType(value);

  const getMinError = getValidateError(
    "min",
    !hasQualifer ? charge?.fee_value?.min : condition?.min,
    tier,
    "max",
    !hasQualifer ? charge?.fee_value?.max : condition?.max
  );

  const getMaxError = getValidateError("max", charge.fee_value.max, tier);

  if (
    getPrvErrorFrom ||
    getFixAmountError?.error ||
    getErrorTo?.error ||
    getErrorFrom?.error ||
    getRateError?.error ||
    getRateTypeError?.error ||
    getMinError?.error ||
    getMaxError?.error ||
    getMinTier?.error ||
    getMaxTier?.error
  ) {
    newCheckListError.push(true);
  }

  return newCheckListError;
};

export const isValidErrorApi = (error) => {
  if (error && error.response.data.status.description) {
    return true;
  }
  return false;
};

export const formatDateApi = (dateString = new Date(), nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  // if (dateString === "9999-12-31T00:00:00") return "";
  // if (!checkValidDate(new Date(dateString))) {
  //   dateString = new Date();
  // }
  // let splitTime = new Date(dateString).toISOString()?.split("T");
  // let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  // let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  // return dateSplit[0] + "-" + dateSplit[1] + "-" + dateSplit[2] + "T00:00:00";

  const currentDate = `${format(new Date(dateString), "yyyy-MM-dd")}T00:00:00`;
  return currentDate;
};

export const formateDate = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateWithTime = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return (
    dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] + " " + splitTime[1]
  );
};

export const formateDateWithTimeHourMintues = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  return (
    dateSplit[2] +
    "/" +
    dateSplit[1] +
    "/" +
    dateSplit[0] +
    " " +
    timeSplit[0] +
    ":" +
    timeSplit[1]
  );

  // return format(new Date(dateString), "dd/MM/yyyy HH:mm");
};

export const checkFeeChargeAllDelete = (fee_charge = []) => {
  return fee_charge.every((charge) => charge?.action === "DELETE");
};

export const onScrollToTop = (id) => {
  const doc = document.getElementById(id);
  if (doc) {
    window.scrollTo(0, -100);
  }
};

export const getFeeChargeOfGroup = (charge_group = []) => {
  let fee_charge = [];
  charge_group?.forEach((charge) => {
    fee_charge = [...fee_charge, ...charge.fee_charge];
  });

  return fee_charge;
};

export const ColorStatus = (status) => {
  if (status === "Pending")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "Approved")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  if (status === "Closed" || status === "Rejected") {
    return <div className="bg-gray-300 rounded-full w-9px h-9px"></div>;
  }
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const TwoLineColumn = (context) => {
  const processContext = context.split("|");

  if (processContext.length < 1) return <div></div>
  return <div>
    <div>{processContext[0]}</div>
    {processContext.length > 1 &&
      <Tooltip title={processContext[1]}>
        <div className="text-textscbdark text-label"> {adjustStringLength(processContext[1])} </div>
      </Tooltip>}
  </div>
}

export const ReportColorStatus = (status) => {
  if (_.upperCase(status) === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (_.upperCase(status) === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const UploadColorStatus = (status) => {
  if (status === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "PROCESSED" || status === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};


export const validateFormatDate = (date) => {
  var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

  const myDate = new Date(date);
  const year = myDate.getFullYear();

  if (String(year).length < 4) {
    return true;
  }

  return false;
};

export const formatAction = (action) => {
  return <div>{_.capitalize(action)}</div>;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const tableBorderBottomStyle = (type) => {
  return {
    borderBottom: type === FEE_TABLE_TYPE.Report ? "" : "4px solid white",
  };
};

export const isRefreshTokenNearlyExpired = () => { };

export const saveTokenToLocalStorage = (data) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("expired_at", getExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    getExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    getExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
};

export const getExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const getUserFromStorage = () => {
  let data = { user: null };

  let getUserStorage = sessionStorage.getItem("user");
  if (getUserStorage) {
    data.user = JSON.parse(getUserStorage).user;
  }
  return data.user;
};

export const getTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
    info: parseJwt(access_token),
  };
};

export const saveUserLogin = (isLoggedIn) => {
  if (isLoggedIn) {
    sessionStorage.setItem("isLoggedIn", true);
  } else {
    sessionStorage.removeItem("isLoggedIn");
  }
};

export const checkUserLogin = () => {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  return isLoggedIn != undefined;
};

export const parseJwt = (token) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkCurrentDate = (
  from_date = new Date(),
  to_date = new Date()
) => {
  const today = new Date();
  const formatToday = format(today, "yyyy-MM-dd");
  const formatFromDate = format(from_date, "yyyy-MM-dd");
  return formatToday >= formatFromDate;
};

export const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

export const checkDebitProfileFutureDate = (currentRow = 0, profiles = []) => {
  let currentIndex = 0;

  if (profiles.length > 1) {
    if (currentRow === 0) {
      currentIndex = 1;
    } else if (currentRow === 1) {
      currentIndex = 0;
    }

    const notCurrentProfile = profiles[currentIndex];
    const currentProfile = profiles[currentRow];

    if (
      formatDateApi(new Date(currentProfile.from_date)) >=
      formatDateApi(new Date(notCurrentProfile.from_date)) &&
      formatDateApi(new Date(currentProfile.from_date)) <=
      formatDateApi(new Date(notCurrentProfile.to_date))
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const prePareService = (service) => {
  return {
    ...service,
    charge_group: service.charge_group.map((group) => {
      return {
        ...group,
        fee_charge: group.fee_charge.map((charge) => {
          return {
            ...charge,
            isError: false,
            isRemove: charge.action === "DELETE",
            checkCurrentDate: checkCurrentDate(
              new Date(charge.from_date),
              new Date(charge.to_date)
            ),
          };
        }),
      };
    }),
  };
};

export const prePareOffer = (offer) => {
  return {
    ...offer,
    checkCurrentDate: checkCurrentDate(
      new Date(offer.from_date),
      new Date(offer.to_date)
    ),
  };
};

export const checkErrorDate = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService.charge_group.forEach((group, groupIndex) => {
    group.fee_charge
      .filter(
        (item) => item?.isRemove === false && item.hasOwnProperty("isRemove")
      )
      .forEach((charge, index) => {
        const filter_not_self = group.fee_charge
          .filter((localCharge, f_index) => {
            return (
              localCharge?.isRemove === false &&
              localCharge?.hasOwnProperty("isRemove")
            );
          })
          .filter((item, f_index) => f_index !== index);

        const self_from_date = new Date(charge.from_date);
        const self_to_date = new Date(charge.to_date);

        if (filter_not_self.length > 0) {
          for (let f_i = 0; f_i < filter_not_self.length; f_i++) {
            const not_self = filter_not_self[f_i];
            const not_self_from_date = new Date(not_self.from_date);
            const not_self_to_date = new Date(
              not_self.to_date === "9999-12-31T00:00:00" ||
                not_self.to_date === "" ||
                not_self.to_date === null
                ? "9999-12-31"
                : not_self.to_date
            );

            if (
              !checkDateInvalid(self_from_date) &&
              !checkDateInvalid(not_self_from_date) &&
              !checkDateInvalid(not_self_to_date)
            ) {
              if (
                formatDateApi(self_from_date) >=
                formatDateApi(not_self_from_date) &&
                formatDateApi(self_from_date) <= formatDateApi(not_self_to_date)
              ) {
                checkErrorList = [
                  ...checkErrorList,
                  {
                    index: index,
                    groupIndex,
                    error: true,
                    message: `${String(
                      group.charge_code
                    ).toUpperCase()} ${TEXT_FIELD_ERROR_DATE_FUTURE}`,
                    field_name: "overlap_date",
                    field_value: [],
                    show: true,
                  },
                ];
                return;
              }
            }
          }
        }
      });
  });
  return checkErrorList;
};

export const returnMessageDuplicate = (index, groupIndex, group, priority) => {
  return {
    index: index,
    groupIndex,
    error: true,
    message: `Charge Code ${group.charge_code} priority ${priority} is duplicate`,
    field_name: "priority",
    field_value: priority,
    show: true,
  };
};

export const returnMessageDuplicateQualifer = (
  index,
  groupIndex,
  charge_code,
  qualiferList = []
) => {
  return {
    error: true,
    message: `Charge Code ${charge_code} qualifer ${qualiferList.join(
      ","
    )} is duplicate`,
    field_name: `qualifer`,
    field_value: qualiferList,
    show: true,
  };
};

const toFindDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index);

export const checkDuplicatePriority = (dataService, validate = false) => {
  const currentService = dataService;
  let checkErrorList = [];
  var isError = false
  currentService?.charge_group.forEach((group, groupIndex) => {
    group?.fee_charge?.forEach((charge, index) => {
      const fee_value = charge.fee_value;
      const hasQualifer = fee_value.hasOwnProperty("conditions");
      if (hasQualifer) {
        let tempPriority = [];
        tempPriority = fee_value.conditions.map((condition) => {
          const hasPriority = condition.hasOwnProperty("priority");
          if (hasPriority && condition?.priority !== "") {
            return String(condition.priority);
          }
          return null;
        }, []);

        const uniquePriority = toFindDuplicates(
          tempPriority.filter((item) => item !== null)
        );
        if (validate) {
          for (let i = 0; i < tempPriority.length; i++) {
            if (isNaN(tempPriority[i]) || tempPriority[i] === "" || tempPriority[i] < 1) {
              isError = true
              break
            }
          }
        } else {
          checkErrorList = [
            ...checkErrorList,
            ...uniquePriority.map((item) => {
              return {
                ...returnMessageDuplicate(index, groupIndex, group, item),
              };
            }),
          ];
        }

      }
    });
  });
  if (validate) {
    return isError
  }

  return checkErrorList;
};

export const checkDuplicateQualifer = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService?.charge_group.forEach((group, groupIndex) => {
    group?.fee_charge?.forEach((charge, chargeIndex) => {
      const fee_value = charge.fee_value;
      const hasQualifer = fee_value.hasOwnProperty("conditions");
      if (hasQualifer) {
        fee_value.conditions.forEach((condition, conditionIndex) => {
          const hasQualifer = condition.hasOwnProperty("qualifiers");
          if (hasQualifer) {
            const qualiferKeys = Object.keys(condition.qualifiers);
            const qualiferList = [];
            for (let index = 0; index < qualiferKeys.length; index++) {
              const key = qualiferKeys[index];
              const qualifier = condition.qualifiers[key];
              qualiferList.push(...qualifier);
            }
            checkErrorList = [
              ...checkErrorList,
              {
                isRemove: charge.isRemove || false,
                charge_code: group.charge_code,
                qualiferList: qualiferList,
                groupIndex,
                index: chargeIndex,
                feeChargeIndex: conditionIndex,
              },
            ];
          }
        }, []);
      }
    });
  });

  let existDuplicate = [];
  checkErrorList.forEach((qualifer, qualiferIndex) => {
    for (let index = 0; index < checkErrorList.length; index++) {
      const prevQualifer = checkErrorList[index];

      const checkCharge =
        `${qualifer.index}_${qualifer.groupIndex}` ===
        `${prevQualifer.index}_${prevQualifer.groupIndex}`;

      if (
        prevQualifer.charge_code === qualifer.charge_code &&
        checkCharge &&
        !qualifer.isRemove
      ) {
        let qualiferExist = false;
        if (index !== qualiferIndex) {
          const curr = qualifer.qualiferList;
          const prev = prevQualifer.qualiferList;
          let countMatching = 0;

          const firstRow = curr.length > prev.length ? curr : prev;
          const secondRow = curr.length > prev.length ? prev : curr;

          for (let i = 0; i < firstRow.length; i++) {
            for (let j = 0; j < secondRow.length; j++) {
              if (firstRow[i] === secondRow[j]) {
                countMatching++;
              }
            }
          }

          qualiferExist = firstRow.length === countMatching;

          if (qualiferExist) {
            if (existDuplicate.length > 0) {
              const findDuplicateExistIndex = existDuplicate.findIndex(
                (dup) => dup.charge_code === qualifer.charge_code
              );
              if (findDuplicateExistIndex !== -1) {
                existDuplicate[findDuplicateExistIndex] = qualifer;
              } else {
                existDuplicate.push(qualifer);
              }
            } else {
              existDuplicate.push(qualifer);
            }
          }
        }
      }
    }
  });

  existDuplicate = existDuplicate.map((dup) => {
    return returnMessageDuplicateQualifer(
      dup.groupIndex,
      dup.index,
      dup.charge_code,
      dup.qualiferList
    );
  });

  return existDuplicate;
};

export const getErrorToDateMax = (debitProfile = []) => {
  if (debitProfile.length > 0) {
    const prevTodate = formatDateApi(
      debitProfile[debitProfile.length - 1].to_date,
      "9999-12-31T00:00:00"
    );
    if (
      prevTodate === "9999-12-31T00:00:00" ||
      prevTodate === null ||
      prevTodate === ""
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const mappingUT10012 = (fee_charge) => {
  return fee_charge?.map((feeCharge, index) => {
    let newQualifer = {
      charge_type: feeCharge?.qualifiers?.charge_type,
      channel: feeCharge?.qualifiers?.channel,
      zone: feeCharge?.qualifiers?.zone,
      ...feeCharge.qualifiers,
    };

    return {
      qualifiers: newQualifer,
      ...feeCharge,
      fee_value: {
        ...feeCharge.fee_value,
        conditions: feeCharge?.fee_value?.conditions.map((condition) => {
          let newQualifer = {
            charge_type: condition?.qualifiers?.charge_type,
            channel: condition?.qualifiers?.channel,
            zone: condition?.qualifiers?.zone,
            ...condition.qualifiers,
          };
          condition.qualifiers = newQualifer;

          return condition;
        }),
      },
    };
  });
};


/// Date functiion


const checkMaxTodate = (to_date) => {
  if (
    to_date === "9999-12-31T00:00:00" ||
    to_date === "" ||
    to_date === null
  ) {
    return new Date();
  }

  return new Date(to_date);
};

const getFormateDate = (date) => {
  if (checkDateInvalid(new Date(date))) {
    return date;
  }
  return formatDateApi(date);
};

export const getFromDateFutureRate = (from_date, to_date) => {
  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  let currentFromDate = from_date;
  let currentToDate = to_date;

  if (checkDateInvalid(new Date(to_date))) {
    currentToDate = "";
  }
  if (checkDateInvalid(new Date(from_date))) {
    currentFromDate = "";
  } else if (
    from_date < today &&
    (to_date === "9999-12-31T00:00:00" || to_date === "")
  ) {
    currentToDate = new Date();
    currentToDate = getFormateDate(currentToDate);
  } else if (from_date >= today) {
    if (to_date && !(to_date === "9999-12-31T00:00:00" || to_date === "")) {
      const newToDate = checkMaxTodate(to_date);
      currentToDate = new Date(newToDate);
      currentToDate.setDate(new Date(newToDate).getDate());
      currentToDate = getFormateDate(currentToDate);
    } else {
      currentToDate = new Date(from_date);
      currentToDate.setDate(new Date(from_date).getDate() + 1);
      currentToDate = getFormateDate(currentToDate);
    }
  }

  return { currentFromDate, currentToDate };
};

export const WrapTextEnter = (text) => {
  return text.replace(',', '\n')
}
export const fillZeroIfEmpty = (data = [], haveBeforeAfter = false) => {
  var temp = [...data]

  if (haveBeforeAfter) {
    for (let i = 0; i < data.length; i++) {
      // console.log("here ",data[i].after_charge.fee_charge)

      if (data[i].after_charge.fee_charge) {
        if (data[i].after_charge.fee_charge.fee_value?.conditions?.length > 0) {
          for (let k = 0; k < data[i].after_charge.fee_charge.fee_value.conditions.length; k++) {
            // console.log("fufill")
            if (data[i].after_charge.fee_charge.fee_value?.conditions[k]?.value?.length < 1) {
              temp[i].after_charge.fee_charge.fee_value?.conditions[k].value.push({ fix_amount: 0 })
            }
          }
        }
      }
    }
  }
  else {
    for (let i = 0; i < data.length; i++) {
      if (data[i].fee_charge?.length > 0) {
        for (let j = 0; j < data[i].fee_charge.length; j++) {
          if (data[i].fee_charge[j].fee_value?.conditions?.length > 0) {
            for (let k = 0; k < data[i].fee_charge[j].fee_value.conditions.length; k++) {
              if (data[i].fee_charge[j].fee_value?.conditions[k]?.value?.length < 1) {
                temp[i].fee_charge[j].fee_value?.conditions[k].value.push({ fix_amount: 0 })
              }
            }
          }
        }
      }
    }
  }
  return temp;
}

export const FunctionGetNumberFromString = (input) => {
  try {
    return input.replace(/[^0-9]/g, "");
  } catch (error) {
    console.error("FunctionGetNumberFromString : ", error);
  }
};


export const mappingForServiceInfo = (service_info) => {
  let ret = []
  for (let i = 0; i < service_info.length; i++) {
    ret.push({
      edit: "edit", service_code: service_info[i].service_code,
      account_id: service_info[i].account_id,
      customer_branch_no: service_info[i].customer_branch_no,
      reg_addr_line: service_info[i].reg_addr_line1 + " " + service_info[i].reg_addr_line2 + " " + service_info[i].reg_addr_line3,
      contact_addr_line: service_info[i].contact_addr_line1 + " " + service_info[i].contact_addr_line2 + " " + service_info[i].contact_addr_line3,
      email: service_info[i].email,
      email_alert_flag: service_info[i].email_alert_flag,
    })
  }
  return ret
}


export const checkDateInvalid = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date?.getTime())) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const FunctionCheckDateValid = (input, name = "") => {
  if (input === null && name === "to_date") {
    return { error: false, message: "" };
  } else if (Object.prototype.toString.call(input) === "[object Date]") {
    // it is a date
    if (isNaN(input?.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return { message: "Invalid date format", error: true };
    } else {
      // date is valid
      return { error: false, message: "" };
    }
  }
  return { message: "Invalid date format", error: true };
};

export const FunctionCheckDateLength = (input, name = "") => {
  if (input) {
    if (input?.length === 0 && name === "to_date") {
      return { error: false, message: "" };
    } else if (input?.length === 10) {
      return { error: false, message: "" };
    } else {
      return { message: "Invalid date format", error: true };
    }
  }

  return { error: false, message: "" };
};

export const FunctionCheckDateValidCustom = (input, name = "") => {
  // if (input.length === 19) {
  if (
    (input === null ||
      input === "" ||
      input === "9999-12-31T00:00:00" ||
      input === "9999-12-31") &&
    name === "to_date"
  ) {
    return { error: false, message: "" };
  }
  if (
    name === "from_date" &&
    (input === "" ||
      input === null ||
      input === "undefined-NaN-undefinedT00:00:00")
  ) {
    return { message: "This fied is required", error: true };
  }

  if (Object.prototype.toString.call(new Date(input)) === "[object Date]") {
    if (isNaN(new Date(input)?.getTime())) {
      return { message: "Invalid date format", error: true };
    } else {
      return { error: false, message: "" };
    }
  } else {
    return { message: "Invalid date format", error: true };
  }
};

export const FunctionCheckDateIsNaN = (input) => {
  const errorMsg = "Invalid date format"
  if (input === "NaN-NaN-NaN") {
    return { message: errorMsg, error: true };
  } else if (input === "undefined-NaN-undefined") {
    return { message: errorMsg, error: true };
  } else if (input === "Invalid Date") {
    return { message: errorMsg, error: true };
  } else if (input === null) {
    return { message: errorMsg, error: true };
  } else if (input === "undefined-NaN-undefinedT00:00:00") {
    return { message: errorMsg, error: true };
  } else {
    return { error: false, message: "" };
  }
};

export const checkFromToDate = (from, to, prev, keyName) => {
  if (prev && from && keyName === "from_date") {
    const prev_date = new Date(prev);

    if (prev_date.getTime() >= new Date(from).getTime()) {
      return TEXT_FIELD_DATE_LESS_THAN;
    }
  }

  if (from && keyName === "from_date") {
    const from_date = new Date(from);
    let sliceYear = `${from_date.getFullYear()}`.slice(0, 4);
    if (sliceYear > 2500) {
      return TEXT_FIELD_DATE_INVALID_AD;
    }
  }

  if (
    to &&
    keyName === "to_date" &&
    to !== "9999-12-31" &&
    to !== "" &&
    to !== null &&
    to !== "9999-12-31T00:00:00"
  ) {
    const to_date = new Date(to);
    let sliceYear = `${to_date.getFullYear()}`.slice(0, 4);
    if (sliceYear > 2500) {
      return TEXT_FIELD_DATE_INVALID_AD;
    }
  }

  if (from && to) {
    const from_date = new Date(from);
    const to_date = new Date(to);

    // const diffday = differenceInDays(from_date, prev_date);

    // if ((diffday <= 0 || diffday > 1) && keyName === "from_date") {
    //   return "Date Invalid";
    // }

    // if (to_date === from_date) {
    //   return "Data Invalid";
    // }
    // if (isNaN(to_date?.getTime())) {
    //   return TEXT_FIELD_ERROR_DATE;
    // } else

    // console.log("prev_date", prev_date);

    if (from_date?.getTime() > to_date?.getTime()) {
      if (keyName === "to_date") {
        return TEXT_FIELD_ERROR_DATE_FROM_TO;
      }
    }
    // else if (prev_date > from_date) {
    //   return "Futrue date is not correct";
    // }
    else {
      return "";
    }
  } else {
    return "";
  }
};


export const getValidateError = (
  name,
  value,
  tier = "",
  sync_name,
  sync_data
) => {
  if (name) {
    const objKeys = Object.keys(validateError);
    const objValues = Object.values(validateError);

    const findIndexOfName = objKeys.findIndex((r) => r === name);

    let temp = [];

    if (findIndexOfName !== -1) {
      objValues[findIndexOfName]?.forEach((i) => {
        if (i === "required") {
          temp?.push(checkRequired(value));
        } else if (i === "minus") {
          temp?.push(checkValueNotMinus(value));
        } else if (i.slice(0, 4) === "word" && typeof value === "string") {
          const index_of_word = objValues[findIndexOfName].findIndex(
            (v) => v.slice(0, 4) === "word"
          );
          temp?.push(
            checkWordSync(objValues[findIndexOfName][index_of_word], value)
          );
        } else if (i.slice(0, 3) === "max") {
          const max_value = Number(i.slice(4, i.length));
          temp?.push(checkMaxValue(value, max_value));
        } else if (i.slice(0, 4) === "sync" && sync_name && sync_data) {
          const field_name_required = i.slice(5, i.length);
          temp?.push(
            checkSyncFieldRequired(
              value,
              sync_name,
              sync_data,
              field_name_required
            )
          );
        } else if (i.slice(0, 10) === "lower_than") {
          temp?.push(checkCompareValue(value, sync_data, sync_name));
        } else if (i.slice(0, 7) === "decimal") {
          const length_decimal = i.slice(8, i.length);
          temp?.push(FunctionCheckDecimal(value, length_decimal));
        } else {
          return "";
        }
      });

      const error_message = temp?.filter((er) => er !== "");

      if (error_message[0] === ERROR_MESSAGE_REQUIRED) {
        return {
          error: true,
          message: ERROR_MESSAGE_REQUIRED,
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else if (error_message.length === 1) {
        return {
          error: true,
          message: String(error_message),
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else if (error_message.length > 1) {
        return {
          error: true,
          message: String(error_message).replaceAll(",", " "),
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else {
        return {
          error: false,
          message: "",
          field_name: name,
          field_value: value,
          tier: tier,
        };
      }
    }
  } else {
    return { error: false, message: "" };
  }
};

export const checkFormToValue = (form_value, to_prev_value) => {
  const real_form_value =
    Number(form_value) >= 0
      ? Number(form_value)
      : form_value === "MIN"
        ? 0
        : form_value;
  const real_to_prev_value =
    Number(to_prev_value) >= 0
      ? Number(to_prev_value)
      : to_prev_value === "MAX"
        ? 999999999.99
        : to_prev_value;

  if (real_form_value >= 0 && real_to_prev_value >= 0) {
    if (real_form_value <= real_to_prev_value) {
      // return to_prev_value + " value cannot be greater than " + real_form_value;
      return "from value should be higher than to value from last step";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const FunctionCheckDecimal = (input, length_decimal = 2) => {
  const number_value = Number(input);
  const decimal_length = number_value?.toString()?.split(".")[1]?.length;

  if (input === "MIN") {
    return "";
  } else if (input === "MAX") {
    return "";
  } else if (Math.floor(number_value?.valueOf()) === number_value?.valueOf()) {
    return "";
  } else if (decimal_length > length_decimal) {
    return TEXT_FIELD_ERROR_DECIMAL;
  } else {
    return "";
  }
};

export const validateError = {
  from: [
    "required",
    "word:MIN",
    "minus",
    "lower_than:to",
    "max:999999999.99",
    "decimal:2",
  ],
  to: ["required", "word:MAX", "minus", "max:999999999.99", "decimal:2"],
  tier: ["required"],
  rate: ["minus", "sync:rate_type"],
  priority: ["minus"],
  min: ["minus", "max:999999999.99", "lower_than:max"],
  max: ["minus", "max:999999999.99"],
  fix_amount: ["minus", "max:999999999.99"],
  min_tier: ["minus", "max:999999999.99", "lower_than:max_tier"],
  max_tier: ["minus", "max:999999999.99"],
};


export const checkRequired = (input) => {
  if (input === 0) {
    return "";
  } else if (input === "") {
    return ERROR_MESSAGE_REQUIRED;
  } else if (input === null) {
    return ERROR_MESSAGE_REQUIRED;
  } else if (input === undefined) {
    return ERROR_MESSAGE_REQUIRED;
  } else if (input) {
    return "";
  } else {
    return ERROR_MESSAGE_REQUIRED;
  }
};


export const checkValueNotMinus = (value) => {
  const real_value = Number(value) >= 0 ? Number(value) : value;
  if (value === `-0`) {
    return ERROR_MESSAGE_MINUS;
  } else if (isNaN(real_value)) {
    return "";
  } else if (real_value >= 0) {
    return "";
  } else {
    return ERROR_MESSAGE_MINUS;
  }
};

export const checkWordSync = (word, value) => {
  const react_value = Number(value) >= 0 ? 0 : value;
  if (typeof react_value === "string") {
    const keyword = word.slice(5, word.length).split(",") || [];
    let temp_value = 0;
    keyword.forEach((it) => {
      if (it === value) {
        temp_value++;
      } else {
        return "";
      }
    });
    if (temp_value > 0) {
      return "";
    } else {
      return String(word.slice(5, word.length));
    }
  } else {
    return "";
  }
};

export const checkMaxValue = (value, max) => {
  const real_value = Number(value) >= 0 ? Number(value) : value;

  if (real_value > max) {
    return "Value cannot be greater than " + max;
  } else {
    return "";
  }
};

export const checkSyncFieldRequired = (
  value,
  sync_name,
  sync_data,
  field_name_required
) => {
  if (sync_name === field_name_required) {
    if (!value && sync_data !== "-" && value !== 0) {
      return ERROR_MESSAGE_REQUIRED;
    } else if (value === "" && sync_data !== "-") {
      return ERROR_MESSAGE_REQUIRED;
    } else {
      return "";
    }
  }
  return "";
};

export const checkCompareValue = (front, back, sync_name) => {
  const front_real_value =
    Number(front) >= 0 ? Number(front) : front === "MIN" ? 0 : front;
  const back_real_value =
    Number(back) >= 0 ? Number(back) : back === "MAX" ? 999999999.99 : back;

  if (front_real_value && back === "" && sync_name === "max") {
    return "";
  } else if (front_real_value && back === "" && sync_name === "max_tier") {
    return "";
  } else if (back_real_value < front_real_value) {
    return "Value cannot be greater than " + mappingKeyForRender[sync_name];
  } else {
    return "";
  }
};



export const TEXT_FIELD_ERROR_DATE =
  "Effective From  Must Be Before Effective To.";
export const TEXT_FIELD_DATE_INVALID = "Invalid Date Format.";
export const TEXT_FIELD_ERROR_DATE_FROM_TO =
  "Effective To Cannot Be Before Effective From.";

export const TEXT_FIELD_ERROR_DATE_NEXT_STEP =
  "Effective to date must be before effective from next step.";

export const TEXT_FIELD_ERROR_DATE_FUTURE =
  "Effective From Future Must Be After Effective To Date From Last Step.";

export const TEXT_FIELD_ERROR_DUPLICATED = "Data cannot be duplicated.";

export const TEXT_FIELD_ERROR_DATA_NOT_FOUND = "Data not found.";
export const TESXT_FIELD_ERROR_NAME_ALREADY_EXIST = "Name already exist.";

export const TEXT_FIELD_DATE_LESS_THAN =
  "From date cannot be less than previous To date.";

export const TEXT_FIELD_ERROR_DECIMAL =
  "Value decimal must not be more than 2 digits.";

export const ERROR_MESSAGE_MINUS = "Value cannot be less than 0.";

export const mappingKeyForRender = {
  max: "Max Charge",
  min: "Min Charge",
  min_tier: "Tier Min",
  max_tier: "Tier Max",
  from: "From",
  to: "To",
};


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}