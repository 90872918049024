export const TEXT_PARAMETER = "PARAMETER";
export const ACCOUNT_GROUP_MANAGEMENT = "ACCOUNT GROUP MANAGEMENT";
export const CAPITAL_BASE = "CAPITAL BASE";
export const BOUND_DETAIL = "BOND DETAIL";
export const LOAN_DETAIL = "LOAN DETAIL";
export const NOSTRO_DETAIL = "NOSTRO DETAIL";

export const ORIGINAL_CURR = ["AED", "AUD", "BHD", "BND", "BRL", "CAD", "CHF", "CNH", "CNO", "CNY", "DKK", "EUR", "FIM", "GBP", "GHS", "HKD", "IDR",
  "INR", "JPY", "KHR", "KRW", "KWD", "LAK", "MMK", "MXN", "MYR", "NGN", "NOK", "NZD", "OMR", "PHP", "PKR", "PLN", "QAR", "RUB", "SAR", "SEK", "SGD", "THB", "TRY", "TWD", "USD", "VND", "ZAR"];
export const COUNTRY_CODE = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW"];
export const COUNTRY_CODE_NONE = ["NONE", ...COUNTRY_CODE];
export const YES_NO_NONE = ["NONE", "YES", "NO"]
export const FIX_VAR_NONE = ["NONE", "Fix", "Var"]
export const GENERAL_SPECIFIC = ["NONE", "General", "Specific"]

export const MAP_YES_NO = {
  "Y": "YES",
  "N": "NO",
  ...["", null, undefined].reduce((acc, item) => {
    acc[item] = "NONE";
    return acc;
  }, {}),
}
export const MAP_YES_NO_REVERSE = {
  "NONE": "",
  "YES": "Y",
  "NO": "N",
}

export const ERROR_CODE = 400;
export const SUCCESS_CODE = 200;
export const ROW_PER_PAGE = 15;
export const FEE_TABLE_TYPE = { Report: 0, Processing: 1, Fee: 2 };
export const STEP = "STEP";
export const MIN_VALUE = 0;
export const MAX_VALUE = 999999999.99;
export const MINIMUM_SEARCH_ACTIVE = 3;
export const MORE_THAN_SEARCH_ACTIVE = 2;
export const TEN_MINUTES = 600000;
///Name
export const OFFER_TITLE = "OFFER";
export const TAX_SCHEME_TITLE = "TAX SCHEME";
export const CUSTOMER_TITLE = "CUSTOMER";
export const SERVICE_TITLE = "SERVICE";
export const RETAIL_TITLE = "RETAIL";

export const PRICING_WITHOUT_DEBIT = "Pricing Without Debit";

export const ModalType = { Role: "ROLE", Group: "Group" };

// Menu Group Name
export const ADMIN = "Admin";
export const DESIGN = "Design";
export const CUSTOMER_INFO = "Customer";
export const REPORT = "Report";
export const DATA_UPLOAD = "Data upload";
export const SETTINGS = "Settings";

/// Path name
export const MAKER_CHECKER_REQUEST_PATH_NAME = "MY REQUEST";
export const MAKER_CHECKER_WAITING_PATH_NAME = "WAITING FOR APPROVAL";
export const PROFILE_PATH_NAME = "USER PROFILE";
export const PROFILE_PATH_USER_GROUP_NAME = "USER GROUP";
export const PATH_USER_GROUP_CREATE = "USER_GROUP_CREATE";
export const NEW_PROFILE_PATH_NAME = "NEW USER PROFILE";
export const GROUP_PATH_NAME = "USER GROUP";
export const TAX_PATH_NAME = "Tax scheme";
export const SERVICE_PATH_NAME = "Service";
export const OFFER_PATH_NAME = "Offer";
export const RETAIL_PATH_NAME = "Retail";
export const CUSTOMER_PATH_NAME = "CUSTOMER";
export const ACCOUNT_PATH_NAME = "ACCOUNT";
export const REPORT_PRICE_PATH_NAME = "PRICED TRANSACTION";
export const REPORT_FEE_PATH_NAME = "FEE DEBIT";
export const REPORT_PRICE_TXN_SUMMARY_PATH_NAME = "PRICED TXN SUMMARY";
export const REPORT_FEE_TRANSACTION_PATH_NAME = "FEE DEBIT TRANSACTION";
export const EMAIL_ALERT_REPORT_PATH_NAME = "EMAIL ALERT";
export const OVERDUE_REPORT_PATH_NAME = "OVERDUE";
export const CUSTOMER_FEE_PROFILE_REPORT = "ACCOUNT STATUS";
export const UPLOAD_PATH_NAME = "UPLOAD";
export const UPLOAD_BREADCOMB = "FILE UPLOAD";
export const SETTINGS_PATH_NAME = "SYSTEM SETTINGS";
export const BANK_BRANCH_PATH_NAME = "BANK BRANCH";
export const TEXT_REFERCH = "Refresh";

/// Route Name
export const MAKER_CHECKER_REQUEST_ROUTE = "/myrequest";
export const MAKER_CHECKER_WAITING_ROUTE = "/waitingforapprove";
export const PROFILE_ROUTE = "/userprofile";
export const GROUP_ROUTE = "/usergroup";
export const TAX_ROUTE = "/taxScheme";
export const SERVICE_ROUTE = "/service";
export const OFFER_ROUTE = "/offer";
export const RETAIL_ROUTE = "/retail";
export const CUSTOMER_ROUTE = "/customer";
export const ACCOUNT_ROUTE = "/account";
export const REPORT_PRICE_ROUTE = "/pricedTransaction";
export const REPORT_FEE_ROUTE = "/feeDebit";
export const REPORT_FEE_TRANSACTION_ROUTE = "/feeDebitTransaction";
export const REPORT_PRICE_TXN_SUMMARY_ROUTE = "/pricedTxnSummary";
export const REPORT_EMAIL_ALERT = "/emailAlert";
export const REPORT_OVERDUE = "/overdue";
export const REPORT_CUSTOMER_FEE_PROFILE = "/customerFeeProfile";
export const GENERATE_FEE_REPORT_ROUTE = "/feeDebit/generateReport";
export const GENERATE_PRICE_REPORT_ROUTE = "/pricedTransaction/generateReport";
export const GENERATE_PRICE_TXN_SUMMARY_REPORT_ROUTE = "/pricedTxnSummary/generateReport";
export const GENERATE_REPORT_EMAIL_ALERT = "/emailAlert/generateReport";
export const GENERATE_REPORT_OVERDUE = "/overdue/generateReport";
export const GENERATE_REPORT_CUSTOMER_FEE_PROFILE = "/customerFeeProfile/generateReport";

export const BANK_BRANCH_ROUTE = "/bankbrach";
export const UPLOAD_ROUTE = "/upload";
export const SETTINGS_ROUTE = "/settings";

export const ACCOUNT_STATUS_BG_ERROR = "bg-error";

export const Path = {
  SERVICE: "service",
  "TAX SCHEME": "taxScheme",
  OFFER: "offer",
  RETAIL: "retail",
  "Bill Payment": "service",
};

export const TIER_TYPE_FORMAT = {
  STEP: "Step",
  SLAP: "Slap",
  SLAB: "Slab",
  NO: "No",
};

export const OPTIONS_PRICING_METHOD = [
  {
    id: "TRAN_VALUE",
    value: "Transaction Value",
  },
  {
    id: "SUBSCRIPTION",
    value: "Subscription",
  },
];

export const OPTIONS_TEMPLATE = [
  { value: "", label: "All Template" },
  { value: "TRAN", label: "Transaction File" },
  { value: "PRICELIST", label: "Customer Account Pricelist File" },
  { value: "BUNDLE", label: "Customer Account Bundle File" },
];

export const OPTIONS_STATUS_UPLOAD = [
  { label: "All Status", value: "" },
  {
    label: "Processing",
    value: "PROCESSING",
  },
  {
    label: "Failed",
    value: "FAILED",
  },
  {
    label: "Processed",
    value: "PROCESSED",
  },
];

export const OPTIONS_STATUS_REPORT = [
  { label: "All Status", value: "" },
  {
    label: "Processing",
    value: "PROCESSING",
  },
  {
    label: "Failed",
    value: "FAILED",
  },
  {
    label: "Success",
    value: "SUCCESS",
  },
];

export const OPTIONS_STATUS = [
  { label: "All Status", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "Rejected", value: "Rejected" },
  { label: "Returned", value: "Returned" },
  { label: "Renewed", value: "Renewed" },
  { label: "Approved", value: "Approved" },
  { label: "Closed", value: "Closed" },
];

export const TEXT_FIELD_DATE_INVALID_AD = "Please fill in the year A.D.";

export const TEXT_FIELD_DATE_INVALID_PAST = "Past date is not allowed.";

export const ALL_REQUEST_TYPE = "All Request Type";

export const NEW_CUSTOMER_ACCOUNT_PRICELIST =
  "New Customer / Account Pricelist";

export const NEW_CUSTOMER_ACCOUNT_OFFER = "New Customer / Account Offer";

export const UPDATE_CUSTOMER_ACCOUNT_PRICELIST =
  "Update Customer / Account Pricelist";

export const UPDATE_CUSTOMER_ACCOUNT_OFFER = "Update Customer / Account Offer";

export const TRANSACTION_UPLOAD_LABEL = "Transaction Upload";

export const NEW_SERVICE = "NEWSERVICE";
export const NEW_OFFER = "NEWOFFER";
export const UPDATE_SERVICE = "UPDATESERVICE";
export const UPDATE_OFFER = "UPDATEOFFER";

export const TRANSACTION_UPLOAD = "TRANSACTION";

export const OPTIONS_ACCOUNT_STATUS = [
  { label: "All Status", value: "All Status" },
  { label: "Normal", value: "Normal" },
  { label: "Closed", value: "Closed" },
  { label: "Dormant", value: "Dormant" },
  { label: "Other", value: "Other" },
];

export const OPTIONS_REQUEST_TYPE = [
  { label: ALL_REQUEST_TYPE, value: "" },
  { label: NEW_CUSTOMER_ACCOUNT_PRICELIST, value: NEW_SERVICE },
  { label: NEW_CUSTOMER_ACCOUNT_OFFER, value: NEW_OFFER },
  { label: UPDATE_CUSTOMER_ACCOUNT_PRICELIST, value: UPDATE_SERVICE },
  { label: UPDATE_CUSTOMER_ACCOUNT_OFFER, value: UPDATE_OFFER },
  { label: TRANSACTION_UPLOAD_LABEL, value: TRANSACTION_UPLOAD },
];

// Table Header
export const MY_REQUEST_TABLE_HEADER = [
  {
    id: "color",
    label: "",
  },
  {
    id: "transaction_reference",
    label: "Request ID",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "request_type",
    label: "Request Type",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "maker",
    label: "Maker",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "reject_reason",
    label: "Reject Reason",
  },
];

export const UPLOAD_TABLE_HEADER = [
  {
    id: "color",
    label: "",
  },
  {
    id: "id",
    label: "ID",
  },
  {
    id: "create_date",
    label: "Created Date",
  },
  {
    id: "file_name",
    label: "File Name",
  },
  {
    id: "template",
    label: "Template",
  },
  {
    id: "maker",
    label: "Upload By",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "error",
    label: "Error",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "total",
    label: "Total",
  },
];

export const BANK_BRACH_HEADER = [
  {
    id: "bank_code",
    label: "Bank Code",
  },
  {
    id: "branch_code",
    label: "Branch Code",
  },
  {
    id: "branch_name",
    label: "Branch Name",
  },
  {
    id: "region_code",
    label: "Region Code",
  },
];

export const FEE_REPORT_TABLE_HEADER = [
  {
    id: "color",
    label: "",
  },
  {
    id: "request_id",
    label: "Request ID",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "request_by",
    label: "Request By",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "remark",
    label: "",
  },
  {
    id: "download",
    label: "",
  },
];

export const PRICED_REPORT_TABLE_HEADER = [
  {
    id: "colorStatus",
    label: "",
  },
  {
    id: "request_id",
    label: "Request ID",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "date_range",
    label: "Date Range",
  },
  {
    id: "criteria",
    label: "Criteria",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "download",
    label: "",
  },
];

export const WAITING_TABLE_HEADER_LANDING = [
  {
    id: "request_id",
    label: "Request ID",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "request_type",
    label: "Request Type",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "maker",
    label: "Maker",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "reject_reason",
    label: "Reject Reason",
  },
];

export const HISTORY_HEADER = [
  {
    id: "action_date",
    label: "Date",
  },
  {
    id: "action",
    label: "Action",
  },
  {
    id: "action_by",
    label: "Action By",
  },
  {
    id: "reason",
    label: "Reason",
  },
];

export const WAITING_TABLE_HEADER = [
  {
    id: "transaction_reference",
    label: "Request ID",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "request_type",
    label: "Request Type",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "maker",
    label: "Maker",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "reject_reason",
    label: "Reject Reason",
  },
];

export const OFFER_TABLE_HEADER = [
  {
    id: "offer_name",
    label: "Name",
  },
  {
    id: "offer_code",
    label: "Code",
  },
  {
    id: "from_date",
    label: "From",
  },
  {
    id: "to_date",
    label: "To",
  },
];

export const SERVICE_TABLE_HEADER = [
  {
    id: "service_name",
    label: "Name",
  },
  {
    id: "service_code",
    label: "Code",
  },
  {
    id: "from_date",
    label: "From",
  },
  {
    id: "to_date",
    label: "To",
  },
];

export const ACCOUNT_TITLE = "ACCOUNT";

export const ADD_SERVICE_TABLE_HEADER = [
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "service_code",
    label: "Service Code",
  },
];

export const ADD_SERVICE_CHECKBOX_TABLE_HEADER = [
  {
    id: "checked",
    label: "",
  },
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "service_code",
    label: "Service Code",
  },
];

export const SERVICE_ADDRESS_TABLE_HEADER = [
  {
    id: "edit",
    label: "",
  },
  {
    id: "service_code",
    label: "Service",
  },
  {
    id: "account_id",
    label: "Account No.",
  },
  {
    id: "customer_branch_no",
    label: "Tax Branch No.",
  },
  {
    id: "reg_addr_line",
    label: "Registered Address",
  },
  {
    id: "contact_addr_line",
    label: "Contact Address",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "email_alert_flag",
    label: "Alert",
  },
];

export const UPLOAD_MAKER_CHECKER_HEADER = [
  {
    id: "txn_id",
    label: "TXN_ID",
  },
  {
    id: "txn_date",
    label: "TXN_DATE",
  },
  {
    id: "cust_id",
    label: "CUST_ID",
  },
  {
    id: "acct_no",
    label: "ACCT_NO / BILLER_ID",
  },
  {
    id: "txn_code",
    label: "TXN_CODE",
  },
  {
    id: "txn_volume",
    label: "TXN_VOLUME",
  },
];

export const ADD_OFFER_TABLE_HEADER = [
  {
    id: "offer_name",
    label: "Offer Name",
  },
  {
    id: "offer_code",
    label: "Offer Code",
  },
];

export const HEADER_TABEL_CUSTOMER = [
  {
    id: "customer_name",
    label: "Name",
  },
  {
    id: "customer_id",
    label: "ID",
  },
];
export const HEADER_TABEL_ACCOUNT = [
  {
    id: "account_name",
    label: "Name",
  },
  {
    id: "account_id",
    label: "Account No.",
  },
];
export const HEAD_ACCOUNT_CELLS = [
  {
    id: "account_name",
    label: "Account Name",
  },
  {
    id: "account_id",
    label: "Account No.",
  },
];

export const QUALIFIER_TYPE = {
  charge_type: "Charge Type",
  zone: "Zone",
  channel: "Channel",
  tran_type: "Charge Type",
};

export const PRICING_METHOD = {
  TRAN_VALUE: "Transaction Value",
  SUBSCRIPTION: "Subscription",
  TRAN_VOLUME: "Transaction Volume",
  TRAN_QUALIFIER: "Transaction Qualifier",
};

export const PRICING_METHOD_FOR_DROPDOWN = {
  TRAN_VALUE: "Transaction Value",
  TRAN_VOLUME: "Transaction Volume",
};

export const PRICING_METHOD_FOR_SUBSCRIPTION = {
  SUBSCRIPTION: "Subscription",
};

export const TYPE = {
  STRING: 0,
  NUMBER: 1,
  DATE: 2,
  PERCENT: 3,
};

export const VAT_TYPE = {
  INCLUDE: "Include",
  EXCLUDE: "Exclude",
  NO: "No",
};

export const RATE_TYPE = {
  PERCENT: "%",
  THB: "THB",
};

export const RATE_TYPE_VALUE = {
  "%": "PERCENT",
  THB: "THB",
};

export const WHT_TYPE = {
  N: "No",
  Y: "Yes",
};

export const MAPPING_ALL_TYPE = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  TRANSACTION: "Per transaction",
};

export const PRICING_FREQUENCY = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export const PRICING_FREQUENCY_FOR_SUBSCRIPTION = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export const PRICING_FREQUENCY_FOR_TRAN_VALUE = {
  DAILY: "Daily",
  MONTHLY: "Monthly",
};

export const TRANSACTION_CONSOLIDATION_FOR_DAILY = {
  TRANSACTION: "Per transaction",
};

export const TRANSACTION_CONSOLIDATION_FOR_MONTHLY = {
  MONTHLY: "Monthly",
};

export const TRANSACTION_CONSOLIDATION = {
  MONTHLY: "Monthly",
  TRANSACTION: "Per Transaction",
  NO: "No",
};

export const POSTING_TYPE = {
  DEBIT: "Debit",
  CREDIT: "Credit",
};

export const POSTING_FREQUENCY = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export const POSTING_CONSOLIDATION = {
  SERVICE: "Service",
  NO: "No",
  CUSTOMER: "Customer",
  CHARGE: "Charge",
};

export const TAX_HEAD_CELLS = [
  {
    id: "tax_rate",
    label: "Rate",
  },
  {
    id: "effective_date",
    label: "Effective",
  },
  {
    id: "end_date",
    label: "End",
  },
];

export const USER_HEAD_CELL = [
  {
    id: "user_name",
    label: "Staff Name",
  },
  {
    id: "user_id",
    label: "Staff ID",
  },
  {
    id: "user_role",
    label: "Roles",
  },
];

export const SERVICE_HEAD_CELL = [
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "service_code",
    label: "Code",
  },
];

export const HISTORY_HEAD_CELLS = [
  {
    id: "aprrove_date",
    label: "Approve Date",
  },
  {
    id: "checker",
    label: "Checker Name",
  },
  {
    id: "request_type",
    label: "Request Type",
  },
  {
    id: "maker",
    label: "Maker",
  },
  {
    id: "request_date",
    label: "Request Date",
  },
  {
    id: "remark",
    label: "Remark",
  },
];

export const TAXSCHME_HISTORY_HEAD_CELLS = [
  {
    id: "create_date",
    label: "Create Date",
  },
  {
    id: "request_name",
    label: "Request Name",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "remark",
    label: "Remark",
  },
];


export const ACCOUNT_HEAD_CELLS = [
  {
    id: "posting_account_id",
    label: "Account No.",
  },
  {
    id: "posting_account_name",
    label: "Account Name",
  },
  {
    id: "status",
    label: "Account Status",
  },
  {
    id: "effective_date",
    label: "From",
  },
  {
    id: "end_date",
    label: "To",
  },
  {
    id: "delete",
    label: "",
  },
];

export const STEP_OPTIONS = [
  { label: "Step", id: "Step" },
  { label: "Slab", id: "Slab" },
];

export const EDIT_COLUMN_WITHOUT_QUALIFIERS = 8;
export const NULL_DATE = "9999-12-31T00:00:00";

export const MAPPING_GENDER = {
  M: "Male",
  F: "Female",
};

export const MAPPING_CUSTOMER_TYPE = {
  C: "Corporate",
  P: "Personal",
};

export const MAPPING_PAYROLL_INDICATOR = {
  P: "Payroll",
  N: "Non Payroll",
};

export const MAPPING_CLOSURE_TYPE = {
  T: "Temporary",
  P: "Permanent",
};

export const MAPPING_CARD_TYPE = {
  P: "Primary",
  S: "Supplementary",
};

export const USER_ROLE = {
  SecurityAdmin: "Security Admin",
  SystemAdmin: "System Admin",
  UserGroup: "User Group",
};

export const USER_ROLE_ADMIN_OPTIONS = [
  { value: "SYSTEMADMIN", label: "System Admin" },
  { value: "SECURITYADMIN", label: "Security Admin" },
];

export const USER_ROLE_OPTIONS = [
  { value: "REPORTVIEWER", label: "Report Viewer" },
  { value: "MAKER", label: "Maker" },
  { value: "CSVIEWER", label: "CS Viewer" },
  { value: "VIEWER", label: "Viewer" },
  { value: "CHECKER", label: "Checker" },
];

export const USER_ROLE_MAPPING = {
  SYSTEMADMIN: "System Admin",
  SECURITYADMIN: "Security Admin",
  REPORTVIEWER: "Report Viewer",
  MAKER: "Maker",
  CSVIEWER: "CS Viewer",
  VIEWER: "Viewer",
  CHECKER: "Checker",
};


export const END_OF_TIME = "9999-12-31T00:00:00"

export const VALIDATE_NUMBER_INPUT = "This field must be number."
export const ERROR_MESSAGE_REQUIRED = "This field is required.";
