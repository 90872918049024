import React, { useContext, useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootContext } from "../../context/root.context";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import { BORDER_COLOR } from "../../constrant/style.constrant";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { ACCOUNT_GROUP_MANAGEMENT, BOUND_DETAIL, CAPITAL_BASE } from "../../constrant/text.constrant";
import { Button } from "@mui/material";

function Appbar({ routeName }) {
  const { openDrawer,setShowModal } = useContext(RootContext);
  const { user } = useSelector ((state) => state.auth);

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        borderBottom: 1,
        borderColor: "#DDD",
        paddingLeft: openDrawer ? 24 : 4,
        // ...(!openDrawer && { width: "calc(100% - 42px)" }),
      }}
      position="fixed"
      open={openDrawer}
    >
      <Toolbar
        sx={{
          backgroundColor: "white",
        }}
      >
        <div className="w-full flex justify-between items-center">
          <Breadcrumbs />
          <ActionAppbar
            user={user}
            routeName={routeName}
            setShowModal={setShowModal}
          //  setAction={setAction}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}

const ActionAppbar = ({user,routeName,setShowModal}) => {
  //console.log("route name ", routeName)
  if ( user?.user_role !== "REPORT" && (routeName && routeName.length > 1 && (routeName[1]?.title === CAPITAL_BASE ||routeName[1]?.title === BOUND_DETAIL))) {
    return (
      <Button
        variant="contained"
        sx={{ fontSize: "14px" }}
        onClick={() =>setShowModal(true)}
      >
        ADD DATA
      </Button>
    );
  }
  if (user?.user_role !== "REPORT" && (routeName && routeName.length > 1 && routeName[1]?.title ===  ACCOUNT_GROUP_MANAGEMENT)) {
    return (
      <Button
        variant="contained"
        sx={{ fontSize: "14px" }}
        onClick={() =>setShowModal(true)}
      >
        ADD A/C GROUP
      </Button>
    );
  }

}

export default Appbar;
