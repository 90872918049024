import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { saveUserLogin } from "../../utils/util.main";
import Loading from "../../components/loading/loadingPage";
import { getTokenThunk, getUserProfileThunk } from "../../redux/authSlice";
import { RootContext } from "../../context/root.context";

function AuthCallbackMain({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setActionAppbar } = useContext(RootContext);

  useEffect(() => {
    const search = location.search;
    saveUserLogin(true);

    if (search) {
      const { code, session_state } = queryString.parse(search);
      if (!code || !session_state) {
        return;
      }
      fetchToken({ code, session_state });
    }
    setActionAppbar("");
  }, [dispatch, location, navigate]);

  const fetchToken = async ({ code, session_state }) => {
    await dispatch(getTokenThunk({ code, session_state })).unwrap();
    await dispatch(getUserProfileThunk()).unwrap();
    navigate("/");
  };

  return (
    <div className="h-screen flex items-center justify-center w-full ">
      <Loading />
    </div>
  );
}

export default AuthCallbackMain;
