import { LocalizationProvider } from "@mui/lab";
import React, { useState, createContext } from "react";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export const RootContext = createContext({});

function RootContextProvider({ children }) {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [actionAppbar, setActionAppbar] = useState("");
  const [routeName, setRouteName] = useState([]);
  const [showModal,setShowModal] = useState(false)

  return (
    <RootContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        routeName,
        setRouteName,
        actionAppbar,
        setActionAppbar,
        showModal,
        setShowModal
      }}
    >
        {children}
    </RootContext.Provider>
  );
}

export default RootContextProvider;
