import api from "./api.main";
import { apiConfig } from "../config/config.main";

const nostroInquiry = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.nostroInquiry}${query}`, {
    params,
  });
};

const nostroRegen = async (params) => {
  return api.instance.post(`${apiConfig.nostroRegen}`, params)
}



const nostroUpdate = async (isLastest, gl_acct, params) => {
  if (isLastest) return api.instance.put(`${apiConfig.nostroLatest}/${gl_acct}`,
    params,
  );
  return api.instance.put(`${apiConfig.nostroInquiry}/${gl_acct}`,
    params,
  );
};

const nostroLatest = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.nostroLatest}${query}`, {
    params,
  });
}

const nostroDownload = async (date) => {
  // /v1/parameter/bond/download?report_date=yyyy-mm-dd

  // return api.instance.get(`${apiConfig.`);
  return api.instance.get(`/v1/parameter/nostro/download?report_date=${date}`)

}

const nostroLatestDownload = async (showAll) => {
  return api.instance.get(`/v1/parameter/nostrolatest/download?showall=${showAll}`);
}


const exportedObject = {
  nostroInquiry,
  nostroUpdate,
  nostroLatest,
  nostroRegen,
  nostroDownload,
  nostroLatestDownload
};

export default exportedObject;
