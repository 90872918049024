import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import HKDrawer from "../drawer/drawer";
import Appbar from "../appbar/appbar";
import { RootContext } from "../../context/root.context";
import { useLocation } from "react-router-dom";
import { version } from "../../config/config.main";

function Layout({ }) {

  const { routeName } = useContext(RootContext);

  const location = useLocation()

  if (location.pathname === "/login") {
    return null
  }

  return (
    <>
      <CssBaseline />
      <HKDrawer  version={version}/>
      <Appbar routeName={routeName}/>
    </>
  );
}

export default Layout;
