import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RootContext } from "../../context/root.context";

const Breadcrumb = ({ titles, ...props }) => {
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const { setRouteName, routeName } = useContext(RootContext);
  //   const dispatch = useDispatch();

  useEffect(() => {
    let temp = [];


    const onClickNavigate = async (path) => {
      setRouteName([]);
      navigate(path);
    };
    temp.push(
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => onClickNavigate("/")}
      >
        <IconButton>
          <HomeIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Link>
    );
    if (routeName?.length > 0) {
      for (let i = 0; i < routeName.length - 1; i++) {
        temp.push(
          <Link
            underline="hover"
            key={i + 2}
            color="inherit"
             onClick={() => {if(titles) onClickNavigate(titles[i]?.path)}}
          >
            <div className="text-medium-14px text-textgray cursor-pointer">
              {routeName[i].title}
            </div>
          </Link>
        );
      }

      temp.push(
        //   <Typography key={titles.length + 1} color="text.primary">
        <div className=" text-textprimary " key={routeName.length + 1}>
          {routeName[routeName.length - 1]?.title}
        </div>
        //    </Typography>
      );
    }
    setBreadcrumbs(temp);
  }, [titles, navigate, setRouteName, routeName]);

  return (
    <Breadcrumbs
      {...props}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
