import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshTokenThunk } from "../../redux/authSlice";
import { saveTokenToLocalStorage } from "../../utils/util.main";
import authService from "../../api/authService"

const TimerRefreshToken = ({ stopRefreshToken = false }) => {
  const [checkTime, setCheckTime] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stopRefreshToken) return;
    let timer = setTimeout(async () => {
      try {
        setCheckTime(!checkTime);
        const now = Date.now();
        const plan_refresh_token_expired_at = sessionStorage.getItem(
          "plan_refresh_token_expired_at"
        );
        const actual_refresh_token_expired_at =
          sessionStorage.getItem("refresh_expired_at");
        if (
          now >= parseInt(plan_refresh_token_expired_at) &&
          now < actual_refresh_token_expired_at
        ) {
          const refresh_token = sessionStorage.getItem("refresh_token");
          const { data: responseData } = await authService.refreshToken(
            refresh_token
          );
          if (responseData?.data) {
            saveTokenToLocalStorage(responseData?.data);
          }
        }
      } catch (error) {
        sessionStorage.clear();
        window.open("/login", "_self");
      }
    }, 1000 * 30);

    return () => {
      clearTimeout(timer);
    };
  }, [checkTime, dispatch, stopRefreshToken]);

  return null;
};

export default TimerRefreshToken;