import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ROUTE_CAPITALBASE, ROUTE_REPORT } from "../constrant/route.constrant";
import CapitalBase from "../pages/capitalbase";
import ReportMain from "../pages/report/report.main";
import ProtectedRoute from "./protectedRoute";

function CapitalBaseRoute() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Routes>
      <Route path={ROUTE_CAPITALBASE} element={<ProtectedRoute can_see={user?.user_role === "MANAGEMENT"||user?.user_role === "REPORT"}><CapitalBase />  </ProtectedRoute>} />
    </Routes>
  );
}

export default CapitalBaseRoute;
