import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Skeleton from "react-loading-skeleton";
import { Select, TextField } from "@mui/material";
import { CommonDropdown, RMDropdown } from "../Input/commonDropdown";
import Invalid from "../error/invalidData";
import accountBaseAPI from "../../api/accountBaseAPI";
import { LoadingButton } from "@mui/lab";
import "react-loading-skeleton/dist/skeleton.css";
import { FooterButton } from "../button/footerButton";
import { Content } from "../text/content";
import removeIcon from "../../assets/icon/remove.svg";
import YesNoRadio from "../Input/yesnoRadio";
import useQuery from "../../hooks/useQuery";
import accountAPI from "../../api/accountBaseAPI";
import ConfirmModal from "./confirmModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AccountModal = ({
  open = false,
  action,
  hasBlur = false,
  data = {},
  isCreate,
}) => {
  const [form, setForm] = React.useState({scb_connect:"N"});
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState([]);
  const [customer, setCustomer] = React.useState([
    { customer_rm_no: "", account_name: "" },
  ]);
  const {
    data: rm,
    error,
    loading: loadingRM,
  } = useQuery(accountAPI.accountOption, {}, {}, [open]);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);

  React.useEffect(() => {
    if (!isCreate) {
      setForm(data);
      setCustomer(
        data.items?.map((item) => {
        //   if (item?.scb_connect) {
        //     delete item?.scb_connect;
        //   }
          return {
            ...item,
          };
        })
      );
    } else {
      setForm({scb_connect:"N"});
      setCustomer([{ customer_rm_no: "", account_name: "" }]);
    }
    setOpenConfirm(false);
    setErrorOpen(false);
  }, [open]);

  const submit = async () => {
    try {
      setLoading(true);
      var ret;
      if (isCreate)
        ret = await accountBaseAPI.accountCreate({
          ...form,
          items: customer,
        });
      else
        ret = await accountBaseAPI.accountUpdate(form.parent_id, {
          ...form,
          items: customer,
        });
      if (ret.status === 200) {
        action(true);
      }
    } catch (e) {
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const Validate = () => {
    var error = [];
    if (!form.parent_name || form.parent_name == "")
      error.push({ message: "Parent name is required" });
    // if (!form.scb_connect || form.amount == "")
    //     error.push({ message: "Amount is required" })
    if (customer.length < 1)
      error.push({ message: "Customer required atleast one" });
    else {
      for (var i = 0; i < customer.length; i++) {
        if (!customer[i].customer_rm_no || customer[i].customer_rm_no == "")
          error.push({ message: "Customer RM NO is required" });
        // if (!customer[i].account_name || customer[i].account_name == "")
        //   error.push({ message: "Account name is required" });
      }
    }

    setErrorOpen(error.length > 0);
    if (error.length === 0) {
      submit();
    }
    setErrorState(error);
  };

  const Customer = (item, index) => {


    const selectCustomer = (e) => {
      var temp = [...customer];
      const selectedRM = rm.find((item) => item.rm_no === e.target.value);
      customer[index].customer_rm_no = selectedRM.rm_no;
      customer[index].account_name = selectedRM.part_srch_pname;
      setCustomer(temp);
    };

    return (
      <div className="flex flex-row w-full my-20px">
        <div className="grid grid-cols-7 w-full gap-4">
          <div className="col-span-3">
            <RMDropdown
              label="RM NO / CUS REF CODE"
              onChange={selectCustomer}
              value={item}
              options={rm}
            />
          </div>
          <div className="col-span-2">
            <Content label="A/C NAME" value={item?.account_name || ""} />
          </div>
          <div className="col-span-2">
            {/* <YesNoRadio value={item.scb_connect} handleChange={toogleSCB} /> */}
          </div>
        </div>

        <button onClick={() => deleteCustomer(index)}>
          <img className="w-20px h-20px" src={removeIcon}></img>
        </button>
      </div>
    );
  };

  const addCustomer = () => {
    setCustomer([
      ...customer,
      { customer_rm_no: "", account_name: "", scb_connect: "N" },
    ]);
  };

  const deleteCustomer = (index) => {
    var temp = customer;
    temp.splice(index, 1);
    setCustomer([...temp]);
  };

  const deleteAccount = async () => {
    try {
      setLoadingModal(true);
      const res = await accountAPI.accountDelete(form.parent_id);
      if (res) {
        setLoadingModal(false);
        action(true);
      }
    } catch (e) {
      setLoadingModal(false);
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    }
  };

  const globalToogleSCB = (e) => {
    // const isChecked = e.target.value;
    // setIsConnect(isChecked);
    setForm({ ...form, scb_connect: e.target.value });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      className={"z-index-1"}
      fullWidth={true}
      maxWidth={"md"}
      sx={
        hasBlur
          ? {
              backdropFilter: "blur(5px)",
            }
          : {}
      }
    >
      <ConfirmModal
        title="Delete A/C Group"
        message="Are you insist to delete ?"
        onConfirm={deleteAccount}
        open={openConfirm}
        setOpen={setOpenConfirm}
        loading={loadingModal}
      />
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: "#ccc",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        align="center"
      >
        {(!isCreate ? "EDIT" : "ADD") + ""}
        <Invalid
          pt="pt-20px"
          errorState={errorState}
          open={errorOpen}
          setOpen={setErrorOpen}
          isShow={true}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description "
          sx={{ padding: "40px" }}
        >
          <div className="flex justify-between">
            <div className="w-[600px]">
              <TextField
                inputProps={{ style: { color: "#7A58BF" } }}
                label="PARENT NAME"
                variant="standard"
                fullWidth
                value={form?.parent_name}
                onChange={(e) =>
                  setForm({ ...form, parent_name: e.target.value })
                }
              />
            </div>
            <div className="">
              <YesNoRadio
                value={form?.scb_connect}
                handleChange={globalToogleSCB}
              />
            </div>
          </div>

          {loadingRM ? (
            <div className="mt-2">
              <Skeleton count={1} height={30} />
            </div>
          ) : (
            <div>{customer.map((item, index) => Customer(item, index))}</div>
          )}
          <FooterButton text="ADD A/C NAME" action={addCustomer} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={action}>
          Cancel
        </Button>
        <LoadingButton
          // startIcon={<img alt="" src={SaveIcon} />}
          variant="contained"
          //  sx={{ fontSize: "14px" }}
          onClick={Validate}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AccountModal;
