import { FormControl, FormLabel,RadioGroup,Radio,FormControlLabel } from "@mui/material"

const YesNoRadio = ({ label = "CONNECTED WITH SCB",value="Y" ,handleChange}) => {
    return (
        <FormControl>
            <FormLabel ><div className="text-label">{label}</div></FormLabel>
            <RadioGroup
                row={true}
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value="Y" control={<Radio />} label="YES" />
                <FormControlLabel value="N" control={<Radio />} label="NO" />
            </RadioGroup>
        </FormControl>)
}

export const OptionRadio = ({ label = "Connected With SCB",value,values=[] ,handleChange}) => {
    return (
        <FormControl>
            <FormLabel ><div className="text-label">{label}</div></FormLabel>
            <RadioGroup
                row={true}
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value={values[0]} control={<Radio />} label={values[0]} />
                <FormControlLabel value={values[1]}  control={<Radio />} label={values[1]} />
            </RadioGroup>
        </FormControl>)
}

export default YesNoRadio