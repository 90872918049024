import EditIcon from "../../assets/icon/edit.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { RootContext } from "../../context/root.context";
import Skeleton from "react-loading-skeleton";
import useQuery from "../../hooks/useQuery";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import nostroAPI from "../../api/nostroBaseAPI";
import { NOSTRO_DETAIL } from "../../constrant/text.constrant";
import { ROUTE_NOSTRO } from "../../constrant/route.constrant";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { NostroTable } from "../../components/table/nostroTable";
import "react-loading-skeleton/dist/skeleton.css";
import { addCommasToString, formatNumberComma, formatNumberCommaTwoDigit } from "../../utils/util";
import NostroModal from "../../components/modal/nostroModal";
import { formateDateWithTimeHourMintues, isValidDate, ScbText } from "../../utils/util.main";
import HKError from "../../components/error/alertError";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { OptionRadio } from "../../components/Input/yesnoRadio";
import ReactExport from "react-export-excel-xlsx-fix";
import Download from "../../assets/icon/download_scb.svg";
import numeral from "numeral";
import moment from "moment";
import { apiBaseURL } from "../../config/config.main";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Nostro = () => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const [dateValue, setDateValue] = useState(date);
  const { user } = useSelector((state) => state.auth);
  const [showAll, setShowAll] = useState("N")
  const [reload, setReload] = useState(0);
  const [regenLoading, setRegenLoading] = useState(false);
  const [viewType, setViewType] = useState("Latest Data");
  const [errorState, setErrorState] = useState([])
  const [errorOpen, setErrorOpen] = useState(false);
  const { setRouteName, routeName, showModal, setShowModal } =
    useContext(RootContext);
  const { data, loading, error } = useQuery(
    viewType === "Latest Data"
      ? nostroAPI.nostroLatest
      : nostroAPI.nostroInquiry,
    {
      ...(viewType !== "Latest Data" && {
        report_date:
          dateValue instanceof Date && !isNaN(new Date(dateValue))
            ? format(new Date(dateValue), "yyyy-MM-dd")
            : "",

      }),
      showall: showAll
    },
    {},
    [dateValue, reload, showAll],
    {
      // checkFirstLoad:  viewType === "Latest Data" ||
      //   (dateValue instanceof Date && !isNaN(dateValue) ? true : false),
      checkFirstLoad: true
    }
  );
  const [selectedNostro, setSelectedNostro] = useState({});

  const RowAction = (item) => {
    if (user?.user_role === "REPORT") return <></>;

    return (
      <button className="w-12">
        <img
          className="h-20px w-20px cursor-pointer"
          src={EditIcon}
          onClick={() => {
            setSelectedNostro({
              ...item,
            });
            setShowModal(true);
          }}
        ></img>
      </button>
    );
  };

  const changeViewType = (e) => {
    setViewType(e.target.value);
    setReload(reload + 1);
  };

  const regen = async () => {

    try {
      setRegenLoading(true);
      const ret = await nostroAPI.nostroRegen({
        report_date: format(new Date(dateValue), "yyyy-MM-dd"),
      });

    } catch (e) {
      setErrorState({ message: e?.response.data?.status?.description })
    } finally {
      setRegenLoading(false);
    }
  };

  const regenValidate = () => {
    const asOfdate = moment(dateValue).format("YYYY-MM-DD");
    const invalid = (asOfdate.toString() === "Invalid date") || asOfdate.toString().startsWith("0")
    if (invalid) {
      setErrorOpen(true);
      setErrorState({ message: "As of date is required" });
    }
    else {
      setErrorOpen(false);
      regen();
    }
  }

  const closeModal = (shouldReload = false) => {
    setShowModal(false);
    setSelectedNostro({});
    if (shouldReload) {
      setReload(reload + 1);
    }
  };

  useEffect(() => {
    setRouteName([
      { title: "PARAMETER", path: "/" },
      { title: NOSTRO_DETAIL, path: ROUTE_NOSTRO },
    ]);
    setShowModal(false);
  }, []);


  const createDataWithStyle = (data = []) => {
    if (!data) return;

    var result = [];
    const borders = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    for (let i = 0; i < data?.length; i++) {
      var filled = { fgColor: { rgb: "FFFFFF" } };
      var filled_col_0 = { patternType: "solid", fgColor: { rgb: "FDFFA7" } };

      result.push([
        {
          value: `${data[i]?.gl_acct || ""}`,
          style: { font: { sz: 14 }, alignment: "left", fill: filled_col_0 },
        },
        {
          value: data[i]?.gl_acct_desc || "",
          style: { font: { sz: 14 }, alignment: "left" },
        },
        {
          value: data[i]?.original_curr || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: formatNumberCommaTwoDigit(data[i]?.os_amount_original) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.hq_country_code || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.inward_risk_transfer || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.outward_risk_transfer || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.int_bearing_flag || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.fix_var || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.country_code || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },


      ]);
    }
    return result;
  };

  const multiDataSet = [
    {
      columns: [
        {
          value: "G/L ACC",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "G/L ACCT DESCRIPTION",
          widthPx: 450,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "ORIGINAL CURR",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "AMOUNT [OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        }, //char width
        {
          value: "Country of Head Quarter / Guarantor",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "Inward risk transfer",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "Outward risk transfer",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "Int Bearing Flag",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "Fix/Var",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "Country Code",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
      ],
      data: createDataWithStyle(data),
    },
  ];

  const onClickExportExcel = async () => {
    let uri = `/v1/parameter/nostrolatest/download?showall=${showAll}`;
    if (viewType === "Data as of Date") {
      const reportDate = format(new Date(dateValue), "yyyy-MM-dd");
      uri = `/v1/parameter/nostro/download?report_date=${reportDate}`;
    }

    try {
      const access_token = sessionStorage.getItem("access_token");
      const url = apiBaseURL + uri;
      fetch(url, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
    } catch (e) {
      console.log("error", e);
      alert("Unable to export excel");
    }
  };


  return (
    <div className="w-full">
    {errorOpen ? <HKError error={errorState} title="ValidationError" /> : 
      (error || errorState?.message) && <HKError error={error || errorState} />}
      <div className="mt-20px p-large w-full ">
        <NostroModal reportDate={
          dateValue instanceof Date && !isNaN(dateValue)
            ? format(new Date(dateValue), "yyyy-MM-dd")
            : ""
        } isLatest={viewType === "Latest Data"} open={showModal} action={closeModal} data={selectedNostro} />
        <div className="flex flex-row justify-center  p-large uppercase pb-0">
          <OptionRadio
            label=""
            value={viewType}
            values={["Latest Data", "Data as of Date"]}
            handleChange={changeViewType}
          />



          {viewType === "Data as of Date" ? (
            <div className="flex flex-row mt-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  //    views={['day', 'month', 'year']}
                  value={dateValue}
                  onChange={setDateValue}
                  sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 16, color: "#7A58BF" },
                      }}
                      variant="standard"
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              <div className="w-4"></div>
              <ExcelFile
                filename={`EXPORT_NOSTRO_${dateValue instanceof Date && !isNaN(dateValue)
                  ? format(new Date(dateValue), "yyyy-MM-dd")
                  : ""
                  }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
                element={<button id="export-excel-nostro" hidden></button>}
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name="Nostro Detail"
                ></ExcelSheet>
              </ExcelFile>
              <button onClick={onClickExportExcel} className="mx-5">
                <div className="flex flex-row text-textscb font-bold text-14px">
                  <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                  EXPORT EXCEL
                </div>
              </button>
              {user?.user_role !== "REPORT" && (
                <LoadingButton
                  variant="contained"
                  loadingPosition="start"
                  onClick={regenValidate}
                  loading={regenLoading}
                  disabled={regenLoading}
                  sx={{ fontSize: "12px", height: "32px" }}
                  startIcon={<RefreshIcon />}
                >
                  REGEN
                </LoadingButton>
              )}
            </div>
          ) : <div className="flex flex-row"> <FormControlLabel control={<Checkbox onChange={(e) => setShowAll(showAll === "Y" ? "N" : "Y")} checked={showAll === "Y"} />} label="Show All" />
            <ExcelFile
              filename={`EXPORT_NOSTRO_${dateValue instanceof Date && !isNaN(dateValue)
                ? format(new Date(dateValue), "yyyy-MM-dd")
                : ""
                }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
              element={<button id="export-excel-nostro" hidden></button>}
            >
              <ExcelSheet
                dataSet={multiDataSet}
                name="Nostro Detail"
              ></ExcelSheet>
            </ExcelFile>
            <button onClick={onClickExportExcel} className="mx-5">
              <div className="flex flex-row text-textscb font-bold text-14px">
                <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                EXPORT EXCEL
              </div>
            </button></div>
          }
        </div>
        {loading ? (
          <div className="p-fourteen ">
            <Skeleton
              containerTestId="react-loading-skeleton"
              height={30}
              // width={`100%`}
              count={15}
            />
          </div>
        ) : (
          <NostroTable
            rows={MapData(data)}
            formatControl={[
              RowAction,
              ScbText,
              null,
              null,
              formatNumberCommaTwoDigit,
              null,
              null,
              null,
              null,
              null,
              formateDateWithTimeHourMintues,
              null,
            ]}
          />
        )}
      </div>
    </div>
  );
};

const MapData = (data) => {
  let temp = [];
  for (let i = 0; i < data?.length; i++) {
    temp.push({
      action: data[i],
      gl_acct: data[i].gl_acct,
      gl_acct_desc: data[i].gl_acct_desc,
      original_curr: data[i].original_curr,
      os_amount_original: data[i].os_amount_original,
      hq_country_code: data[i].hq_country_code,
      inward_risk_transfer: data[i].inward_risk_transfer,
      outward_risk_transfer: data[i].outward_risk_transfer,
      int_bearing_flag: data[i].int_bearing_flag,
      fix_var: data[i].fix_var,
      update_date_time: data[i].update_date_time,
      update_user: data[i].update_user,
    });
  }
  return temp;
};

export default Nostro;
