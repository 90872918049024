import api from "./api.main";

// config
import { apiConfig } from "../config/config.main";

const getToken = async (params) => {
  return api.instance.get(`${apiConfig.getTokenAPI}${params}`);
};

const refreshToken = async (refresh_token) => {
  // return api.instance.get(
  //   `${apiConfig.refreshTokenAPI}?refreshToken=${refresh_token}`
  // );
  return api.instance.post(
    `${apiConfig.refreshTokenAPI}`, {
    refresh_token: refresh_token
  }
  );
};

const logout = async (body) => {
  try {
      await  api.instance.post(apiConfig.logoutAPI, { ...body });
  } catch (error) {
     sessionStorage.clear();
    // window.location.reload()
    
  }finally{
    sessionStorage.clear();
    // window.location.reload()
  }
};

const login = async (user_id, password) => {
  return api.instance.post(apiConfig.loginAPI, {
    user_id: user_id,
    password: password,
  });
}

const exportedObject = {
  getToken,
  refreshToken,
  logout,
  login
};

export default exportedObject;
