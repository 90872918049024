import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import { Select, TextField } from "@mui/material";
import { CommonDropdown, GeographicalDropDown } from "../Input/commonDropdown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Invalid from "../error/invalidData";
import boundBaseApi from "../../api/boundBaseAPI";
import { LoadingButton } from "@mui/lab";
import {
  COUNTRY_CODE,
  COUNTRY_CODE_NONE,
  FIX_VAR_NONE,
  GENERAL_SPECIFIC,
  MAP_YES_NO,
  MAP_YES_NO_REVERSE,
  ORIGINAL_CURR,
  YES_NO_NONE,
} from "../../constrant/text.constrant";
import {
  addCommasToString,
  convertInputNumCommaToNumber,
  errMaxAmount,
  formatDateToAPI,
  formatFirstCapital,
  inputFormatNumCommaTwoDigit,
  validateMaxAmount,
} from "../../utils/util";
import ConfirmModal from "./confirmModal";
import DeleteIcon from "../../assets/icon/remove.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BoundModal = ({ open = false, action, hasBlur = false, data = {}, isCreate, isLatest = false, reportDate }) => {
  const [form, setForm] = React.useState({ coupon_rate: 0 });
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState([]);
  const [confirm, setConfirm] = React.useState(false);
  const [originalCurr, setOriginalCurr] = React.useState("HKD");
  const [prevOriginalCurr, setPrevOriginalCurr] = React.useState("HKD"); // [HKD, USD
  const [countryCode, setCountryCode] = React.useState("TH");

  React.useEffect(() => {
    // setErrorState([])
    setErrorOpen(false);
    if (!isCreate) {
      setForm(data);
      setOriginalCurr(data.original_curr);
      setPrevOriginalCurr(data.original_curr);
      setCountryCode(data.country_code);
    } else setForm({ coupon_rate: 0 });
  }, [open]);

  const deleteBond = async () => {
    try {
      setLoading(true);
      const ret = await boundBaseApi.boundDelete(isLatest, form.sub_acc, form.account_name, originalCurr, reportDate);
      if (ret.status === 200) action(true);
    } catch (e) {
      console.log("error", e);
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    } finally {
      setLoading(false);
      setConfirm(false);
    }
  };

  const updateFourDigitNumber = (fieldName, newValue) => {
    if (/^\d*\.?\d{0,4}$/.test(newValue)) {
      setForm((prevData) => ({
        ...prevData,
        [fieldName]: newValue,
      }));
    }
  };

  const Submit = async () => {
    try {
      setLoading(true);
      var ret;
      // let cust_ind = form?.cust_ind
      // if(form?.cust_ind === "NONE") cust_ind = ""
      // if(form?.cust_ind === "Bank") cust_ind = "B"
      // if(form?.cust_ind === "Personal") cust_ind = "P"
      // if(form?.cust_ind === "Corporate") cust_ind = "C"

      if (isCreate)
        ret = await boundBaseApi.boundCreate(isLatest, {
          ...form,
          original_curr: originalCurr,
          country_code: countryCode,
          trade_dt: formatDateToAPI(form.trade_dt),
          value_dt: formatDateToAPI(form.value_dt),
          next_roll_dt: formatDateToAPI(form.next_roll_dt),
          maturity_dt: formatDateToAPI(form.maturity_dt),
          cn_related: form.cn_related,
          general_specific: form?.general_specific === "NONE" ? "" : form?.general_specific,
          hkma_rating: form?.hkma_rating === "NONE" ? "" : form?.hkma_rating,
          int_bearing_flag: form.int_bearing_flag,
          fix_var: form?.fix_var === "NONE" ? "" : form?.fix_var,
          geographical_cn: form.geographical_cn === "NONE" ? "" : form.geographical_cn,
          country_usage: form.country_usage === "NONE" ? "" : form.country_usage,
          purchase_amt_original: convertInputNumCommaToNumber(form.purchase_amt_original),
          os_amount_original: convertInputNumCommaToNumber(form.os_amount_original),
          disc_os_amt_original: convertInputNumCommaToNumber(form.disc_os_amt_original),
          gain_loss_os_amt_original: convertInputNumCommaToNumber(form.gain_loss_os_amt_original),
          accr_int_os_amt_original: convertInputNumCommaToNumber(form.accr_int_os_amt_original),
          provision_amt_hkd: convertInputNumCommaToNumber(form.provision_amt_hkd),
          coupon_rate: form.coupon_rate || 0,
          ...(!isLatest && {
            report_date: reportDate,
          }),
        });
      else
        ret = await boundBaseApi.boundUpdate(isLatest, form.sub_acc, form.account_name, prevOriginalCurr, {
          ...form,
          original_curr: originalCurr,
          country_code: countryCode,
          trade_dt: formatDateToAPI(form.trade_dt),
          value_dt: formatDateToAPI(form.value_dt),
          next_roll_dt: formatDateToAPI(form.next_roll_dt),
          maturity_dt: formatDateToAPI(form.maturity_dt),
          cn_related: form.cn_related,
          general_specific: form?.general_specific === "NONE" ? "" : form?.general_specific,
          int_bearing_flag: form.int_bearing_flag,
          fix_var: form?.fix_var === "NONE" ? "" : form?.fix_var,
          hkma_rating: form?.hkma_rating === "NONE" ? "" : form?.hkma_rating,
          report_date: reportDate,
          geographical_cn: form.geographical_cn === "NONE" ? "" : form.geographical_cn,
          country_usage: form.country_usage === "NONE" ? "" : form.country_usage,
          purchase_amt_original: convertInputNumCommaToNumber(form.purchase_amt_original),
          os_amount_original: convertInputNumCommaToNumber(form.os_amount_original),
          disc_os_amt_original: convertInputNumCommaToNumber(form.disc_os_amt_original),
          gain_loss_os_amt_original: convertInputNumCommaToNumber(form.gain_loss_os_amt_original),
          accr_int_os_amt_original: convertInputNumCommaToNumber(form.accr_int_os_amt_original),
          provision_amt_hkd: convertInputNumCommaToNumber(form.provision_amt_hkd),
          coupon_rate: form.coupon_rate || 0,
        });
      if (ret.status === 200) action(true);
    } catch (e) {
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const Validate = () => {
    var error = [];

    if (!form.sub_acc || form.sub_acc.length < 1) error.push({ message: "ISIN CODE / REF. CODE is required" });
    if (!form.account_name || form.account_name.length < 1) {
      error.push({ message: "Account Name is required" });
    }
    if (!originalCurr) error.push({ message: "Original Cur is required" });

    if (!form.bond_item || form.bond_item.length < 1) error.push({ message: "Item is required" });
    if (!form.provision_gl_acct || form.provision_gl_acct.length < 1)
      error.push({ message: "Provision GL Acct is required" });
    if (!form.gl_prod_code || form.gl_prod_code.length > 4) error.push({ message: "GL Prod Code is required" });
    if (form.os_amount_original != null) {
      validateMaxAmount(form.os_amount_original, "AMOUNT [OS Original Curr]", error);
    }
    if (form.purchase_amt_original != null) {
      validateMaxAmount(form.purchase_amt_original, "PURCHASE AMOUNT [Original Curr]", error);
    }

    if (form?.coupon_rate?.length > 0 && isNaN(form.coupon_rate)) {
      error.push({ message: "Coupon Rate is required (0-100)" });
    }
    if (!form.trade_dt || formatDateToAPI(form.trade_dt).length !== 10)
      error.push({ message: "Trade Date is required" });
    if (!form.value_dt || formatDateToAPI(form.value_dt).length !== 10)
      error.push({ message: "Value Date is required" });
    if (!form.next_roll_dt || formatDateToAPI(form.next_roll_dt).length !== 10)
      error.push({ message: "Next Roll Date is required" });
    if (!form.maturity_dt || formatDateToAPI(form.maturity_dt).length !== 10)
      error.push({ message: "Maturity Date is required" });
    if (!form.disc_item || form.disc_item.length < 1) error.push({ message: "Disc Item is required" });
    if (!form.disc_gl_acct || form.disc_gl_acct?.length < 1) error.push({ message: "Disc GL Acct is required" });

    if (form.disc_os_amt_original != null) {
      validateMaxAmount(form.disc_os_amt_original, "Disc Amount [Original Curr]", error, true);
    }
    // if (!form.accr_int_item || form.accr_int_item.length < 1)
    //   error.push({ message: "Accr Int Item is required" });
    // if (!form.accr_int_gl_acct || form.accr_int_gl_acct.length < 1)
    //   error.push({ message: "Accr Int GL Acct is required" });
    // if (
    //   !form.accr_int_os_amt_original ||
    //   form.accr_int_os_amt_original.length < 1 ||
    //   isNaN(form.accr_int_os_amt_original)
    // )
    //   error.push({
    //     message: "Accr Int Amount Original is required and must be number",
    //   });
    if (form.accr_int_os_amt_original != null) {
      const amount = form.accr_int_os_amt_original;
      if (typeof amount === "string") {
        parseFloat(amount.replace(/,/g, "")) > 9999999999999.99 &&
          error.push({ message: errMaxAmount("Accr Int Amount [Original Curr]") });
      } else if (typeof amount === "number") {
        amount > 9999999999999.99 && error.push({ message: errMaxAmount("Accr Int Amount [Original Curr]") });
      }
    }
    if (!form.gain_loss_item || form.gain_loss_item.length < 1) error.push({ message: "Gain Loss Item is required" });
    if (!form?.gain_loss_gl_acct || form.gain_loss_gl_acct?.length < 1) {
      error.push({ message: "Gain Loss Account is required" });
    }
    if (form?.gain_loss_os_amt_original != null) {
      validateMaxAmount(form.gain_loss_os_amt_original, "Gain Loss Amount [Original Curr]", error, true);
    }

    if (!form?.provision_acct && form.provision_acct) {
      error.push({ message: "Provision Account is required" });
    }
    if (form.provision_amt_hkd != null) {
      validateMaxAmount(form.provision_amt_hkd, "Provision Amount [HKD EQV]", error);
    }
    setErrorOpen(error.length > 0);
    if (error.length === 0) {
      Submit();
    } else {
      setErrorState(error);
    }
  };

  const mapCustID = (custID) => {
    if (custID === "B") {
      return "Bank";
    } else if (custID === "P") {
      return "Personal";
    } else if (custID === "C") {
      return "Corporate";
    } else {
      return "NONE";
    }
  };

  const mapCustIDRevert = (custID) => {
    if (custID === "Bank") {
      return "B";
    } else if (custID === "Personal") {
      return "P";
    } else if (custID === "Corporate") {
      return "C";
    } else {
      return "";
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      className={"z-index-1"}
      maxWidth="md"
      sx={
        hasBlur
          ? {
              backdropFilter: "blur(5px)",
            }
          : {}
      }
    >
      <ConfirmModal
        title="Delete this Bond"
        message="Are you insist to delete ?"
        onConfirm={deleteBond}
        open={confirm}
        setOpen={setConfirm}
        loading={loading}
      />
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: "#ccc",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        align="center"
      >
        {(!isCreate ? "EDIT" : "ADD") + " BOND"}
        <Invalid pt="pt-20px" errorState={errorState} open={errorOpen} setOpen={setErrorOpen} isShow={true} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description " sx={{ paddingX: "40px" }} className="uppercase">
          <div className="ml-40px">
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="ISIN CODE / REF. CODE"
              disabled={!isCreate}
              variant="standard"
              fullWidth
              sx={{ marginTop: "20px" }}
              value={form?.sub_acc}
              onChange={(e) => setForm({ ...form, sub_acc: e.target.value?.trim() })}
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="A/C Name"
              variant="standard"
              fullWidth
              sx={{ marginTop: "20px" }}
              value={form?.account_name}
              onChange={(e) => setForm({ ...form, account_name: e.target.value })}
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="RM No"
              variant="standard"
              fullWidth
              sx={{ marginTop: "20px" }}
              value={form?.rm_no}
              onChange={(e) => setForm({ ...form, rm_no: e.target.value })}
            />
            <div className="h-20px"> </div>
            <CommonDropdown
              disabled={false}
              label="Original Curr"
              value={originalCurr}
              onChange={(e) => setOriginalCurr(e.target.value)}
              options={ORIGINAL_CURR}
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              label="Item"
              value={form?.bond_item}
              onChange={(e) => setForm({ ...form, bond_item: e.target.value })}
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              label="G/L ACCT"
              value={form?.gl_acct}
              onChange={(e) => setForm({ ...form, gl_acct: e.target.value })}
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 4 }}
              label="GL PROD CODE"
              value={form?.gl_prod_code}
              onChange={(e) => setForm({ ...form, gl_prod_code: e.target.value })}
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="AMOUNT [OS ORIGINAL CURR]"
              value={addCommasToString(form?.os_amount_original)}
              onChange={(e) => inputFormatNumCommaTwoDigit("os_amount_original", e.target.value, setForm)}
              type="text"
              variant="standard"
              placeholder="0.00"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="PURCHASE AMOUNT [ORIGINAL CURR]"
              value={addCommasToString(form?.purchase_amt_original)}
              onChange={(e) => inputFormatNumCommaTwoDigit("purchase_amt_original", e.target.value, setForm)}
              variant="standard"
              type="text"
              placeholder="0.00"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              label="Coupon Rate"
              value={form?.coupon_rate}
              onChange={(e) => updateFourDigitNumber("coupon_rate", e.target.value)}
              variant="standard"
              type="text"
              placeholder="0.0000"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <div className="h-20px"> </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="dd/MM/yyyy"
                value={form?.trade_dt || null}
                onChange={(e) => setForm({ ...form, trade_dt: e })}
                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 16, color: "#7A58BF" },
                    }}
                    fullWidth={true}
                    variant="standard"
                    label="Trade Date"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
            <div className="h-20px"> </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="dd/MM/yyyy"
                value={form?.value_dt || null}
                onChange={(e) => setForm({ ...form, value_dt: e })}
                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 16, color: "#7A58BF" },
                    }}
                    fullWidth={true}
                    variant="standard"
                    label="Value Date"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
            <div className="h-20px"> </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="dd/MM/yyyy"
                value={form?.next_roll_dt || null}
                onChange={(e) => setForm({ ...form, next_roll_dt: e })}
                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 16, color: "#7A58BF" },
                    }}
                    fullWidth={true}
                    variant="standard"
                    label="Next Roll Date"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
            <div className="h-20px"> </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="dd/MM/yyyy"
                value={form?.maturity_dt || null}
                onChange={(e) => setForm({ ...form, maturity_dt: e })}
                sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 16, color: "#7A58BF" },
                    }}
                    fullWidth={true}
                    variant="standard"
                    label="Maturity Date"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
            <div className="h-20px"> </div>
            <CommonDropdown
              label="Country Code"
              value={countryCode || "TH"}
              onChange={(e) => setCountryCode(e.target.value)}
              options={COUNTRY_CODE}
            />
          </div>
          <div className="text-textprimary font-bold text-14px mt-40px">Premium / Discount</div>
          <div className="ml-40px">
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.disc_item}
              onChange={(e) => setForm({ ...form, disc_item: e.target.value })}
              label="Item"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.disc_gl_acct}
              onChange={(e) => setForm({ ...form, disc_gl_acct: e.target.value })}
              label="G/L ACCT"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              value={addCommasToString(form?.disc_os_amt_original)}
              onChange={(e) => inputFormatNumCommaTwoDigit("disc_os_amt_original", e.target.value, setForm, true)}
              label="AMOUNT [ORIGINAL CURR]"
              type="text"
              placeholder="0.00"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
          </div>

          <div className="text-textprimary font-bold text-14px mt-40px">GAIN / LOSS</div>
          <div className="ml-40px mb-40px">
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.gain_loss_item}
              onChange={(e) => setForm({ ...form, gain_loss_item: e.target.value })}
              label="Item"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.gain_loss_gl_acct}
              onChange={(e) => setForm({ ...form, gain_loss_gl_acct: e.target.value })}
              label="G/L ACCT"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              value={addCommasToString(form?.gain_loss_os_amt_original)}
              onChange={(e) => inputFormatNumCommaTwoDigit("gain_loss_os_amt_original", e.target.value, setForm, true)}
              type="text"
              placeholder="0.00"
              label="AMOUNT [ORIGINAL CURR]"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
          </div>

          <div className="text-textprimary font-bold text-14px mt-40px">ACCRU Interest</div>
          <div className="ml-40px mb-40px">
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.accr_int_item}
              onChange={(e) => setForm({ ...form, accr_int_item: e.target.value })}
              label="Item"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.accr_int_gl_acct}
              onChange={(e) => setForm({ ...form, accr_int_gl_acct: e.target.value })}
              label="G/L ACCT"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              value={addCommasToString(form?.accr_int_os_amt_original)}
              onChange={(e) => inputFormatNumCommaTwoDigit("accr_int_os_amt_original", e.target.value, setForm)}
              label="AMOUNT [ORIGINAL CURR]"
              type="text"
              placeholder="0.00"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
          </div>
          {/* NEW */}

          <div className="text-textprimary font-bold text-14px mt-40px">PROVISION</div>
          <div className="ml-40px mb-40px">
            <TextField
              inputProps={{ style: { color: "#7A58BF" }, maxLength: 8 }}
              value={form?.provision_gl_acct || ""}
              onChange={(e) => setForm({ ...form, provision_gl_acct: e.target.value })}
              label="G/L ACCT"
              variant="standard"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
            <TextField
              inputProps={{ style: { color: "#7A58BF" } }}
              value={addCommasToString(form?.provision_amt_hkd)}
              onChange={(e) => inputFormatNumCommaTwoDigit("provision_amt_hkd", e.target.value, setForm)}
              label="AMOUNT [HKD EQV]"
              variant="standard"
              type="text"
              placeholder="0.00"
              sx={{ marginTop: "20px" }}
              fullWidth
            />
          </div>

          <div className="ml-40px">
            <GeographicalDropDown
              value={form?.geographical_cn || "NONE"}
              onChange={(e) => setForm({ ...form, geographical_cn: e.target.value })}
            />
            <div className="h-10px" />
            <CommonDropdown
              label="Country Usage"
              value={form?.country_usage || "NONE"}
              onChange={(e) => setForm({ ...form, country_usage: e.target.value })}
              options={COUNTRY_CODE_NONE}
            />
            <div className="h-10px" />
            <div className="w-full">
              <CommonDropdown
                label="CN Related"
                value={MAP_YES_NO[form?.cn_related || ""]}
                onChange={(e) => setForm({ ...form, cn_related: MAP_YES_NO_REVERSE[e.target.value] })}
                options={YES_NO_NONE}
              />
            </div>
            <div className="h-10px" />
            <div className="w-full">
              <CommonDropdown
                label="General / Specific"
                value={formatFirstCapital(form?.general_specific || "")}
                onChange={(e) => setForm({ ...form, general_specific: e.target.value })}
                options={GENERAL_SPECIFIC}
              />
            </div>
            <div className="h-10px" />
            <CommonDropdown
              label={"Credit Rating"}
              value={(form?.credit_rating || "") === "" ? "NONE" : form?.credit_rating}
              onChange={(e) => setForm({ ...form, credit_rating: e.target.value === "NONE" ? "" : e.target.value })}
              options={[
                "NONE",
                "C1",
                "C2",
                "C3",
                "C4",
                "C5",
                "C6",
                "C7",
                "C8",
                "C9",
                "C10",
                "C11",
                "C12",
                "C13",
                "C14",
                "C15",
                "C16",
                "C17",
                "C18",
                "C19",
                "C20",
                "C21",
                "C22",
                "M1",
                "M2",
                "M3",
                "M4",
                "M5",
                "M6",
                "M7",
                "M8",
                "M9",
                "M10",
                "M11",
                "M12",
                "M13",
                "M14",
                "M15",
                "M16",
                "M17",
                "M18",
                "M19",
                "M20",
                "M21",
                "M22",
              ]}
            />
            <div className="h-10px" />
            <CommonDropdown
              label={"HKMA Rating"}
              value={(form?.hkma_rating || "") === "" ? "NONE" : form?.hkma_rating}
              onChange={(e) => setForm({ ...form, hkma_rating: e.target.value === "NONE" ? "" : e.target.value })}
              options={[
                "NONE",
                "Pass",
                "Special Mention-Qualitative",
                "Substandard",
                "Doubtful",
                "Doubtful Loss/ Loss",
              ]}
            />
            <div className="h-10px" />
            <div className="w-full">
              <CommonDropdown
                label="INT BEARING FLAG"
                value={MAP_YES_NO[form?.int_bearing_flag || ""]}
                onChange={(e) => {
                  const newInt = e.target.value;
                  let newFixVar = form?.fix_var;

                  if (["NONE", "NO"].includes(newInt)) {
                    newFixVar = "NONE";
                  }

                  if (form?.fix_var === "NONE" && newInt === "YES") {
                    newFixVar = "Fix";
                  }
                  setForm({
                    ...form,
                    int_bearing_flag: MAP_YES_NO_REVERSE[e.target.value],
                    fix_var: newFixVar,
                  });
                }}
                options={YES_NO_NONE}
              />
            </div>
            <div className="h-10px" />
            <div className="w-full">
              <CommonDropdown
                label="FIX / VAR"
                value={form?.fix_var || "NONE"}
                onChange={(e) => {
                  setForm({ ...form, fix_var: e.target.value });
                }}
                options={
                  form?.int_bearing_flag === "YES" || form?.int_bearing_flag === "Y"
                    ? FIX_VAR_NONE.filter((item) => item !== "NONE")
                    : FIX_VAR_NONE.filter((item) => item === "NONE")
                }
              />
            </div>
            <div className="w-full mt-10px">
              <CommonDropdown
                label="CUST IND"
                value={mapCustID(form?.cust_ind || "")}
                onChange={(e) => {
                  setForm({ ...form, cust_ind: mapCustIDRevert(e.target.value) });
                }}
                options={["NONE", "Bank", "Personal", "Corporate"]}
              />
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <img
          className="h-5 w-5 mr-1 cursor-pointer"
          onClick={() => {
            setConfirm(true);
          }}
          src={DeleteIcon}
        ></img> */}
        <Button variant="text" onClick={action}>
          Cancel
        </Button>

        <LoadingButton
          // startIcon={<img alt="" src={SaveIcon} />}
          variant="contained"
          //  sx={{ fontSize: "14px" }}
          onClick={Validate}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BoundModal;
