import React from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTE_REPORT } from "../constrant/route.constrant";
import ReportMain from "../pages/report/report.main";
import ProtectedRoute from "./protectedRoute";

function ReportRoute() {
  return (
    <Routes>
      <Route path={ROUTE_REPORT} element={<ProtectedRoute><ReportMain />  </ProtectedRoute>} />
    </Routes>
  );
}

export default ReportRoute;
