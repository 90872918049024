import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";
import NotFoundIcon from "../../assets/icon/not_found.svg";
import { MAX_VALUE } from "../../constrant/text.constrant";
import { BOND_HEADER_GROUP, DEFAULT_HEADER_STYLE } from "../../constrant/style.constrant";
import { minWidth } from "@mui/system";
import { formatNumberComma, formatNumberCommaTwoDigit } from "../../utils/util";
import { TableFooter } from "@mui/material";
import useWindowDimensions from "../../utils/util";

export const BoundTable = ({
  rows,
  showFooter = false,
  selectable = false,
  onClickRow,
  page = 0,
  setPage,
  orderByDefault = "",
  hasUtilIndex = false,
  formatControl,
  showFields = [],
  searched,
  overwriteStyle = null,
  disabledSort = false,
  orderDefault = "asc",
  showNoData = false,
  rowsPerPage = 999,
  disabledSortList = [],
  disabledCursor = false,
  totals = [0, 0, 0,0,0],
}) => {
  const [order, setOrder] = React.useState(orderDefault);
  // set default order by here
  const [orderBy, setOrderBy] = React.useState(orderByDefault);
  const [sortBy, setSortBy] = React.useState(true);
  const { height } = useWindowDimensions();

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const getDisabledSort = (headCell) => {
      if (disabledSort) {
        return disabledSort;
      } else if (disabledSortList.length > 0) {
        return disabledSortList.some((item) => item === headCell.id);
      }
      return false;
    };

    return (
      <TableHead>
        <TableRow className="border-b-0 " hover={false}>
          <TableCell
            rowSpan={2}
            sx={{
              position: "sticky",
              left: 0,
              top: 0,
              backgroundColor: "white",
              zIndex: 100,
            }}
          >
            <div className="w-80px"></div>
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              verticalAlign: "top",
              position: "sticky",
              left: 100,
              top: 0,
              zIndex: 100,
              background: "white",
              textAlign:"center",
            }}
          >
            <div style={{minWidth:"150px"}}>
              ISIN CODE / <br/>REF. CODE
            </div>
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              verticalAlign: "top",
              position: "sticky",
              left: 280,
              background: "white",
              textAlign:"center",
              zIndex: 100,
              top: 0,
            }}
          >
            <div style={{minWidth:"100px"}}>A/C NAME</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-12">RM NO</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div>ORIGINAL</div> CURR
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            ITEM
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-20">GL/ ACCT</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-24 text-center">GL</div> PROD CODE
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-40 text-center">AMOUNT <br/> [OS ORIGINAL CURR]</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-48 text-center">PURCHASE AMOUNT <br/> [ORIGINAL CURR]</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            COUPON RATE
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-20">TRADE DT</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-20">VALUE DT</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-28">NEXT ROLL DT</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="w-24">MATURITY DT</div>
          </TableCell>
          <TableCell rowSpan={2} sx={DEFAULT_HEADER_STYLE}>
            <div className="text-center">COUNTRY</div>
            <div className="text-center">CODE</div>{" "}
          </TableCell>
        
          <TableCell
            sx={BOND_HEADER_GROUP}
            rowSpan={1}
            colSpan={3}
          >
            PREMIUM/DISCOUNT
          </TableCell>
          <TableCell
            sx={BOND_HEADER_GROUP}
            rowSpan={1}
            colSpan={3}
          >
            GAIN / LOSS
          </TableCell>
          <TableCell
            sx={BOND_HEADER_GROUP}
            rowSpan={1}
            colSpan={3}
          >
            ACCRU INTEREST
          </TableCell>
          <TableCell
            sx={BOND_HEADER_GROUP}
            rowSpan={1}
            colSpan={2}
          >
            <div className="text-center">PROVISION</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-40">GEOGRAPHICAL IN CN</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-32">COUNTRY USAGE</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-24">CN RELATED</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-32">GENERAL/SPECIFIC</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div>CREDIT RATING</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="text-center">HKMA RATING</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div style={{minWidth:"95px"}}>INT BEARING <br/> FLAG</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div>FIX/VAR</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-20">CUST IND</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-32"> LATEST - DATE</div>
          </TableCell>
          <TableCell sx={DEFAULT_HEADER_STYLE} rowSpan={2}>
            <div className="w-28">UPDATED BY</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>ITEM</TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>
            <div className="w-12">G/L ACCT</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#7A58BF" }}>
            <div className="text-center w-32">AMOUNT<br/>[ORIGINAL CURR]</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>ITEM</TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>
            <div className="w-12">G/L ACCT</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#7A58BF" }}>
            <div className="text-center w-32">AMOUNT<br/>[ORIGINAL CURR]</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>ITEM</TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>
            <div className="w-12">G/L ACCT</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#7A58BF" }}>
            <div className="text-center w-32">AMOUNT<br/>[ORIGINAL CURR]</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#00000080" }}>
            <div className="w-12">G/L ACCT</div>
          </TableCell>
          <TableCell sx={{ fontSize: "10px", verticalAlign: "top", color: "#7A58BF" }}>
            <div className="text-center w-32">AMOUNT [HKD EQV]</div>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const DataArrayTable = disabledSort
    ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : stableSort(rows, getComparator(order, orderBy), orderBy, sortBy)?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

  const convertShowFieldsObject = (row) => {
    let temp = [];
    if (showFields.length === 0) {
      /// Non Show Field case
      Object.keys(row).map((key) => temp.push(row[key]));
    } else {
      for (let i = 0; i < showFields.length; i++) {
        const key = showFields[i];
        if (row[key]) {
          temp.push(row[key]);
        } else {
          temp.push("");
        }
      }
    }
    return temp;
  };

  if (showNoData && DataArrayTable.length <= 0) {
    return (
      <TableContainer>
        <NoRecordFound />
      </TableContainer>
    );
  }

  return (
    <div className="">
      <TableContainer sx={{ height: height - 180 }}>
        {searched?.length > 2 && rows?.length <= 0 ? (
          <NoRecordFound />
        ) : (
          <Table
            sx={TABLE_STYLE_WITH_HOVER_STANDARD_WITDH}
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              rowCount={rows?.length}
              onRequestSort={handleRequestSort}
              disabledSortList={disabledSortList}
            />
            <TableBody>
              {DataArrayTable?.map((row, index) => {
                return (
                  <TableRow
                    id="table-row"
                    key={index}
                    hover={selectable}
                    onClick={() => onClickRow && onClickRow(row)}
                    sx={{
                      ...TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
                    }}
                  >
                    {convertShowFieldsObject(row).map((item, index) => {
                      if (overwriteStyle !== null) {
                        return (
                          <TableCell
                            id="table-cell"
                            align="left"
                            key={index}
                            sx={{
                              ...overwriteStyle[index],
                              cursor: disabledCursor && "auto",
                            }}
                          >
                            {item}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          id="table-cell"
                          align="left"
                          key={index}
                          sx={
                            index < 3
                              ? {
                                  position: "sticky",
                                  left: index * 100 + (index > 1 ? 80 : 0),
                                  background: "white",
                                }
                              : {}
                          }
                          className=""
                        >
                          {formatControl === undefined ||
                          formatControl[index] == null
                            ? item
                            : formatControl[index](item, index)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow sx={{}}>
                <TableCell
                  sx={{
                    border: "0px",
                    position: "sticky",
                    left: 0,
                    background: "white",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: "0px",
                    position: "sticky",
                    left: 100,
                    background: "white",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: "0px",
                    fontWeight: "bold",
                    fontSize: "24px",
                    position: "sticky",
                    left: 200,
                    background: "white",
                  }}
                >
                  Total
                </TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>

                <TableCell
                  sx={{ border: "0px", color: "#7A58BF", fontWeight: 700 }}
                >
                  {formatNumberCommaTwoDigit(totals[0])}{" "}
                </TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell
                  sx={{ border: "0px", color: "#7A58BF", fontWeight: 700 }}
                >
                  {formatNumberCommaTwoDigit(totals[1])}
                </TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell
                  sx={{ border: "0px", color: "#7A58BF", fontWeight: 700 }}
                >
                  {formatNumberCommaTwoDigit(totals[2])}
                </TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell
                  sx={{ border: "0px", color: "#7A58BF", fontWeight: 700 }}
                >
                  {formatNumberCommaTwoDigit(totals[3])}
                </TableCell>
                <TableCell sx={{ border: "0px" }}></TableCell>
                <TableCell
                  sx={{ border: "0px", color: "#7A58BF", fontWeight: 700 }}
                >
                  {formatNumberCommaTwoDigit(totals[4])}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {rows?.length > 0 && showFooter && (
        <div className="border-t">
          <TablePagination
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[]}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      )}
    </div>
  );
};

/// Sort
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, name, sortBy) {
  if (name?.length > 0) {
    return array?.sort((a, b) => {
      if (sortBy) {
        return a[name]?.toString().localeCompare(b[name]);
      } else {
        return b[name]?.toString().localeCompare(a[name]);
      }
    });
  } else {
    return array;
  }
}

const TABLE_STYLE_WITH_HOVER_STANDARD_WITDH = {
  minWidth: 650,
  // tableLayout: "fixed",
  "& .MuiTableRow-root:hover": {
    //     cursor: "pointer",
  },
};

const TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER = {
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#7A58BF70 !important",
    //      cursor: "pointer",
  },
  "&:last-child td, &:last-child th": { border: 0 },
};

const NoRecordFound = () => {
  return (
    <div id="NoRecordFound" className="m-auto pt-xl">
      <img
        className="w-28px h-28px m-auto mb-5px"
        alt="error"
        src={NotFoundIcon}
      />
      <div className="text-value  w-full text-center text-textsecoundary">
        No Record Found.
      </div>
    </div>
  );
};
