import { useContext, useEffect, useState } from "react";
import { CommonTable } from "../../components/table/commonTable";
import { ACCOUNT_GROUP_MANAGEMENT } from "../../constrant/text.constrant";
import { RootContext } from "../../context/root.context";
import AccountRoute from "../../routes/accountRoute";
import EditIcon from "../../assets/icon/edit.svg";
import DeleteIcon from "../../assets/icon/remove.svg";
import {
  downloadFile,
  formateDateWithTimeHourMintues,
} from "../../utils/util.main";
import accountAPI from "../../api/accountBaseAPI";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import useQuery from "../../hooks/useQuery";
import Searchbar from "../../components/searchbar/searchbar";
import AccountModal from "../../components/modal/accountModal";
import ConfirmModal from "../../components/modal/confirmModal";
import Download from "../../assets/icon/download_scb.svg";
import ReactExport from "react-export-excel-xlsx-fix";
import { format } from "date-fns";
import HKError from "../../components/error/alertError";
import { apiBaseURL } from "../../config/config.main";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import RegenAccountModal from "../../components/modal/regenAccountModal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AccountGroupMangement = () => {
  const { setRouteName, routeName, showModal, setShowModal } =
    useContext(RootContext);
  const [searched, setSearched] = useState("");
  const [isCreate, setIsCreate] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState();
  const [reload, setReload] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [regenModal, setRegenModal] = useState(false);


  const { data, loading, error } = useQuery(
    accountAPI.accountInquiry,
    {
      query: searched,
    },
    {},
    [searched, reload],
    { prePareData: null, checkFirstLoad: true },
    true,
    3
  );

  useEffect(() => {
    setRouteName([
      { title: "PARAMETER", path: "/" },
      { title: ACCOUNT_GROUP_MANAGEMENT, path: AccountRoute },
    ]);
    setShowModal(false);
  }, []);

  const deleteAccount = async () => {
    try {
      setLoadingModal(true);
      const res = await accountAPI.accountDelete(selectedAccount.parent_id);
      if (res) {
        setLoadingModal(false);
        setOpenConfirm(false);
        setReload(reload + 1);
      }
    } catch (e) {
      setLoadingModal(false);
    }
  };

  const clear = (reload = false) => {
    setRegenModal(false);
    if (true) setReload(reload + 1);
  };


  // const regen = async () => {
  //   try {
  //     setRegenLoading(true);
  //     const ret = await accountAPI.accountRegen({
  //       as_of_date: format(new Date(), "yyyy-MM-dd"),
  //     });
  //   } catch (e) {
  //   } finally {
  //     setRegenLoading(false);
  //   }
  // };


  const RowAction = (item) => {
    if (user?.user_role === "REPORT") return <></>;

    const formItem = {
      ...item,
      scb_connect: item?.scb_connect ?? "Y",
    };

    return (
      <div className="grid grid-cols-2 w-10 space-x-2">
        <img
          className="h-18px w-18px cursor-pointer"
          src={EditIcon}
          onClick={() => {
            setSelectedAccount(formItem);
            setShowModal(true);
            setIsCreate(false);
          }}
        ></img>

        <img
          className="h-18px w-18px cursor-pointer"
          onClick={() => {
            setOpenConfirm(true);
            setSelectedAccount(formItem);
          }}
          src={DeleteIcon}
        ></img>
      </div>
    );
  };

  const refresh = () => {
    setShowModal(false);
    setIsCreate(true);
    setReload(reload + 1);
  };

  const createDataWithStyle = (data = []) => {
    if (!data) return;

    var result = [];
    const borders = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    for (let i = 0; i < data?.length; i++) {
      var filled = { fgColor: { rgb: "FFFFFF" } };
      var filled_col_0 = { patternType: "solid", fgColor: { rgb: "FDFFA7" } };

      let item = data[i].items || {};

      result.push([
        {
          value: `${data[i].parent_name || ""}`,
          style: { font: { sz: 14 }, alignment: "right", fill: filled_col_0 },
        },
        {
          value: data[i]?.customer_rm_no || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value:
            data[i]?.items.map((item) => item?.account_name).join(", ") || "",

          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value:
            data[i]?.items.map((item) => item?.scb_connect).join(", ") || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value:
            data[i]?.items.map((item) => item?.update_user).join(", ") || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value:
            data[i]?.items.map((item) => item?.update_datetime).join(", ") ||
            "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
      ]);
    }
    return result;
  };

  const multiDataSet = [
    {
      columns: [
        {
          value: "PARENT NAME",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "RM NO / CUST REF CODE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "A/C NAME",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "4287f5" } } },
        }, //pixels width
        {
          value: "Connected with SCB",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        }, //char width
        {
          value: "LAST UPDATE DATE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "UPDATED BY",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
      ],
      data: createDataWithStyle(data),
    },
  ];

  const onClickExportExcel = async () => {
    try {
      // const response = await accountAPI.accountExportExcel({
      //     query: ""
      // });

      // let filename = response.headers["content-disposition"]?.replace(
      //     "attachment; filename=", ""
      // );

      const access_token = sessionStorage.getItem("access_token");
      fetch(apiBaseURL + "/v1/parameter/accountgroup/download?query=", {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      });

      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', filename);
      // document.body.appendChild(link);
      // link.click();

      // return;

      // filename.replace(/['"]+/g, "");
      //  downloadFile(response?.data, filename)
      //downloadFile("sd,jfhdskfjh", filename)
    } catch (e) {
      console.log("error", e);
      alert("Unable to export excel");
    }
  };

  return (
    <div className="w-full">{error && <HKError error={error} />}
      <div className="mt-20px p-large w-full">
        <ConfirmModal
          title="Delete A/C Group"
          message="Are you insist to delete ?"
          onConfirm={deleteAccount}
          open={openConfirm}
          setOpen={setOpenConfirm}
          loading={loadingModal}
        />
        <AccountModal
          data={selectedAccount}
          isCreate={isCreate}
          open={showModal}
          action={refresh}
        />
        <RegenAccountModal open={regenModal} action={clear} />
        <div className="p-large flex flex-row justify-end w-full space-x-4">
          <Searchbar
            placeholder="SEARCH RM NO , A/C NAME"
            value={searched}
            onChange={(e) => setSearched(e.target.value)}
          />
          <ExcelFile
            filename={`EXPORT_ACCOUNT
                -GROUP_MANAGMENT_${format(new Date(), "yyyy-MM-dd")}`}
            element={<button id="export-excel-agm" hidden></button>}
          >
            <ExcelSheet
              dataSet={multiDataSet}
              name="Account Group Management"
            ></ExcelSheet>
          </ExcelFile>
          <button>
            <div
              onClick={onClickExportExcel}
              className="flex flex-row text-textscb font-bold text-14px"
            >
              <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
              EXPORT EXCEL
            </div>
          </button>
          {user?.user_role !== "REPORT" && (
            <div className="flex flex-col justify-center pl-5">
              <LoadingButton
                variant="contained"
                loadingPosition="start"
                onClick={() => setRegenModal(true)}
                loading={false}
                disabled={false}
                sx={{ fontSize: "12px", height: "32px" }}
                startIcon={<refreshi />}
              >
                REGEN
              </LoadingButton></div>
          )}

        </div>
        {loading ? (
          <Skeleton count={15} height={30} />
        ) : (
          <CommonTable
            searched={searched}
            headCells={[
              { id: "action", label: "" },
              { id: "parent_name", label: "PARENT NAME" },
              { id: "customer_rm_no", label: "RM NO / CUST REF CODE" },
              { id: "account_name", label: "A/C NAME" },
              { id: "scb_connect", label: "CONNECTED WITH SCB" },
              { id: "update_user", label: "LAST UPDATE DATE" },
              { id: "update_datetime", label: "UPDATED BY" },
            ]}
            rows={dataMap(data)}
            formatControl={[
              RowAction,
              Header,
              ArrayRowRM,
              ArrayRow,
              null,
              formateDateWithTimeHourMintues,
              null,
            ]}
            verticalAlign="top"
          />
        )}
      </div>
    </div>
  );
};

const dataMap = (data = []) => {
  let temp = [];

  for (let i = 0; i < data?.length; i++) {
    temp.push({
      action: data[i],
      parent_name: data[i].parent_name,
      customer_rm_no: data[i].items,
      account_name: data[i].items.map((item) => item.account_name),
      scb_connect: data[i].scb_connect,
      // update_user: data[i].items.map(item => item.update_user),
      // update_datetime: data[i].items.map(item => item.update_datetime),
      update_datetime:
        data[i]?.items.length > 0 && data[i]?.items[0]?.update_datetime,
      update_user: data[i]?.items.length > 0 && data[i]?.items[0]?.update_user,
    });
  }
  return temp;
};

const Header = (item) => {
  return (
    <div className="text-textscb font-bold align-top text-value">{item}</div>
  );
};

const ArrayRow = (data) => {
  return (
    <div>
      <div>
        {data.map((item) => {
          return <div className="pb-large">{item}</div>;
        })}
      </div>
    </div>
  );
};


const ArrayRowRM = (data) => {
  return (
    <div>
      <div>
        {data.map((item) => {
          // let height = "h-[50px]"
          // console.log(item?.account_name + " ",item?.acac?.length)
          // if (item?.account_name?.length > 30) {
          //   height = "h-[70px] 2xl:h-[50px]"
          // }
          // if (item?.account_name?.length > 60) {
          //   height = "h-[90px] 2xl:h-[50px]"
          // }

          return <div className={"pb-large relative text-white select-none"}>
            {item?.account_name}
            <div className="absolute top-0 left-0 text-black select-text">{item?.customer_rm_no}</div>
          </div>;
        })}
        {/* return <div className={"pb-large "}>{item?.customer_rm_no}</div>;
        })} */}
      </div>
    </div>
  );
};



export default AccountGroupMangement;
