import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import React from "react";

const ProtectedRoute = ({ children, can_see = true, ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/login" />
  }

  if (can_see && isLoggedIn) {
    return children;
  }

  return null
};

export default ProtectedRoute;
