import api from "./api.main";
import { apiConfig } from "../config/config.main";

const getUser = async (params, query, headers = {}) => {
  return api.instance.get(apiConfig.getUserProfile, { headers });
};

const exportedObject = {
  getUser,
};

export default exportedObject;
