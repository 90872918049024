import api from "./api.main";
import { apiConfig } from "../config/config.main";
import { last } from "lodash";

const boundInquiry = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.boundInquiry}`, {
    params,
});
};

const boundLatest = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.boundLatest}`, {
    params,
});
};

const boundCreate = async (isLatest,params) => {

  if(isLatest) return api.instance.post(`${apiConfig.boundLatest}`,params,)

  return api.instance.post(`${apiConfig.boundInquiry}`, 
    params,
  );
};


const boundUpdate = async (isLastest,sub_acc,account_name,original_curr,params) => {
  if(isLastest) return api.instance.put(`${apiConfig.boundLatest}/${sub_acc}/${original_curr}`, 
    params)
  return api.instance.put(`${apiConfig.boundInquiry}/${sub_acc}/${original_curr}`, 
    params,
  );
};

const boundDelete = async (isLastest,sub_acc,account_name,original_curr,reportDate) => {
  let params = {
    id: sub_acc,
    report_date: reportDate
  }
  if(!isLastest) return api.instance.put(`${apiConfig.bondDel}`, params)
  return api.instance.put(`${apiConfig.bondDelLatest}`, params);
};

const boundRegen = async (params) => {
  return api.instance.post(`${apiConfig.boundRegen}`, 
    params,
  );
};

const boundDownload = async (date) => {
  // /v1/parameter/bond/download?report_date=yyyy-mm-dd

  // return api.instance.get(`${apiConfig.`);
  return api.instance.get(`/v1/parameter/bond/download?report_date=${date}`)

}

const boundLatestDownload = async () => {
  return api.instance.get(`/v1/parameter/bondlatest/download`);

}




const exportedObject = {
    boundInquiry,
    boundCreate,
    boundUpdate,
    boundDelete,
    boundRegen,
    boundLatest,
    boundDownload,
    boundLatestDownload
};

export default exportedObject;
