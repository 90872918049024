import api from "./api.main";
import { apiConfig } from "../config/config.main";

const getReportInquiry = async (params = {}, query) => {
  return api.instance.get(`${apiConfig.getReportInquiry}${query}`, {
    params,
  });
};
const downloadReport = async (params) => {
  return api.instance.get(`${apiConfig.downloadReport}/${params.id}`, {
  });
};


const exportedObject = {
  getReportInquiry,
  downloadReport
};

export default exportedObject;
