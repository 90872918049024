import { useContext, useEffect, useRef, useState } from "react";
import { ROUTE_BOUND } from "../../constrant/route.constrant";
import { BOUND_DETAIL } from "../../constrant/text.constrant";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Skeleton from "react-loading-skeleton";
import useQuery from "../../hooks/useQuery";
import { Button, TextField } from "@mui/material";
import { RootContext } from "../../context/root.context";
import boundAPI from "../../api/boundBaseAPI";
import { BoundTable } from "../../components/table/boundTable";
import { addCommasToString, formatNumberComma, formatNumberCommaTwoDigit, formatPercentFourDigits } from "../../utils/util";
import {
  formateDate,
  formateDateTime,
  formateDateWithTimeHourMintues,
  isValidDate,
} from "../../utils/util.main";
import EditIcon from "../../assets/icon/edit.svg";
import DeleteIcon from "../../assets/icon/remove.svg";
import { DRAWER_WIDTH } from "../../constrant/style.constrant";
import BoundModal from "../../components/modal/boundModal";
import ConfirmModal from "../../components/modal/confirmModal";
import "react-loading-skeleton/dist/skeleton.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import Download from "../../assets/icon/download_scb.svg";
import { LoadingButton } from "@mui/lab";
import ReactExport from "react-export-excel-xlsx-fix";
import HKError from "../../components/error/alertError";
import { useSelector } from "react-redux";
import { OptionRadio } from "../../components/Input/yesnoRadio";
import moment from "moment";
import { apiBaseURL } from "../../config/config.main";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Bound = () => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const [dateValue, setDateValue] = useState(date);
  const [viewType, setViewType] = useState("Latest Data");
  const { setRouteName, routeName, showModal, setShowModal } =
    useContext(RootContext);
  const [reload, setReload] = useState(0);
  const [errorState, setErrorState] = useState([])
  const [errorOpen, setErrorOpen] = useState(false);
  const { data, loading, error } = useQuery(
    viewType === "Latest Data"
      ? boundAPI.boundLatest
      : boundAPI.boundInquiry,
    {
      // ...(viewType !== "Latest Data" && {
      report_date:
        dateValue instanceof Date && !isNaN(dateValue)
          ? format(new Date(dateValue), "yyyy-MM-dd")
          : "",
      // }),
    },
    {},
    [dateValue, reload],
    {
      // checkFirstLoad: viewType === "Latest Data" ||
      //   (dateValue instanceof Date && !isNaN(dateValue) ? true : false),
      checkFirstLoad: true,
    }
  );



  const [selectedBound, setSelectedBound] = useState({});
  const [isCreate, setIsCreate] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalloading, setLoading] = useState(false);
  const [regenLoading, setRegenLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const changeViewType = (e) => {
    setViewType(e.target.value);
    setReload(reload + 1);

  };

  const RowAction = (item) => {
    if (user?.user_role === "REPORT") return <></>;

    return (
      <div className="grid grid-cols-2 w-10 space-x-2">

        <img
          className="h-18px w-18px cursor-pointer"
          src={EditIcon}
          onClick={() => {
            setSelectedBound(item);
            setShowModal(true);
            setIsCreate(false);
          }}
        ></img>

        <img
          className="h-18px w-18px cursor-pointer"
          src={DeleteIcon}
          onClick={() => {
            // setSelectedBound(dataMap(data)[0])
            setSelectedBound(item);
            setOpenConfirm(true);
          }}
        ></img>
      </div>
    );
  };

  const deleteBound = async () => {
    try {
      setLoading(true);

      const ret = await boundAPI.boundDelete(
        viewType === "Latest Data",
        selectedBound.sub_acc,
        selectedBound.account_name,
        selectedBound.original_curr,
        dateValue instanceof Date && !isNaN(dateValue)
          ? format(new Date(dateValue), "yyyy-MM-dd")
          : ""
      );
      setOpenConfirm(false);
      setReload(reload + 1);
    } catch (e) {
      setErrorState([{ message: e.response.data?.status.description }]);
    } finally {
      setLoading(false);
    }
  };

  const regen = async () => {
    try {
      setRegenLoading(true);
      const ret = await boundAPI.boundRegen({
        report_date: format(new Date(dateValue), "yyyy-MM-dd"),
      });
    } catch (e) {
      setErrorState({ message: e?.response.data?.status?.description })
    } finally {
      setRegenLoading(false);
    }


  };

  const regenValidate = () => {
    const asOfdate = moment(dateValue).format("YYYY-MM-DD");
    const invalid = (asOfdate.toString() === "Invalid date") || asOfdate.toString().startsWith("0")
    if (invalid) {
      setErrorOpen(true);
      setErrorState({ message: "As of date is required" });
    }
    else {
      setErrorOpen(false);
      regen();
    }
  }

  useEffect(() => {
    setRouteName([
      { title: "PARAMETER", path: "/" },
      { title: BOUND_DETAIL, path: ROUTE_BOUND },
    ]);
    setShowModal(false);
  }, []);

  const getTotal = () => {
    let os_original = 0,
      disc_original = 0,
      gain_original = 0,
      acct_original = 0,
      provision_original = 0;
    for (let i = 0; i < data?.length; i++) {
      os_original += data[i].os_amount_original;
      disc_original += data[i].disc_os_amt_original;
      gain_original += data[i].gain_loss_os_amt_original;
      acct_original += data[i].accr_int_os_amt_original;
      provision_original += data[i].provision_amt_hkd;
    }
    return [os_original, disc_original, gain_original, acct_original, provision_original];
  };

  const createDataWithStyle = (data = []) => {
    if (!data) return;

    var result = [];
    const borders = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    for (let i = 0; i < data?.length; i++) {
      var filled = { fgColor: { rgb: "FFFFFF" } };
      var filled_col_0 = { patternType: "solid", fgColor: { rgb: "FDFFA7" } };

      let item = data[i].items || {};

      result.push([
        {
          value: `${data[i]?.sub_acc || ""}`,
          style: { font: { sz: 14 }, alignment: "right", fill: filled_col_0 },
        },
        {
          value: data[i]?.account_name || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.rm_no || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.original_curr || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.bond_item || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.gl_acct || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.gl_prod_code || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.os_amount_original?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.purchase_amt_original?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.coupon_rate != null
            ? data[i]?.coupon_rate.toFixed(4) + "%" : "0.0000%",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },

        },

        {
          value: formateDate(data[i]?.trade_dt) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: formateDate(data[i]?.value_dt) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: formateDate(data[i]?.next_roll_dt) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: formateDate(data[i]?.maturity_dt) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.country_code || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.disc_item || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.disc_gl_acct || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.disc_os_amt_original?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.gain_loss_item || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.gain_loss_gl_acct || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.gain_loss_os_amt_original?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.accr_int_item || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: data[i]?.accr_int_gl_acct || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.accr_int_os_amt_original?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.provision_gl_acct || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: addCommasToString(data[i]?.provision_amt_hkd?.toFixed(2)) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },

        {
          value: data[i]?.geographical_cn || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.country_usage || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.cn_related || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.general_specific || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.credit_rating || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.hkma_rating || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.int_bearing_flag || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.fix_var || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },
        {
          value: data[i]?.cust_ind || "",
          style: { font: { sz: 14 }, alignment: "right" },
        },

        {
          value: formateDateTime(data[i]?.update_datetime) || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
        {
          value: `${data[i]?.update_user}` || "",
          style: {
            font: { sz: 14 },
            numFmt: "0,0.00",
            border: borders,
            alignment: "right",
            fill: filled,
          },
        },
      ]);
    }
    return result;
  };

  const multiDataSet = [
    {
      columns: [
        {
          value: "ISIN CODE/REF. CODE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "A/C NAME",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "RM NO",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "8B7243" } } },
        }, //pixels width
        {
          value: "ORIGINAL CURR",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        }, //char width
        {
          value: "ITEM",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GL/ ACCT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GL PROD CODE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "AMOUNT [OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PURCHASE AMOUNT [OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "COUPON RATE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "TRADE DT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "VALUE DT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "NEXT ROLL DT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "MATURITY DT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "COUNTRY CODE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },

        {
          value: "PREMIUM/DISCOUNT ITEM",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PREMIUM/DISCOUNT GL/ACCT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PREMIUM/DISCOUNT AMOUNT[OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },

        {
          value: "GAIN/LOSS ITEM",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GAIN/LOSS GL/ACCT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GAIN/LOSS AMOUNT[OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "ACCRU INTEREST ITEM",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "ACCRU INTEREST G/L ACCT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "ACCRU INTEREST AMOUNT[OS ORIGINAL]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PROVISION GL/ACCT",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "PROVISION AMT [HKD]",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GEOGRAPHICAL IN CN",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "COUNTRY USAGE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "CN RELATED",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "GENERAL/SPECIFIC",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "CREDIT RATING",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "HKMA RATING",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "INT BEARING FLAG",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "FIX/VAR",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "CUST IND",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },

        {
          value: "LATEST - DATE",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
        {
          value: "UPDATED - BY",
          widthPx: 150,
          style: { fill: { patternType: "solid", fgColor: { rgb: "FDFFA7" } } },
        },
      ],
      data: createDataWithStyle(data),
    },
  ];

  const onClickExportExcel = async () => {
    let uri = "/v1/parameter/bondlatest/download";
    if (viewType === "Data as of Date") {
      const reportDate = format(new Date(dateValue), "yyyy-MM-dd");
      uri = `/v1/parameter/bond/download?report_date=${reportDate}`;
    }

    try {
      const access_token = sessionStorage.getItem("access_token");
      const url = apiBaseURL + uri;
      fetch(url, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
    } catch (e) {
      console.log("error", e);
      alert("Unable to export excel");
    }
  };


  return (
    <div className="w-full">
      {errorOpen ? <HKError error={errorState} title="ValidationError" /> : 
      (error || errorState?.message) && <HKError error={error || errorState} />}
      <div className="mt-20px p-large w-5/6 ">
        <ConfirmModal
          title="Delete Bond"
          message="Are you insist to delete ?"
          onConfirm={deleteBound}
          open={openConfirm}
          setOpen={setOpenConfirm}
          loading={modalloading}
        />
        <BoundModal
          data={selectedBound}
          open={showModal}
          isLatest={viewType === "Latest Data"}
          isCreate={isCreate}
          reportDate={
            dateValue instanceof Date && !isNaN(dateValue)
              ? format(new Date(dateValue), "yyyy-MM-dd")
              : ""
          }
          action={() => {
            setShowModal(false);
            setIsCreate(true);
            setReload(reload + 1);
          }}
        />

        <div className="flex flex-row justify-center  p-large uppercase pb-0">
          <OptionRadio
            label=""
            value={viewType}
            values={["Latest Data", "Data as of Date"]}
            handleChange={changeViewType}
          />
          {viewType === "Data as of Date" ? (
            <div className="flex flex-row mt-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  //    views={['day', 'month', 'year']}
                  value={dateValue}
                  onChange={setDateValue}
                  sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 16, color: "#7A58BF" },
                      }}
                      variant="standard"
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              <div className="w-4"></div>
              <ExcelFile
                filename={`EXPORT_BOND_${dateValue instanceof Date && !isNaN(dateValue)
                  ? format(new Date(dateValue), "yyyy-MM-dd")
                  : ""
                  }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
                element={<button id="export-excel-bond" hidden></button>}
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name="Bond Detail"
                ></ExcelSheet>
              </ExcelFile>
              <button onClick={onClickExportExcel} className="mx-5">
                <div className="flex flex-row text-textscb font-bold text-14px">
                  <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                  EXPORT EXCEL
                </div>
              </button>
              {user?.user_role !== "REPORT" && (
                <LoadingButton
                  variant="contained"
                  loadingPosition="start"
                  onClick={regenValidate}
                  loading={regenLoading}
                  disabled={regenLoading}
                  sx={{ fontSize: "12px", height: "32px" }}
                  startIcon={<RefreshIcon />}
                >
                  REGEN
                </LoadingButton>
              )}
            </div>
          ) : (
            <div className="mt-[10px]">
              <ExcelFile
                filename={`EXPORT_BOND_${dateValue instanceof Date && !isNaN(dateValue)
                  ? format(new Date(dateValue), "yyyy-MM-dd")
                  : ""
                  }_${format(new Date(), "yyyyMMdd_HHmmss")}`}
                element={<button id="export-excel-bond" hidden></button>}
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name="Bond Detail"
                ></ExcelSheet>
              </ExcelFile>
              <button onClick={onClickExportExcel} className="mx-5">
                <div className="flex flex-row text-textscb font-bold text-14px">
                  <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
                  EXPORT EXCEL
                </div>
              </button></div>)}
        </div>
        {/* <div className="flex flex-row justify-end  p-large w-scbscreen space-x-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date desktop"
            //    views={['day', 'month', 'year']}
            inputFormat="dd/MM/yyyy"
            value={dateValue}
            onChange={setDateValue}
            sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 16, color: "#7A58BF" },
                }}
                variant="standard"
                label="Date"
                error={false}
              />
            )}
          />
        </LocalizationProvider>
        <ExcelFile
          filename={`EXPORT_BOUND_${format(
            new Date(dateValue?.length === 10 ? dateValue : "02-02-2022"),
            "yyyy-MM-dd"
          )}`}
          element={<button id="export-excel-bond" hidden></button>}
        >
          <ExcelSheet dataSet={multiDataSet} name="Bond Detail"></ExcelSheet>
        </ExcelFile>
        <button onClick={onClickExportExcel}>
          <div className="flex flex-row text-textscb font-bold text-14px">
            <img src={Download} className="mr-10px h-16px w-16px mt-3px" />
            EXPORT EXCEL
          </div>
        </button>
        {user?.user_role !== "REPORT" && (
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            onClick={regen}
            loading={regenLoading}
            disabled={regenLoading}
            sx={{ fontSize: "14px" }}
            startIcon={<RefreshIcon />}
          >
            REGEN
          </LoadingButton>
        )}
      </div> */}
        {loading ? (
          <div className="p-fourteen">
            <Skeleton
              containerTestId="react-loading-skeleton"
              height={30}
              width={`100%`}
              count={15}
            />
          </div>
        ) : (
          <div>
            <BoundTable
              totals={getTotal()}
              rows={dataMap(data)}
              formatControl={[
                RowAction,
                TextScb,
                null,
                null,/// rm
                null,
                null,
                null,
                null,
                formatNumberCommaTwoDigit,
                formatNumberCommaTwoDigit,
                formatPercentFourDigits, /// coupon
                formateDate,
                formateDate,
                formateDate,
                formateDate,
                null,
                null,
                null,
                formatNumberCommaTwoDigit,
                null,
                null,
                formatNumberCommaTwoDigit,
                null,
                null,
                formatNumberCommaTwoDigit,
                null, /// geographical
                formatNumberCommaTwoDigit,
                null, ///country usage
                null, /// cn related
                null,  /// general spec
                null,  /// credit rating
                null,  /// hkma rating
                null,  // int bearing
                null, // fix var
                null, // cust ind
                formatNumberComma,
                formateDateWithTimeHourMintues,
                null,
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const TextScb = (text) => {
  return <div className="text-textscb">{text}</div>;
};

const dataMap = (data = []) => {
  let temp = [];

  for (let i = 0; i < data?.length; i++) {
    temp.push({
      action: data[i],
      sub_acc: data[i].sub_acc,
      account_name: data[i].account_name,
      rm_no: data[i]?.rm_no || "-",
      original_curr: data[i].original_curr,
      bound_item: data[i].bond_item,
      provision_gl_acct: data[i].gl_acct,
      gl_prode_code: data[i].gl_prod_code,
      os_amount_original: data[i].os_amount_original,
      purchase_amt_original: data[i].purchase_amt_original,
      coupon_rate: data[i]?.coupon_rate,
      trade_dt: data[i].trade_dt || null,
      value_dt: data[i].value_dt || null,
      next_roll_dt: data[i].next_roll_dt,
      maturity_dt: data[i].maturity_dt,
      country_code: data[i].country_code,

      disc_item: data[i].disc_item,
      disc_gl_acct: data[i].disc_gl_acct,
      disc_os_amt_original: data[i].disc_os_amt_original,
      // gain loss
      gain_loss_item: data[i]?.gain_loss_item,
      gain_loss_acct: data[i].gain_loss_gl_acct,
      gain_loss_os_amt_original: data[i].gain_loss_os_amt_original,

      accr_int_item: data[i]?.accr_int_item,
      accr_int_gl_acct: data[i].accr_int_gl_acct,
      accr_int_original: data[i].accr_int_os_amt_original,

      provision_amt_acct: data[i].provision_gl_acct,
      provision_amt_hkd: data[i].provision_amt_hkd,

      geographical_cn: data[i]?.geographical_cn,
      country_usage: data[i]?.country_usage,
      cn_related: data[i]?.cn_related,
      general_specific: data[i]?.general_specific,
      credit_rating: data[i]?.credit_rating,
      hkma_rating: data[i]?.hkma_rating,
      int_bearing_flag: data[i]?.int_bearing_flag,
      fix_var: data[i]?.fix_var,
      cust_ind: data[i]?.cust_ind,
      update_datetime: data[i].update_datetime,
      update_user: data[i].update_user,
    });
  }
  return temp;

};

export default Bound;
