
import PlusIcon from '../../assets/icon/plus.svg';
export const FooterButton = ({ text, action = null, disabled = false,icon=PlusIcon }) => {
  return (
    <div
      className={
        "flex justify-end pt-fourteen text-13px  font-bold text-textscb "}
    >
      <button disabled={disabled} onClick={action}>
        <div className='flex flex-row space-x-4'>
          <img src={icon} className="w-18px h-18px mr-10px"></img>
          {text}
        </div>

      </button>
    </div>
  );
};


