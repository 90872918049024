import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Box from "@mui/material/Box";
import AuthRoute from "./authRoute";
import ReportRoute from "./reportRoute";
import Layout from "../components/layout/layout";
import { ROUTE_CAPITALBASE } from "../constrant/route.constrant";
import CapitalBaseRoute from "./capitalBaseRoute";
import BoundRoute from "./boundRoute";
import AccountRoute from "./accountRoute";
import LoanRoute from "./loanRoute";
import NostroRoute from "./nostroRoute";
import { useSelector } from "react-redux";

function RoutesMain() {

  return (
    <Router>
      <AuthRoute />
      <Box sx={{ display: "flex", boxShadow: 0 }}>
        <Layout />
        <React.Fragment>
          <ReportRoute/>
          <CapitalBaseRoute />
          <BoundRoute />
          <AccountRoute />
          <LoanRoute />
          <NostroRoute />
        </React.Fragment>
      </Box>
    </Router>
  );
}

export default RoutesMain;
