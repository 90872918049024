import { Route, Routes } from "react-router-dom";
import { ROUTE_ACCOUNT, ROUTE_BOUND } from "../constrant/route.constrant";
import AccountGroupMangement from "../pages/account group managment";
import ProtectedRoute from "./protectedRoute";
import React from "react"
import { useSelector } from "react-redux";

function AccountRoute() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Routes>
      <Route path={ROUTE_ACCOUNT} element={<ProtectedRoute can_see={user?.user_role === "MANAGEMENT"||user?.user_role === "REPORT"}><AccountGroupMangement /></ProtectedRoute>} />
    </Routes>
  );
}

export default AccountRoute;