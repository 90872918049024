import { useContext, useEffect, useRef, useState } from "react";
import { CAPITAL_BASE } from "../../constrant/text.constrant";
import { RootContext } from "../../context/root.context";
import CapitalBaseRoute from "../../routes/capitalBaseRoute";
import { DateRangePicker } from "../../components/Input/dateRangePicker";
import { format } from "date-fns";
import { CommonTable } from "../../components/table/commonTable";
import capitalBaseApi from "../../api/capitalBaseAPI";
import useQuery from "../../hooks/useQuery";
import {
  CapitalbaseColumnUpdate,
  formateDate,
  formateDateWithTimeHourMintues,
  isValidDate,
} from "../../utils/util.main";
import EditIcon from "../../assets/icon/edit.svg";
import DeleteIcon from "../../assets/icon/remove.svg";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import {
  formatNumberComma,
  formatNumberCommaTwoDigit,
  getLastThreeQuater,
  getLastThreeQuaterBegin,
  getLastThreeQuaterEnd,
} from "../../utils/util";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ConfirmModal from "../../components/modal/confirmModal";
import HKError from "../../components/error/alertError";
import CapitalBaseModal from "../../components/modal/capitalBaseModal";
import { useSelector } from "react-redux";

const CapitalBase = ({}) => {
  const { setRouteName, routeName, showModal, setShowModal } =
    useContext(RootContext);
  var date = new Date();
  const [fromDate, setFromDate] = useState(getLastThreeQuaterBegin());
  const [toDate, setToDate] = useState(getLastThreeQuaterEnd());
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedCapitalBase, setSelectedCapitalBase] = useState();
  const [reload, setReload] = useState(0);
  const [isCreate, setIsCreate] = useState(true);
  const { data, loading, error } = useQuery(
    capitalBaseApi.capitalBaseList,
    {
      // from_date: "2020-12-31",
      // to_date: "2022-12-31",
      from_date:
        fromDate instanceof Date && !isNaN(fromDate)
          ? format(new Date(fromDate), "yyyy-MM-dd")
          : "",
      to_date:
        toDate instanceof Date && !isNaN(toDate)
          ? format(new Date(toDate), "yyyy-MM-dd")
          : "",
          // to_date:"srdgsfd"
    },
    {},
    [fromDate, toDate, reload],
    { checkFirstLoad: isValidDate(fromDate) && isValidDate(toDate) }
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setRouteName([
      { title: "PARAMETER", path: "/" },
      { title: CAPITAL_BASE, path: CapitalBaseRoute },
    ]);
    setShowModal(false);
  }, []);

  const RowAction = (item) => {
    if (user?.user_role === "REPORT") return <></>;

    return (
      <div className="grid grid-cols-2">
        <img
          className="h-18px w-18px cursor-pointer"
          onClick={() => {
            setShowModal(true);
            setIsCreate(false);
            setSelectedCapitalBase(item);
          }}
          src={EditIcon}
        ></img>
        <img
          className="h-18px w-18px cursor-pointer"
          onClick={() => {
            setOpenConfirm(true);
            setSelectedCapitalBase(item);
          }}
          src={DeleteIcon}
        ></img>
      </div>
    );
  };

  const deleteCapitalBase = async () => {
    try {
      setLoadingModal(true);
      const res = await capitalBaseApi.capitalBaseDelete(
        selectedCapitalBase.as_of_date
      );
      if (res) {
        setLoadingModal(false);
        setOpenConfirm(false);
        setReload(reload + 1);
      }
    } catch (e) {
      setLoadingModal(false);
    }
  };

  const clear = () => {
    setShowModal(false);
    setIsCreate(true);
    setReload(reload + 1);
  };


  return (
    <div className="w-full">
    {error && <HKError error={error} />}
    <div className="mt-20px p-large w-full">
      <ConfirmModal
        title="Delete Capital Base"
        message="Are you insist to delete ?"
        onConfirm={deleteCapitalBase}
        open={openConfirm}
        setOpen={setOpenConfirm}
        loading={loadingModal}
      />
      <CapitalBaseModal
        open={showModal}
        data={selectedCapitalBase}
        action={clear}
        isCreate={isCreate}
      />
      <div className="p-large flex flex-row justify-end w-full space-x-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            //     views={['day', 'month', 'year']}
            label="Date desktop"
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={setFromDate}
            sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 16, color: "#7A58BF" },
                }}
                variant="standard"
                label="FROM"
                error={false}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            //  views={['day', 'month', 'year']}
            label="Date desktop"
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={setToDate}
            sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 16, color: "#7A58BF" },
                }}
                variant="standard"
                label="TO"
                error={false}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={15}
          />
        </div>
      ) : (
        <CommonTable
          headCells={[
            { id: "item", label: "" },
            { id: "as_of_date", label: "AS OF DATE" },
            { id: "amount", label: "AMOUNT (HKD)" },
            { id: "update_datetime", label: "LAST UPDATE DATE" },
            { id: "update_user", label: "UPDATED BY" },
            { id: "regen", label: "" },
          ]}
          orderByDefault="as_of_date"
          disabledSort={false}
          orderDefault="desc"
          shouldAutoSort={false}
          formatControl={[
            RowAction,
            formateDate,
            formatNumberCommaTwoDigit,
            formateDateWithTimeHourMintues,
            CapitalbaseColumnUpdate,
          ]}
          rows={dataMapCapitalBase(data)}
        />
      )}
    </div>
    </div>
  );
};

const dataMapCapitalBase = (data = []) => {
  if (data?.length > 0) {
    return (
      data
        // ?.sort((a,b)=>{
        //     return a.as_of_date < b.as_of_date ? -1 :1

        // })
        .map((item, index) => {
          return {
            item: {
              as_of_date: item.as_of_date,
              amount: item.capital_base,
            },
            as_of_date: item.as_of_date,
            amount: item.capital_base,
            update_datetime: item.update_datetime,
            update_user: item,
          };
        })
    );
  }
  return [];
};

export default CapitalBase;
