import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ListSubheader } from "@mui/material";

export const CommonDropdown = ({
  label = "input",
  options = ["VALUE", "VALUE2"],
  error = false,
  disabled = false,
  value,
  onChange,
  fullWidth = true,
  width = 0
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ color: "#7A58BF", fontSize: 16, width: width > 0 ? width : "100%" }}
        label={label}
        value={value}
        onChange={onChange}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const RMDropdown = ({
  label = "input",
  options,
  error = false,
  disabled = false,
  value,
  onChange,
  fullWidth = true,
  width = 0
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ color: "#7A58BF", fontSize: 16, width: width > 0 ? width : "100%" }}
        label={label}
        value={value.customer_rm_no}
        onChange={onChange}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.rm_no}>
              {option?.rm_no}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const GeographicalDropDown = ({
  label = "Geographical in CN",
  error = false,
  disabled = false,
  value,
  onChange,
  fullWidth = true,
  width = 0
}) => {

  return (
    <FormControl fullWidth={fullWidth} variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ color: "#7A58BF",fontSize: 16}}
        //    sx={{ color: "#7A58BF", fontSize: 16, width: width > 0 ? width : "100%" }}
        label={label}
        value={value}
        onChange={onChange}
      >
        <MenuItem value={"NONE"}>NONE</MenuItem>
        <ListSubheader sx={{color:"#7A58BF"}}>Eastern region</ListSubheader>
        <MenuItem value={"Beijing"}>Beijing</MenuItem>
        <MenuItem value={"Tianjin"}>Tianjin</MenuItem>
        <MenuItem value={"Hebei"}>Hebei</MenuItem>
        <MenuItem value={"Liaoning"}>Liaoning</MenuItem>
        <MenuItem value={"Shanghai"}>Shanghai</MenuItem>
        <MenuItem value={"Jiangsu"}>Jiangsu</MenuItem>
        <MenuItem value={"Zhejiang"}>Zhejiang</MenuItem>
        <MenuItem value={"Fujian"}>Fujian</MenuItem>
        <MenuItem value={"Shandong"}>Shandong</MenuItem>
        <MenuItem value={"Guangdong"}>Guangdong</MenuItem>
        <MenuItem value={"Hainan"}>Hainan</MenuItem>

        <ListSubheader sx={{color:"#7A58BF"}}>Central region</ListSubheader>
        <MenuItem value={"Shanxi"}>Shanxi</MenuItem>
        <MenuItem value={"Jilin"}>Jilin</MenuItem>
        <MenuItem value={"Heilongjiang"}>Heilongjiang</MenuItem>
        <MenuItem value={"Anhui"}>Anhui</MenuItem>
        <MenuItem value={"Jiangxi"}>Jiangxi</MenuItem>
        <MenuItem value={"Henan"}>Henan</MenuItem>
        <MenuItem value={"Hubei"}>Hubei</MenuItem>
        <MenuItem value={"Hunan"}>Hunan</MenuItem>

        <ListSubheader sx={{color:"#7A58BF"}}>Western region</ListSubheader>
        <MenuItem value={"Chongqing"}>Chongqing</MenuItem>
        <MenuItem value={"Sichuan"}>Sichuan</MenuItem>
        <MenuItem value={"Guizhou"}>Guizhou</MenuItem>
        <MenuItem value={"Yunnan"}>Yunnan</MenuItem>
        <MenuItem value={"Tibet"}>Tibet</MenuItem>
        <MenuItem value={"Shaanxi"}>Shaanxi</MenuItem>
        <MenuItem value={"Gansu"}>Gansu</MenuItem>
        <MenuItem value={"Qinghai"}>Qinghai</MenuItem>
        <MenuItem value={"Ningxia"}>Ningxia</MenuItem>
        <MenuItem value={"Xinjiang"}>Xinjiang</MenuItem>
        <MenuItem value={"Guangxi"}>Guangxi</MenuItem>
        <MenuItem value={"Inner Mongolia"}>Inner Mongolia</MenuItem>
        <MenuItem value={"Other locations in Mainland China"}>Other locations in Mainland China</MenuItem>
        <MenuItem value={"Location outside Mainland China"}>Location outside Mainland China</MenuItem>
      </Select>
    </FormControl>
  )
}

export const EconomicSectorDropdown = ({
  label = "Economic Sector",
  error = false,
  disabled = false,
  value,
  onChange,
  fullWidth = true,
}) => {

  return (
    <FormControl fullWidth={fullWidth} variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ color: "#7A58BF", fontSize: 16}}

        //    sx={{ color: "#7A58BF", fontSize: 16, width: width > 0 ? width : "100%" }}
        label={label}
        value={value}
        onChange={onChange}
      >
        <MenuItem value={"NONE"}>NONE</MenuItem>
        <ListSubheader sx={{color:"#7A58BF"}}>A.Manufacturing</ListSubheader>
        <MenuItem value={"A1a"}>A1a. cotton</MenuItem>
        <MenuItem value={"A1b"}>A1b. others</MenuItem>

        <MenuItem value={"A2"}>A2. Footware & wearing apparel</MenuItem>
        <MenuItem value={"A3"}>A3. Metal products & engineering</MenuItem>
        <MenuItem value={"A4"}>A4. Rubber,plastic & chemicals</MenuItem>
        <MenuItem value={"A5a"}>A5a. telecommunication equipment</MenuItem>
        <MenuItem value={"A5b"}>A5b. others</MenuItem>

        <MenuItem value={"A6"}>A6. Food</MenuItem>
        <MenuItem value={"A7"}>A7. Beverages & Tobacco</MenuItem>
        <MenuItem value={"A8"}>A8. Printing & publishing</MenuItem>
        <MenuItem value={"A9"}>A9. Miscellaneous</MenuItem>

        <ListSubheader sx={{color:"#7A58BF"}}>B. Building and construction, property development and investment</ListSubheader>
        <MenuItem value={"B1a"}>B1a. industrial</MenuItem>
        <MenuItem value={"B1b"}>B1b. residential</MenuItem>
        <MenuItem value={"B1c"}>B1c. commercial</MenuItem>
        <MenuItem value={"B1d"}>B1d. other</MenuItem>
        <MenuItem value={"B2a"}>B2a. industrial</MenuItem>
        <MenuItem value={"B2b"}>B2b. residential</MenuItem>
        <MenuItem value={"B2c"}>B2c. commercial</MenuItem>
        <MenuItem value={"B2d"}>B2d. Others</MenuItem>
        <MenuItem value={"B3"}>B3. Civil engineering works</MenuItem>
        <MenuItem value={"C"}>C. Electricity and gas</MenuItem>
        <MenuItem value={"D"}>D. Recreational activities</MenuItem>

        <ListSubheader sx={{color:"#7A58BF"}}>E. Information technology</ListSubheader>
        <MenuItem value={"E1"}>E1. Telecommunications</MenuItem>
        <MenuItem value={"E2"}>E2. Others</MenuItem>
        <MenuItem value={"F"}>F. Wholesale and retail trade</MenuItem>
        <ListSubheader sx={{color:"#7A58BF"}}>G. Transport and transport equipment</ListSubheader>
        <MenuItem value={"G1"}>G1. Shipping</MenuItem>
        <MenuItem value={"G2"}>G2. Air transport</MenuItem>
        <MenuItem value={"G3"}>G3. Taxis</MenuItem>
        <MenuItem value={"G4"}>G4. Public light buses</MenuItem>
        <MenuItem value={"G5"}>G5. Others</MenuItem>

        <ListSubheader sx={{color:"#7A58BF"}}>H. Miscellanceous</ListSubheader>
        <MenuItem value={"H1"}>H1. Hotels,boarding houses & catering</MenuItem>
        <MenuItem value={"H2a"}>H2a. investment companies</MenuItem>
        <MenuItem value={"H2b"}>H2b. insurance companies</MenuItem>
        <MenuItem value={"H2c"}>H2c. futures brokers</MenuItem>
        <MenuItem value={"H2d"}>H2d. finance companies & other</MenuItem>
        <MenuItem value={"H3a"}>H3a. margin lending</MenuItem>
        <MenuItem value={"H3b"}>H3b. others</MenuItem>
        <MenuItem value={"H4a"}>H4a. margin lending</MenuItem>
        <MenuItem value={"H4b"}>H4b. others</MenuItem>
        <MenuItem value={"H5a"}>H5a. for the purchase of flats covered by the guarantee issued by the Housing Authority under Hom Ownership Scheme, Private Sector Participation Scheme & Tenants Purchase Scheme</MenuItem>
        <MenuItem value={"H5b"}>H5b. for the purchase of other residential properties</MenuItem>
        <MenuItem value={"H5c"}>H5c. for credit card advances</MenuItem>
        <MenuItem value={"H5d"}>H5d. for other business purposes</MenuItem>
        <MenuItem value={"H5e"}>H5e. for other private purposes</MenuItem>
        <MenuItem value={"H6"}>H6. All Other</MenuItem>
        <MenuItem value={"J"}>J. TRADE FINANCING</MenuItem>
        <MenuItem value={"K"}>K. OTHER LOANS & ADVANCES</MenuItem>
      </Select>
    </FormControl>
  )
}