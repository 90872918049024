export const ERROR_CODE = 400;
export const SUCCESS_CODE = 200;
export const ROW_PER_PAGE = 5;
export const GRAY_100 = "#F5F5F5";
export const FEE_TABLE_TYPE = { Report: 0, Processing: 1, Fee: 2 };
export const DRAWER = 200;
export const DRAWER_WIDTH = `calc(100% - ${DRAWER}px)`;
export const HOVER_COLOR = "rgba(122, 88, 191, 0.15)";
export const ACTIVE_COLOR = "rgba(122, 88, 191, 0.15)";
export const STEP = "STEP";
export const MIN_VALUE = 0;
export const MAX_VALUE = 99999;
export const MILD_PURPLE = "#7A58BF";
export const TEXT_PRIMARY = "#000000DE";
export const TEXT_SECONDARY = "#00000099";
export const SMALL_FONT_SIZE = "10px";
export const NORMAL_FONT_SIZE = "14px";
export const BORDER_COLOR = "#E5E5E5";
export const WHITE_BORDER = {
  borderTop: "4px solid white",
  borderBottom: "2px solid #F5F5F5",
};

export const WHITE_BORDER_NOPADDING = {
  borderTop: "4px solid white",
  borderBottom: "2px solid #F5F5F5",
  padding: 1,
  height: "54px",
};

export const WHITE_BORDER_NOPADDING_W_35 = {
  borderTop: "4px solid white",
  borderBottom: "2px solid #F5F5F5",
  padding: 1,
  height: "54px",
  width: "35px",
};

export const GRAY_BORDER_INSIDE = "2px solid #F5F5F5";
export const WHITE_BORDER_INSIDE = "4px solid white";

export const UNDERLINE = "2px solid lightGray";

///// Overide Material UI Section

export const TABLE_STYLE = {
  "& .MuiTableCell-head": {
    fontWeight: 700,
    fontSize: NORMAL_FONT_SIZE,
    paddingLeft: 1,
    paddingRight: 0,
  },
  "& .MuiTableCell-body": {
    fontSize: NORMAL_FONT_SIZE,
    paddingLeft: 1,
    paddingRight: 0,
  },
};

export const TABLE_STYLE_CHARGE = {
  "& .MuiTableCell-head": {
    fontWeight: 700,
    fontSize: NORMAL_FONT_SIZE,
    paddingLeft: 1,
    paddingRight: 0,
  },
  "& .MuiTableCell-body": {
    paddingLeft: 1,
    paddingRight: 0,
  },
};

export const SELECTION_WITDH = { width: "320px" };
export const TABLE_STYLE_WITH_HOVER = {
  width: "320px",
  "& .MuiTableRow-root:hover": {
    cursor: "pointer",
  },
};

export const TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER = {
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#7A58BF70 !important",
    cursor: "pointer",
  },
  "&:last-child td, &:last-child th": { border: 0 },
};
export const TABLE_STYLE_WITH_HOVER_STANDARD_WITDH = {
  minWidth: 650,
  "& .MuiTableRow-root:hover": {
    cursor: "pointer",
  },
};

export const TABLE_STYLE_WITH_HOVER_STANDARD_RADIO_WITDH = {
  minWidth: 450,
  "& .MuiTableRow-root:hover": {
    cursor: "pointer",
  },
};

export const TABLE_STYLE_WITHOUT_BOTTOM_BORDER = {
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#7A58BF70 !important",
  },
  "&:last-child td, &:last-child th": { border: 0 },
};
export const TABLE_STYLESTANDARD_WITDH = {
  minWidth: 650,
  "& .MuiTableRow-root:hover": {},
};

export const TABLE_STYLE_STANDARD_WITDH = {
  minWidth: 650,
  "& .MuiTableRow-root:hover": {},
};


export const BOUND_CELL_STANDARD = {
  fontWeight:"bold",fontSize:"14px",paddingTop:"0px",verticalAlign:"top",
  position: 'sticky',
  top: 0,
  backgroundColor:"#fff"
}

export const BOND_HEADER_GROUP = {
  border: "0",
  fontWeight: "bold",
  textAlign:"center",
  position: 'sticky',
  verticalAlign:"top",
  top: 0,
  backgroundColor:"#fff",
  paddingBottom:"0"
}

export const DEFAULT_HEADER_STYLE = {
  fontWeight:"bold",
  position: 'sticky',
  textAlign: "center",
  verticalAlign:"top",
  top: 0,
  backgroundColor:"#fff"
}