import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import RoutesMain from "./routes/routes.main";
import RootProvider from "./context/root.context";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { checkUserLogin, getTokenFromLocalStorage } from "./utils/util.main";
import {
  modalConfig,
  sessionExpire,
} from "./config/config.main";
import authService from "./api/authService";
import { setModal } from "./redux/modalSlice";
import TimerRefreshToken from "./components/time/timerRefreshToken";
import AlertModal from "./components/modal/alertModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A58BF",
    },
    secondary: {
      main: "#462279",
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const modalSelector = useSelector((state) => state.modal);
  const [stopRefreshToken, setStopRefreshToken] = useState(false);
  const [checkInitialApp, setCheckIntialApp] = useState(false);
  const [checkInterval, setCheckInterval] = useState(false);

  console.warn = function filterWarnings() { };
  console.error = function filterErrors() { };

  useEffect(() => {
    setCheckIntialApp(true);
    const interval = setInterval(() => {
      setCheckInterval(!checkInterval);
      onClickCapture(false);
    }, 10000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (checkInitialApp && !checkUserLogin() && window?.location?.pathname !== "/login") {
      return window.open("/login", "_self");
    }
  }, [checkInitialApp]);

  const onClickCapture = async (updateActive = true) => {
    if (window?.location?.pathname === "/login") return;

    const lastActive = sessionStorage.getItem("lastActive");
    const today = new Date().getTime();
    if (lastActive !== null && today - lastActive >= +sessionExpire * 1000) {
      setStopRefreshToken(true);
      const token = getTokenFromLocalStorage();
      if (token.refresh_token !== undefined && token.refresh_token !== null) {
        await authService.logout({ refresh_token: token.refresh_token });
      }
      sessionStorage.clear();
      return dispatch(
        setModal({
          open: true,
          ...modalConfig.sessionExpiredModal,
        })
      );
    }
    if (updateActive) return sessionStorage.setItem("lastActive", today);
  };

  return (
    <ThemeProvider theme={theme}>
      <RootProvider>
        <div onClickCapture={onClickCapture}>
          <TimerRefreshToken stopRefreshToken={stopRefreshToken} />
          <RoutesMain />
          <AlertModal
            className="z-index-9999"
            open={modalSelector.open}
            title={modalSelector.title}
            description={modalSelector.description}
            action={modalSelector.actionModal}
            hasBlur={true}
          />
        </div>
      </RootProvider>
    </ThemeProvider>
  );
}

export default App;
