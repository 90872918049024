import React, { forwardRef, useContext, useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import reportApi from "../../api/reportInquiry";
import { ROUTE_REPORT } from "../../constrant/route.constrant";
import { RootContext } from "../../context/root.context";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CommonDropdown } from "../../components/Input/commonDropdown";
import { CommonTable } from "../../components/table/commonTable";
import {
  ColorStatus,
  dateSafety,
  Download,
  formateDate,
  formateDateWithTime,
  formateDateWithTimeHourMintues,
  isValidDate,
  longColumn,
  ReportNameColumn,
} from "../../utils/util.main";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HKError from "../../components/error/alertError";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { type } from "@testing-library/user-event/dist/type";

const CustomDateInputQuarter = forwardRef(({ value, onClick }, ref) => {
  return (
    <div ref={ref} onClick={onClick} className="flex flex-col w-[203px]">
      <div className="text-xs text-gray-600">Quarter</div>
      <div className="flex border-b border-gray-500 pb-1 leading-4 mt-1 justify-between items-center">
        <div
          style={{
            fontSize: "16px",
            color: "rgb(122, 88, 191)",
          }}
        >
          {" "}
          {value}
        </div>
        <InsertInvitationIcon
          style={{
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      </div>
    </div>
  );
});

const findQuarter = (dateValue) => {
  const today = new Date(dateValue);
  const month = today.getMonth();
  const quarter = Math.floor(month / 3) + 1;
  return quarter;
};

const getDateValue = (dateValue) => {
  const today = new Date(dateValue);
  const year = today.getFullYear();
  const quarter = findQuarter(dateValue);

  if (quarter === 1) {
    return `${year}-03-31`;
  } else if (quarter === 2) {
    return `${year}-06-30`;
  } else if (quarter === 3) {
    return `${year}-09-30`;
  } else {
    return `${year}-12-31`;
  }
};

function ReportMain({ serviceType, setActionAppbar }) {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const [dateValue, setDateValue] = React.useState(date);
  const [isShowCalendar, setIsShowCalendar] = React.useState(false);
  const [type, setType] = React.useState("Daily Report");
  const { data, loading, error } = useQuery(
    reportApi.getReportInquiry,
    {
      report_type: type.split(" ")[0].toUpperCase(),
      report_date:
        type === "Quarterly Report"
          ? getDateValue(dateValue, type)
          : dateSafety(dateValue),
      //  report_date:  "2022-01-02"
      //  report_date:  ""
    },
    {},
    [dateValue, type],
    { checkFirstLoad: isValidDate(dateValue) }
  );

  const { setRouteName, routeName } = useContext(RootContext);

  useEffect(() => {
    setRouteName([{ title: "REPORT", path: ROUTE_REPORT }]);
  }, []);

  const setDate = (e) => {
    setDateValue(e);
  };


  return (
    <>{error && <HKError error={error} />}
      <div className="pt-xxl w-full ">
        <div className="p-xl w-full h-32">
          <div className="flex flex-row justify-between w-full mb-50px">
            <CommonDropdown
              width={200}
              label={"TYPE"}
              options={["Daily Report", "Monthly Report"]}
              fullWidth={false}
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
            {type === "Monthly Report" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  views={isShowCalendar?["month","year"]: ["day", "month", "year"]}
                  onOpen={() => setIsShowCalendar(true)}
                  onClose={() => setIsShowCalendar(false)}
                  label="Date desktop"
                  inputFormat="MM/yyyy"
                  value={dateValue}
                  onClick={() => alert("click date")}
                  onChange={setDate}
                  sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="number"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 16, color: "#7A58BF" },
                      }}
                      variant="standard"
                      label="MONTH"
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
            {type === "Daily Report" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //views={['day', 'month','year']}
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  value={dateValue}
                  onChange={setDate}
                  sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 16, color: "#7A58BF" },
                      }}
                      variant="standard"
                      label="DATE"
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
            {/* {type === "Quarterly Report" && (
            <div className="z-50">
              <DatePicker
                selected={dateValue}
                onChange={(date) => setDateValue(date)}
                dateFormat="QQQ/yyyy"
                showQuarterYearPicker
                customInput={<CustomDateInputQuarter />}
              />
            </div>
          )} */}
          </div>
          {loading ? (
            <div className="p-fourteen">
              <Skeleton
                containerTestId="react-loading-skeleton"
                height={30}
                width={`100%`}
                count={15}
              />
            </div>
          ) : (
            <CommonTable
              disabledSort={false}
              headCells={[
                {
                  id: "name",
                  label: "NAME",
                },
                {
                  id: "date",
                  label: "DATE",
                },
                {
                  id: "last_update_date",
                  label: "LAST UPDATE DATE",
                },
                {
                  id: "download",
                  label: "",
                },
              ]}
              rows={dataMapReport(data)}
              orderByDefault="name"
              showFooter={false}
              //  showFields={["report_status", "report_name", "report_date", "update_datetime","report_id"]}
              formatControl={[
                ReportNameColumn,
                formateDate,
                formateDateWithTimeHourMintues,
                Download,
              ]}
            />
          )}
        </div>
      </div>
    </>
  );
}

const dataMapReport = (data = []) => {
  var temp = [];
  for (let i = 0; i < data?.length; i++) {
    temp.push({
      name: {
        prevName: i > 0 ? data[i - 1].report_name : "",
        report_name: data[i].report_name,
        colorStatus: data[i].report_status,
      },
      report_date: data[i].report_date,
      update_datetime: data[i].update_datetime,
      download: {
        report_id: data[i].report_id,
        report_status: data[i].report_status,
      },
    });
  }
  return temp;
};

export default ReportMain;
