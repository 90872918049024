import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import { Select, TextField } from "@mui/material";
import { CommonDropdown, RMDropdown } from "../Input/commonDropdown";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Invalid from "../error/invalidData";
import nostroBaseAPI from "../../api/nostroBaseAPI";
import { LoadingButton } from "@mui/lab";
import {
  COUNTRY_CODE_NONE,
  FIX_VAR_NONE,
  ORIGINAL_CURR,
  YES_NO_NONE,
} from "../../constrant/text.constrant";
import { formatDateToAPI } from "../../utils/util";
import YesNoRadio, { OptionRadio } from "../Input/yesnoRadio";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NostroModal = ({isLatest = false, open = false, action, hasBlur = false, data = {},reportDate }) => {
  const [form, setForm] = React.useState({});
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState([]);
  const [countryOfHeadQuater, setCountryOfHeadQuater] = React.useState("CN");

  React.useEffect(() => {
    setForm(data);
    setCountryOfHeadQuater(data?.hq_country_code);
    setErrorOpen(false);
  }, [open]);

  const mapNoneValue = (value) => {
    if (value == null || value === "NONE") return null;
    if (value === "YES") return "Y";
    if (value === "NO") return "N";
    return value;
  };

  const Submit = async () => {
    try {
      setLoading(true);

      var ret = await nostroBaseAPI.nostroUpdate(isLatest,form.gl_acct, {
        hq_country_code: mapNoneValue(countryOfHeadQuater),
        inward_risk_transfer: mapNoneValue(form.inward_risk_transfer),
        outward_risk_transfer: mapNoneValue(form.outward_risk_transfer),
        int_bearing_flag: mapNoneValue(form.int_bearing_flag),
        fix_var: mapNoneValue(form.fix_var),
        ...(!isLatest && {report_date: reportDate})
      });
      if (ret?.status === 200) action(true);
    } catch (e) {
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const Validate = () => {
    var error = [];
    // if (!countryOfHeadQuater) {
    //     error.push({ message: "Country of Head Quarter is required" })
    // }

    // if (
    //   form?.inward_risk_transfer === "YES" &&
    //   form?.outward_risk_transfer === "YES"
    // ) {
    //   error.push({
    //     message:
    //       "Inward Risk Transfer and Outward Risk Transfer can not be Y at the same time",
    //   });
    // }

    // if (!form.inward_risk_transfer) {
    //     error.push({ message: "Inward Risk Transfer is required" })
    // }
    // if (!form.outward_risk_transfer) {
    //     error.push({ message: "Outward Risk Transfer is required" })
    // }
    // if (!form.int_bearing_flag) {
    //     error.push({ message: "Interest Bearing Flag is required" })
    // }
    // if(!form.fix_var){
    //     error.push({ message: "Fix Var is required"})
    // }
    setErrorOpen(error.length > 0);
    if (error.length === 0) {
      Submit();
    } else {
      console.log("error log ", error);
      setErrorState(error);
    }
  };

  const mapListWithNoneValue = (value) => {
    if (
      value == undefined ||
      value === "NONE" ||
      value == null ||
      value.length === 0
    )
      return "NONE";

    return value;
  };

  const mapYesNoValue = (value) => {
    if (value === "Y" || value === "YES") return "YES";
    if (value === "N" || value === "NO") return "NO";
    if (value == null || value.length === 0) return "NONE";
    return value;
  };

  const mapForFixVar = (value) => {
    if (value == null || value.length === 0) return "NONE";
    return value;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      className={"z-index-1"}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: "#ccc",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        align="center"
      >
        EDIT NOSTRO DETAIL
        <Invalid
          pt="pt-20px"
          errorState={errorState}
          open={errorOpen}
          setOpen={setErrorOpen}
          isShow={true}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description "
          sx={{ paddingX: "40px", paddingY: "20px" }}
        >
          <CommonDropdown
            label="COUNTRY OF HEAD QUARTER / GUARANTOR"
            value={mapListWithNoneValue(countryOfHeadQuater) || ""}
            onChange={(e) => setCountryOfHeadQuater(e.target.value)}
            options={COUNTRY_CODE_NONE}
          />
          <div className="h-4"></div>
          <CommonDropdown
            label="INWARD RISK TRANSFER"
            value={mapYesNoValue(form?.inward_risk_transfer)}
            onChange={(e) =>
              setForm({ ...form, inward_risk_transfer: e.target.value })
            }
            options={YES_NO_NONE}
          />
          <div className="h-4"></div>
          <CommonDropdown
            label="OUTWARD RISK TRANSFER"
            value={mapYesNoValue(form?.outward_risk_transfer)}
            onChange={(e) =>
              setForm({ ...form, outward_risk_transfer: e.target.value })
            }
            options={YES_NO_NONE}
          />
          <div className="h-4"></div>
          <CommonDropdown
            label="INT BEARING FLAG"
            value={mapYesNoValue(form?.int_bearing_flag)}
            onChange={(e) =>
              {
                const newInt = e.target.value;
                let newFixVar = form?.fix_var;

                if (["NONE", "NO"].includes(newInt)) {
                  newFixVar = "NONE";
                }

                if (form?.fix_var === "NONE" && newInt === "YES") {
                  newFixVar = "Fix";
                }
                setForm({
                  ...form,
                  int_bearing_flag: e.target.value,
                  fix_var: newFixVar,
                });
              }
              // setForm({ ...form, int_bearing_flag: e.target.value })
            }
            options={YES_NO_NONE}
          />
          <div className="h-4"></div>
          <CommonDropdown
            label="FIX / VAR"
            value={mapForFixVar(form?.fix_var)}
            onChange={(e) => setForm({ ...form, fix_var: e.target.value })}
            // options={
            //   form.int_bearing_flag === "YES"
            //     ? FIX_VAR_NONE.filter(
            //         (item) => item.int_bearing_flag !== "NONE"
            //       )
            //     : FIX_VAR_NONE.filter(
            //         (item) => item.int_bearing_flag === "NONE"
            //       )
            // }

            options={
             (form?.int_bearing_flag === "YES" || form?.int_bearing_flag === "Y")
             ? FIX_VAR_NONE.filter((item) => item !== "NONE"):
             (form?.int_bearing_flag === "NO" || form?.int_bearing_flag === "NONE" ||form?.int_bearing_flag === "N" || form?.int_bearing_flag === "")?
              FIX_VAR_NONE.filter((item) => item === "NONE") : FIX_VAR_NONE
            }
         
          />

          {/* 
                    <div className="w-full">
                        <YesNoRadio label="Inward risk transfer" value={form.inward_risk_transfer} handleChange={e => setForm({ ...form, inward_risk_transfer: e.target.value })} />
                    </div>
                    <div className="w-full">
                        <YesNoRadio label="Outward risk transfer" value={form.outward_risk_transfer} handleChange={e => setForm({ ...form, outward_risk_transfer: e.target.value })} />
                    </div>
                    <div className="w-full">
                        <YesNoRadio label="Int Bearing Flag" value={form.int_bearing_flag} handleChange={e => setForm({ ...form, int_bearing_flag: e.target.value })} />
                    </div>
                    <div className="w-full">
                        <OptionRadio label="Fix / Var" value={form.fix_var || "Var"} values={["Fix", "Var"]} handleChange={e => setForm({ ...form, fix_var: e.target.value })} />
                    </div> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={action}>
          Cancel
        </Button>

        <LoadingButton
          // startIcon={<img alt="" src={SaveIcon} />}
          variant="contained"
          //  sx={{ fontSize: "14px" }}
          onClick={Validate}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NostroModal;
