import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../constrant/style.constrant";
import { Select, TextField } from "@mui/material";
import {
  EconomicSectorDropdown,
  GeographicalDropDown,
  CommonDropdown,
} from "../Input/commonDropdown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Invalid from "../error/invalidData";
import loanBaseAPI from "../../api/loanBaseAPI";
import { LoadingButton } from "@mui/lab";
import {
  COUNTRY_CODE,
  COUNTRY_CODE_NONE,
  FIX_VAR_NONE,
  GENERAL_SPECIFIC,
  MAP_YES_NO_REVERSE,
  ORIGINAL_CURR,
  YES_NO_NONE,
} from "../../constrant/text.constrant";
import { addCommasToString, checkValidDate, convertInputNumCommaToNumber, formatDateToAPI, inputFormatNumCommaTwoDigit, validateMaxAmount } from "../../utils/util";
import YesNoRadio, { OptionRadio } from "../Input/yesnoRadio";
import { format } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoanModal = ({ open = false, action, data = {}, reportDate, type }) => {
  const [form, setForm] = React.useState({});
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState([]);
  React.useEffect(() => {
    setForm({
      ...data,
      provision_amt_hkd: data?.provision_amt_hkd ?? 0.0,
      fix_var: ["NONE", "NO"].includes(data?.int_bearing_flag)
        ? "NONE"
        : data?.fix_var,
    });
    return () => {
      setErrorOpen(false);
    };
  }, [open]);

  const onAmountChange = (e) => {
    // if(!isEditProvisionAmt) setIsEditProvisionAmt(true)
    setForm({ ...form, provision_amt_hkd: e.target.value });
  };

  const Submit = async () => {

    try {
      setLoading(true);
      var submit_form = {
        account_name: form.account_name,
        original_curr: form.original_curr,
        geographical_cn: form.geographical_cn === "NONE" ? "" : form.geographical_cn,
        country_usage: form.country_usage === "NONE" ? "" : form.country_usage,
        cn_related: MAP_YES_NO_REVERSE[form.cn_related],
        general_specific:
          form?.general_specific === "NONE" ? "" : form?.general_specific,
        credit_rating: form.credit_rating,
        hkma_rating: form?.hkma_rating === "NONE" ? "":form?.hkma_rating,
        provision_amt_hkd: convertInputNumCommaToNumber(form?.provision_amt_hkd),
        drawdown_avail_dt: form?.drawdown_avail_dt
          ? format(new Date(form.drawdown_avail_dt), "yyyy-MM-dd")
          : null,
        int_bearing_flag: MAP_YES_NO_REVERSE[form.int_bearing_flag],
        fix_var: form?.fix_var === "NONE" ? "" : form?.fix_var,
        economic_sector: (form?.economic_sector && form?.economic_sector!== "NONE")
          ? form.economic_sector.split(".")[0]
          : null,
        report_date: reportDate,
      };

      if (form?.gl_acct?.toLowerCase() === "undrawn")
        submit_form = {
          fix_var: form?.fix_var === "NONE" ? "" : form?.fix_var,
          account_name: form.account_name,
          original_curr: form.original_curr,
          geographical_cn: form.geographical_cn === "NONE" ? "" : form.geographical_cn,
          country_usage: form.country_usage === "NONE" ? "" : form.country_usage,
          cn_related: MAP_YES_NO_REVERSE[form.cn_related],
          general_specific:
            form?.general_specific === "NONE" ? "" : form?.general_specific,
          credit_rating: form.credit_rating,
          hkma_rating: form?.hkma_rating === "NONE" ? "":form?.hkma_rating,

          provision_amt_hkd: convertInputNumCommaToNumber(form?.provision_amt_hkd),
          drawdown_avail_dt: form?.drawdown_avail_dt
            ? format(new Date(form.drawdown_avail_dt), "yyyy-MM-dd")
            : null,
          int_bearing_flag: MAP_YES_NO_REVERSE[form.int_bearing_flag],
          economic_sector: (form?.economic_sector && form?.economic_sector!== "NONE")
            ? form?.economic_sector?.split(".")[0]
            : null,
          os_original_amt: convertInputNumCommaToNumber(form?.os_amount_original),
          report_date: reportDate,
        };

      var ret = await loanBaseAPI.loanUpdate(
        form.gl_acct,
        form.master_acc,
        form.sub_acc,
        submit_form,
        type
      );
      if (ret.status === 200) {
        action(true);
      }
    } catch (e) {
      console.log("error ", e);
      setErrorState([{ message: e.response.data?.status.description }]);
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const Validate = () => {
    var error = [];
    // if (!form.sub_acc || form.sub_acc.length < 4)
    //     error.push({ message: "Trans Num is required" })
    // if (!form.account_name || form.account_name.length < 4)
    //     error.push({ message: "A/C Name is required" })
    // if (!form.original_curr)
    //     error.push({ message: "Original Cur is required" })

    if (
      form?.gl_acct?.toLowerCase() !== "undrawn" &&
      form?.gl_acct?.toLowerCase() !== "xxxxx"
    ) {
      // if (!form?.geographical_cn) {
      //   error.push({ message: "Geographical is required" });
      // }
      // if (!form?.country_usage) {
      //   error.push({ message: "Country Usage is required" });
      // }
      if (!form?.cn_related) {
        error.push({ message: "CN Related is required" });
      }
      // if (!form?.general_specific) {
      //   error.push({ message: "General Specific is required" });
      // }
      // if (!form?.credit_rating) {
      //   error.push({ message: "Credit Rating is required" });
      // }
  
      if (form?.provision_amt_hkd != null) {
        validateMaxAmount(form?.provision_amt_hkd, "Provision Amount [HKD EQV]", error)
      }



      // if (form?.gl_acct?.toLowerCase() === "undrawn" && (!form?.provision_amt_hkd || isNaN(form?.provision_amt_hkd))) {
      //     error.push({ message: "Amount (OS Original) is required as number" })
      // }
      // if (
      //   !form.drawdown_avail_dt ||
      //   !checkValidDate(new Date(form.drawdown_avail_dt))
      // ) {
      //   error.push({ message: "Drawdown Avail Date is required" });
      // }
      if (!form?.int_bearing_flag) {
        error.push({ message: "INT BEARING FLAG is required" });
      }

      //   if (["", "NONE"].includes(form?.fix_var)) {
      //     error.push({ message: "Fix or Var is required" });
      //   }
      // if (!form?.economic_sector) {
      //   error.push({ message: "Economic Sector is required" });
      // }
    } else {
      if (form?.gl_acct?.toLowerCase() === "undrawn") {
        validateMaxAmount(form?.os_amount_original, "Amount [OS Original Curr]", error)
      }

      if (form?.provision_amt_hkd != null) {
        validateMaxAmount(form?.provision_amt_hkd, "Provision Amount [HKD EQV]", error)
      }
    }

    setErrorOpen(error.length > 0);
    if (error.length === 0) {
      Submit();
    } else {
      setErrorState(error);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      className={"z-index-1"}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: "#ccc",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        align="center"
      >
        EDIT LOAN DETAIL
        <Invalid
          pt="pt-20px"
          errorState={errorState}
          open={errorOpen}
          setOpen={setErrorOpen}
          isShow={true}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description "
          sx={{ paddingX: "40px", paddingY: "10px" }}
          className="uppercase"
        >
          {form?.gl_acct?.toLowerCase() === "undrawn" && (
            <>
              <TextField
                inputProps={{ style: { color: "#7A58BF" } }}
                label="Amount [OS Original Curr]"
                variant="standard"
                placeholder="0.00"
                type="text"
                fullWidth
                value={addCommasToString(form?.os_amount_original)}
                onChange={(e) => inputFormatNumCommaTwoDigit("os_amount_original", e.target.value, setForm)}
                
              />
              <div className="h-10px" />
            </>
          )}
          <GeographicalDropDown
            value={form?.geographical_cn || "NONE"}
            onChange={(e) =>
              setForm({ ...form, geographical_cn: e.target.value })
            }
          />
          <div className="h-10px" />
          <CommonDropdown
            label="Country Usage"
            value={form?.country_usage || "NONE"}
            onChange={(e) =>
              setForm({ ...form, country_usage: e.target.value })
            }
            options={COUNTRY_CODE_NONE}
          />
          <div className="h-10px" />
          <div className="w-full">
            <CommonDropdown
              label="CN Related"
              value={form?.cn_related || ""}
              onChange={(e) => setForm({ ...form, cn_related: e.target.value })}
              options={YES_NO_NONE}
            />
            {/* <YesNoRadio
              label="CN Related"
              value={form?.cn_related}
              handleChange={(e) =>
                setForm({ ...form, cn_related: e.target.value })
              }
            /> */}
          </div>
          <div className="h-10px" />
          <div className="w-full">
            <CommonDropdown
              label="General / Specific"
              value={form?.general_specific || "NONE"}
              onChange={(e) =>
                setForm({ ...form, general_specific: e.target.value })
              }
              options={GENERAL_SPECIFIC}
            />
            {/* <OptionRadio
              label="General / Specific"
              values={["General", "Specific"]}
              value={form?.general_specific || ""}
              handleChange={(e) =>
                setForm({ ...form, general_specific: e.target.value })
              }
            /> */}
          </div>
          <div className="h-10px" />
          <CommonDropdown
            label={"Credit Rating"}
            value={(form?.credit_rating || "") === "" ? "NONE" : form?.credit_rating}
            onChange={(e) =>
              setForm({ ...form, credit_rating: e.target.value === "NONE" ? "" : e.target.value })
            }
            options={[
              "NONE",
              "C1",
              "C2",
              "C3",
              "C4",
              "C5",
              "C6",
              "C7",
              "C8",
              "C9",
              "C10",
              "C11",
              "C12",
              "C13",
              "C14",
              "C15",
              "C16",
              "C17",
              "C18",
              "C19",
              "C20",
              "C21",
              "C22",
              "M1",
              "M2",
              "M3",
              "M4",
              "M5",
              "M6",
              "M7",
              "M8",
              "M9",
              "M10",
              "M11",
              "M12",
              "M13",
              "M14",
              "M15",
              "M16",
              "M17",
              "M18",
              "M19",
              "M20",
              "M21",
              "M22",
            ]}
          />
          <div className="h-10px" />
          <CommonDropdown
            label={"HKMA Rating"}
            value={form?.hkma_rating || "NONE"}

            onChange={(e) => setForm({ ...form, hkma_rating: e.target.value })}
            options={[
              "NONE",
              "Pass",
              "Special Mention-Qualitative",
              "Substandard",
              "Doubtful",
              "Doubtful Loss/ Loss",
            ]}
          />
          <div className="h-10px" />
          <TextField
            inputProps={{ style: { color: "#7A58BF" } }}
            label="Provision Amount [HKD EQV]"
            variant="standard"
            fullWidth
            type="text"
            placeholder="0.00"
            defaultValue={0}
            value={
              addCommasToString(form?.provision_amt_hkd)
            }
            onChange={(e) => inputFormatNumCommaTwoDigit("provision_amt_hkd", e.target.value, setForm)}
          />
          <div className="h-10px" />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date desktop"
              inputFormat="dd/MM/yyyy"
              value={form?.drawdown_avail_dt || null}
              onChange={(e) => setForm({ ...form, drawdown_avail_dt: e })}
              sx={{ color: "#7A58BF", fontSize: 16, width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 16, color: "#7A58BF" },
                  }}
                  fullWidth={true}
                  variant="standard"
                  label="Drawdown Available DT"
                  error={false}
                />
              )}
            />
          </LocalizationProvider>
          <div className="h-10px" />
          <div className="w-full">
            <CommonDropdown
              label="INT BEARING FLAG"
              value={form?.int_bearing_flag || ""}
              onChange={(e) => {
                const newInt = e.target.value;
                let newFixVar = form?.fix_var;

                if (["NONE", "NO"].includes(newInt)) {
                  newFixVar = "NONE";
                }

                if (form?.fix_var === "NONE" && newInt === "YES") {
                  newFixVar = "Fix";
                }
                setForm({
                  ...form,
                  int_bearing_flag: e.target.value,
                  fix_var: newFixVar,
                });
              }}
              options={YES_NO_NONE}
            />
            {/* <YesNoRadio
              label="INT BEARING FLAG"
              value={form?.int_bearing_flag}
              handleChange={(e) =>
                setForm({ ...form, int_bearing_flag: e.target.value })
              }
            /> */}
          </div>
          <div className="h-10px" />
          <div className="w-full">
            <CommonDropdown
              label="FIX / VAR"
              value={form?.fix_var || ""}
              onChange={(e) => {
                setForm({ ...form, fix_var: e.target.value });
              }}
              options={
                (form?.int_bearing_flag === "YES" || form?.int_bearing_flag === "Y")
                  ? FIX_VAR_NONE.filter((item) => item !== "NONE")
                  : FIX_VAR_NONE.filter((item) => item === "NONE")
              }
            />
            {/* <OptionRadio
              label="FIX / VAR"
              value={form?.fix_var || ""}
              values={["Fix", "Var"]}
              handleChange={(e) =>
                setForm({ ...form, fix_var: e.target.value })
              }
            /> */}
          </div>
          <div className="h-10px" />
          <EconomicSectorDropdown
            value={form?.economic_sector || "NONE"}
            onChange={(e) =>
              setForm({ ...form, economic_sector: e.target.value })
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={action}>
          Cancel
        </Button>
        <LoadingButton
          // startIcon={<img alt="" src={SaveIcon} />}
          variant="contained"
          //  sx={{ fontSize: "14px" }}
          onClick={Validate}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LoanModal;
