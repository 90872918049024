import React, { useEffect, useCallback } from "react";
import _ from "lodash";

function useQuery(
  actionApi = () => {},
  params = {},
  query = {},
  dependencies = [],
  options = { prePareData: null, checkFirstLoad: true },
  checkMin = false,
  minLength = 1,
) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(options.checkFirstLoad);
  const [data, setData] = React.useState(null);
  const [originalData, setOriginalData] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const fetchData = useCallback(
    async (params = {}, query = {}, checkLoading = true) => {
      try {
        setLoading(checkLoading);
        const queryKeys = Object.keys(query);
        let queryString = "";
        if (queryKeys.length > 0) {
          const searchParams = new URLSearchParams();
          for (const queryKey of queryKeys) {
            searchParams.append(queryKey, query[queryKey]);
          }
          queryString = `?${searchParams.toString()}`;
        }

        const { data: responseData } = await actionApi(params, queryString);
        if (options?.prePareData) {
          const new_data = await options.prePareData(responseData?.data);
          const new_data_original = _.cloneDeep(new_data);
          // const new_data_original = await options.prePareData(
          //   responseData?.data
          // );
          sessionStorage.setItem(
            "originalData",
            JSON.stringify(new_data_original)
          );
          setData(new_data);
          setOriginalData(new_data_original);
        } else {
          const new_data = responseData?.data;
          const new_data_original = responseData?.data;
          setData(new_data);
          setOriginalData(new_data_original);
        }
        setTotalRecords(responseData?.total_records);
        setError(null)
      } catch (error) {
        if (error?.response?.data?.status?.description) {
          setError({ message: error.response.data.status.description });
        } else  if (error?.response?.data?.description) {
          setError({ message: error.response.data.description });
        }  else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [...dependencies]
  );

  useEffect(() => {
    if (options?.checkFirstLoad) {
      if(checkMin && params?.query?.length > 0 && params?.query?.length < minLength) return
      fetchData(params, query);
    }
  }, [fetchData]);

  return {
    error,
    loading,
    data,
    setData,
    originalData,
    totalRecords,
    refetch: fetchData,
    setLoading,
  };
}

export default useQuery;
